import React, {useMemo, useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {Grid, FormControlLabel, Radio, Dialog, DialogTitle, DialogActions, Button, DialogContent, Collapse, Typography} from '@material-ui/core';
import {CheckCircleOutline} from '@material-ui/icons';
import {AutoField, FormikForm, RadioGroup, TextField} from 'components/formik';
import {object, string} from 'yup';
import {states} from 'constLists.js';
import {postalRegex} from 'utilities.js';
import {Alert} from 'components/';
import {batteryKitPrice, priceByState, adminEmail} from 'constLists';
import {Firestore} from 'firebaseConfig.js';

const ModalPaperInvoice = ({open, close, currentUser, returnDetails}) => {
  const [stage, setStage] = useState('customerInfo');
  const handleClose = useCallback(() => {
    setStage('customerInfo');
    close();
  }, [close]);
  const handleRadioChange = useCallback((event, formik) => {
    if (event.target.value === 'newAddress') {
      formik.setFieldValue('Address1', '');
      formik.setFieldValue('Address2', '');
      formik.setFieldValue('City', '');
      formik.setFieldValue('State', '');
      formik.setFieldValue('PostalCode', '');
      formik.setTouched({});
    } else {
      const location = currentUser.Locations[event.target.value];
      formik.setFieldValue('Address1', location.Address1);
      formik.setFieldValue('Address2', location.Address2);
      formik.setFieldValue('City', location.City);
      formik.setFieldValue('State', location.State);
      formik.setFieldValue('PostalCode', location.PostalCode);
      formik.setTouched({});
    }
  }, [currentUser]);
  const formProps = useMemo(() => ({
    initialStatus: {alert: ''},
    initialValues: {
      ShippingType: 'outboundEconomy',
      Address: currentUser.Locations.length > 0 ? '0' : 'newAddress',
      Contact: '',
      Email: '',
      Address1: currentUser.Locations.length > 0 ? currentUser.Locations[0].Address1 : '',
      Address2: currentUser.Locations.length > 0 ? currentUser.Locations[0].Address2 : '',
      City: currentUser.Locations.length > 0 ? currentUser.Locations[0].City : '',
      State: currentUser.Locations.length > 0 ? currentUser.Locations[0].State : '',
      PostalCode: currentUser.Locations.length > 0 ? currentUser.Locations[0].PostalCode : '',
    },
    onSubmit: async (values, actions) => {
      const {...billingInfo} = values;
      try {
        const batch = Firestore.batch();
        const paymentRef = Firestore.collection('BatteryReturns').doc(returnDetails.BatteryReturnId).collection('Payments').doc();
        const returnRef = Firestore.collection('BatteryReturns').doc(returnDetails.BatteryReturnId);
        const emailRef = Firestore.collection('Emails').doc();
        batch.set(paymentRef, {...billingInfo});
        batch.update(returnRef, {Status: 'Invoiced'});
        batch.set(emailRef, {
          to: adminEmail,
          template: {
            data: {
              ReturnId: returnDetails.BatteryReturnId,
              VIN: returnDetails.Battery.VIN,
              ShippingPaper: returnDetails.ShipmentDetails.ShippingPaper,
              Contact: billingInfo.Contact,
              Email: billingInfo.Email,
              Address: `${billingInfo.Address1}, ${billingInfo.City}, ${billingInfo.State} ${billingInfo.PostalCode}`,
              ReturnLink: `https://a3.evbatteryreturns.com/portal/battery-returns/BatteryReturns/${returnDetails.BatteryReturnId}`,
              RecyclingFee: returnDetails.Accounting.RecyclingFee,
              ManagementFee: returnDetails.Accounting.ManagementFee,
              ModuleCount: returnDetails.Battery.DefectiveModules,
              ModuleKit: batteryKitPrice,
              ModuleKitTotal: returnDetails.Accounting.DamagedModuleKits,
              Inbound: returnDetails.Accounting.Inbound,
              OutboundCount: returnDetails.Battery.DefectiveModules,
              Outbound: priceByState[billingInfo.State]['outboundEconomy'],
              OutboundTotal: returnDetails.Accounting.Outbound,
              TPP: returnDetails.Accounting.ThirdPartyPackaging,
              Total: returnDetails.Accounting.Total,
            },
            name: 'InvoiceRequest',
          },
        });
        await batch.commit();
        setStage('success');
      } catch (error) {
        actions.setStatus({alert: error.message});
        actions.setSubmitting(false);
      }
    },
    validationSchema: object().shape({
      Contact: string().label('Billing Contact').required(),
      Email: string().label('Billing Email').required().email(),
      Address1: string().label('Address 1').required(),
      Address2: string().label('Address 2').notRequired(),
      City: string().label('City').required(),
      State: string().label('State').required(),
      PostalCode: string().label('Postal Code').required().matches(postalRegex, 'Postal Code is improperly formatted. (e.g. #####)'), // eslint-disable-line
    }),
  }), [currentUser.Locations, returnDetails]);
  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'sm',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [open]);
  const core = useMemo(() => ({
    buttons: {
      cancel: formik => ({
        color: 'secondary',
        disabled: formik.isSubmitting || formik.isValidating,
        onClick: handleClose,
      }),
      submit: formik => ({
        type: 'submit',
        color: 'primary',
        disableRipple: true,
        disabled: formik.isSubmitting || formik.isValidating,
      }),
    },
  }), [handleClose]);
  return (
    <Dialog {...dialogProps}>
      <Collapse in={stage === 'customerInfo'} timeout={{enter: 500, exit: 500}}>
        <DialogTitle>
        Paper Invoice
        </DialogTitle>
        <FormikForm {...formProps}>
          {formik => {
            return (
              <>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <RadioGroup name='Address' onChange={e => handleRadioChange(e, formik)} >
                        {currentUser.Locations.map(({Address1, Address2, City, State, PostalCode}, index) => {
                          const fullAddress = `${Address1}, ${Address2 ? Address2 + ', ' : ''} ${City}, ${State} ${PostalCode}`;
                          return <FormControlLabel key={index} value={`${index}`} control={<Radio color="primary" />} label={fullAddress} />;
                        })}
                        <FormControlLabel value='newAddress' control={<Radio color="primary" />} label='New Address' />
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField name='Contact' label='Billing Contact' required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField name='Email' label='Billing Email' required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField name='Address1' label='Address 1' required disabled={formik.values.Address !== 'newAddress'} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField name='Address2' label='Address 2' disabled={formik.values.Address !== 'newAddress'} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField name='City' label='City' required disabled={formik.values.Address !== 'newAddress'} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <AutoField name='State' label='State' items={states} itemKey='value' required disabled={formik.values.Address !== 'newAddress'} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField name='PostalCode' label='Postal Code' required disabled={formik.values.Address !== 'newAddress'} />
                    </Grid>
                  </Grid>
                  <Alert message={formik.status.alert} />
                </DialogContent>
                <DialogActions style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Button {...core.buttons.cancel(formik)}>Cancel</Button>
                  <Button {...core.buttons.submit(formik)}>Submit</Button>
                </DialogActions>
              </>
            );
          }}
        </FormikForm>
      </Collapse>
      <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
        <DialogContent>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <CheckCircleOutline style={{fontSize: '10em'}} />

            <Typography variant="h6" style={{textAlign: 'center'}} gutterBottom>
                Your billing information has been successfully submitted. Please wait to hear back from our billing department.
            </Typography>

          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Close</Button>
        </DialogActions>
      </Collapse>
    </Dialog>
  );
};

ModalPaperInvoice.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  returnDetails: PropTypes.object.isRequired,
};
export default ModalPaperInvoice;
