import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Button, Paper, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {LocalShipping} from '@material-ui/icons';

const ViewNoBatteryReturns = ({setModal}) => {
  const {batteryReturns} = useSelector(state => state.firestore);
  const {currentUser} = useSelector(state => state.auth);
  const styles = useStyles();

  if (batteryReturns.list.length !== 0) return null;
  return (
    <Paper className={styles.paper} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <Typography variant="h4" gutterBottom>
        Battery Return Request
      </Typography>
      <LocalShipping style={{fontSize: '10em', fill: 'slategray', width: '100%'}} />
      {Boolean(currentUser.DealerNo) ?
        <>
          <Typography variant="h6" gutterBottom>
            No Previous Returns Found
          </Typography>
          <Button variant="outlined" color="primary" onClick={() => setModal('newReturn')}>
            Schedule First Return
          </Button>
        </> :
        <>
          <Typography variant="h6" gutterBottom>
              Let's Finish Setting Up Your Account
          </Typography>
          <Button variant="outlined" color="primary" onClick={() => setModal('finishSetup')}>
            Complete Setup
          </Button>
        </>
      }
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    display: 'flex',
    marginTop: '24px',
    overflowX: 'auto',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));
ViewNoBatteryReturns.propTypes = {
  setModal: PropTypes.func.isRequired,
};
export default ViewNoBatteryReturns;
