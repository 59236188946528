import React, {useEffect, useState, useRef, useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Button, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@material-ui/core';
import {Functions} from 'firebaseConfig.js';
import {CheckCircleOutline} from '@material-ui/icons';
import {Alert} from 'components/';
import green from '@material-ui/core/colors/green';
import {batteryKitPrice} from 'constLists.js';

import squareLogo from 'media/squareLogo.png';

const progressStyle = {
  color: green[500],
  position: 'absolute',
  top: '50%',
  left: '50%',
  marginTop: -12,
  marginLeft: -12,
};

const ModalCreditCard = ({open, close, returnDetails}) => {
  const [stage, setStage] = useState('cardInfo');
  const [isSubmitting, setSubmitting] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [nonce, setNonce] = useState('');
  const paymentForm = useRef(null);
  const handleClose = useCallback(() => {
    setAlertMessage('');
    setStage('cardInfo');
    setNonce('');
    paymentForm.current.destroy();
    close();
  }, [close]);
  useEffect(() => {
    if (open) {
      paymentForm.current = new window.SqPaymentForm({
        // Initialize the payment form elements

        // TODO: Replace with your sandbox application ID
        applicationId: 'sq0idp-Wis8eP1PPEzNbLe8xcmDww',
        inputClass: 'sq-input',
        autoBuild: false,
        // Customize the CSS for SqPaymentForm iframe elements
        inputStyles: [{
          fontSize: '16px',
          lineHeight: '24px',
          padding: '16px',
          placeholderColor: '#a0a0a0',
          backgroundColor: 'transparent',
        }],
        // Initialize the credit card placeholders
        cardNumber: {
          elementId: 'sq-card-number',
          placeholder: 'Card Number',
        },
        cvv: {
          elementId: 'sq-cvv',
          placeholder: 'CVV',
        },
        expirationDate: {
          elementId: 'sq-expiration-date',
          placeholder: 'MM/YY',
        },
        postalCode: {
          elementId: 'sq-postal-code',
          placeholder: 'Postal',
        },
        // SqPaymentForm callback functions
        callbacks: {
          /*
                * callback function: cardNonceResponseReceived
                * Triggered when: SqPaymentForm completes a card nonce request
                */
          cardNonceResponseReceived: function(errors, nonce, cardData) {
            if (errors) {
              setSubmitting(false);
              // Log errors from nonce generation to the browser developer console.
              console.error('Encountered errors:');
              errors.forEach(function(error) {
                console.error('  ' + error.message);
              });
              // alert('Encountered errors, check browser developer console for more details');
              setAlertMessage('Credit card information is invalid');
              return;
            }
            // alert(`The generated nonce is:\n${nonce}`);
            setNonce(nonce);
          },
        },
      });
      paymentForm.current.build();
    }
  }, [open]);
  const onGetCardNonce = () => {
    setSubmitting(true);
    paymentForm.current.requestCardNonce();
  };
  useEffect(() => {
    async function createPayment() {
      if (nonce) {
        try {
          const paymentDescription = `VIN: ${returnDetails.Battery.VIN} - ID: ${returnDetails.BatteryReturnId}`;
          await Functions.httpsCallable('createPaymentAndReceipt')({nonce, amount: Math.round(returnDetails.Accounting.Total * 100), note: paymentDescription, returnDetails, batteryKitPrice});
          setStage('success');
          setSubmitting(false);
        } catch (error) {
          console.log(error);
          if (error.message === 'INTERNAL') {
            setSubmitting(false);
            setAlertMessage('There was an error processing your payment. Please check to see if your card has been charged and contact a3-support@xevstrategies.com');
          } else {
            setSubmitting(false);
            setAlertMessage(error.message);
          }
        }
      }
    }
    createPayment();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonce]);
  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'sm',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [open]);

  const core = useMemo(() => ({
    buttons: {
      cancel: {
        color: 'secondary',
        disabled: isSubmitting,
        onClick: handleClose,
      },
      pay: {
        id: 'sq-creditCard',
        className: 'button-credit-card',
        color: 'primary',
        disabled: isSubmitting,
        onClick: onGetCardNonce,

      },
    },
  }), [handleClose, isSubmitting]);
  return (
    <Dialog {...dialogProps}>
      <Collapse in={stage === 'cardInfo'} timeout={{enter: 500, exit: 500}}>
        <DialogTitle>
          <img src={squareLogo} alt='Square' style={{width: '50%', margin: '10px auto', display: 'block'}} />
        </DialogTitle>
        <DialogContent>
          <div id="form-container">
            <div id="sq-card-number"></div>
            <div className="third MuiTextField-root" id="sq-expiration-date"></div>
            <div className="third" id="sq-cvv"></div>
            <div className="third" id="sq-postal-code"></div>
          </div>
          <Alert message={alertMessage} />
        </DialogContent>
        <DialogActions style={{display: 'flex', justifyContent: 'space-between'}}>
          <Button {...core.buttons.cancel}>Cancel</Button>
          <Button {...core.buttons.pay}>Pay {isSubmitting && <CircularProgress size={24} style={progressStyle} />}</Button>
        </DialogActions>
      </Collapse>
      <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
        <DialogContent>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <CheckCircleOutline style={{fontSize: '10em'}} />

            <Typography variant="h6" style={{textAlign: 'center'}} gutterBottom>
                Your payment has been successfully processed.
            </Typography>

          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
          Close
          </Button>
        </DialogActions>
      </Collapse>
    </Dialog>
  );
};

ModalCreditCard.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  returnDetails: PropTypes.object.isRequired,
};
export default ModalCreditCard;
