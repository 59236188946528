import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {MenuItem, Paper, Popper, TextField} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Field} from 'formik';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import get from 'lodash.get';
import includes from 'lodash.includes';
import startsWith from 'lodash.startswith';

const useStyles = makeStyles(theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(0),
    left: 0,
    right: 0,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
}));
let itemList = [];

const AutoField = ({filterType, items, itemKey, maxList, minInput, name, onSelect, ...otherProps}) => {
  const [anchor, setAnchor] = useState(null);
  const classes = useStyles();

  const handleChange = ({formik: {field, form}, selection}) => {
    form.setFieldValue(name, selection);
  };
  const getSuggestions = (value, items, itemKey, {showEmpty = false} = {}) => {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    if (inputLength < minInput && !showEmpty) itemList = [];
    else {
      itemList = items.filter(item => {
        const keepItem = () => {
          count += 1;
          return true;
        };
        if (count < maxList) {
          if (filterType === 'startsWith' && startsWith(item[itemKey].toLowerCase(), inputValue)) return keepItem();
          if (filterType === 'includes' && includes(item[itemKey].toLowerCase(), inputValue)) return keepItem();
        }
        return false;
      });
    }

    return itemList;
  };

  return (
    <Field name={name}>
      {formik => {
        return (
          <Downshift onChange={selection => handleChange({formik, selection})}>
            {downshift => {
              const {clearSelection, getInputProps, getItemProps, getLabelProps, getMenuProps} = downshift;
              const {highlightedIndex, inputValue, isOpen, selectedItem} = downshift;
              const {onBlur, onFocus, ...inputProps} = getInputProps({
                onBlur: e => {
                  formik.field.onBlur(e);
                  formik.form.setTouched({...formik.form.touched, [name]: true});
                  if (!inputValue) onSelect({item: null});
                },
                onChange: e => {
                  formik.field.onChange(e);
                  if (e.target.value === '') clearSelection();
                },
                onFocus: e => {
                  if (e.target.value === '') clearSelection();
                  setAnchor(e.target);
                },
                onKeyDown: e => {
                  if (e.keyCode === 13) {
                    if (isOpen && highlightedIndex !== null) onSelect({item: itemList[highlightedIndex]});
                    else if (isOpen && !inputValue) onSelect({item: null});
                    else if (isOpen && inputValue) e.preventDefault();
                  }
                  if (e.keyCode === 9) {
                    if (isOpen && !inputValue) onSelect({item: null});
                  }
                },
              });

              const textFieldProps = {
                disabled: formik.form.isSubmitting,
                error: formik.form.touched[name] && formik.form.errors[name] ? true : false,
                fullWidth: true,
                helperText: formik.form.touched[name] && formik.form.errors[name],
                margin: 'dense',
                label: name || 'Auto Suggest',
                placeholder: 'Type to search...',
                ...otherProps,
                InputProps: {
                  inputRef: anchor,
                  classes: {
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  },
                  onBlur,
                  onFocus,
                },
                InputLabelProps: getLabelProps({shrink: true}),
                ...inputProps,
                value: (() => {
                  if (isOpen && inputValue) return inputValue;
                  else if (!isOpen && get(formik.form.values, name)) {
                    return get(formik.form.values, name);
                  } else return '';
                })(),
              };
              const menuItemProps = ({item, index}) => {
                const itemProps = getItemProps({
                  item: item[itemKey],
                  onClick: () => onSelect({item: itemList[highlightedIndex]}),
                });
                return {
                  selected: highlightedIndex === index,
                  component: 'div',
                  style: {fontWeight: (selectedItem || '').indexOf(item[itemKey]) > -1 ? 900 : 400},
                  ...itemProps,
                };
              };
              const popperProps = {
                open: isOpen,
                anchorEl: anchor,
                placement: 'bottom-start',
                style: {zIndex: 5000, left: -200},
              };
              const paperProps = {
                className: classes.paper,
                square: true,
                style: {width: anchor ? anchor.clientWidth : undefined},
              };

              return (
                <div className={classes.container}>
                  <TextField {...textFieldProps} />
                  <Popper {...popperProps}>
                    <div {...getMenuProps({}, {suppressRefError: true})}>
                      <Paper {...paperProps}>
                        {getSuggestions(inputValue, items, itemKey).map((item, index) => (
                          <MenuItem key={index} {...menuItemProps({item, index})}>
                            {item[itemKey]}
                          </MenuItem>
                        ))}
                      </Paper>
                    </div>
                  </Popper>
                </div>
              );
            }}
          </Downshift>
        );
      }}
    </Field>
  );
};

AutoField.defaultProps = {
  filterType: 'startsWith',
  maxList: 5,
  minInput: 1,
  onSelect: () => {},
};
AutoField.propTypes = {
  filterType: PropTypes.string.isRequired,
  itemKey: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  maxList: PropTypes.number,
  minInput: PropTypes.number,
  name: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
};
export default AutoField;
