import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import logo from 'media/logo_primary.png';
import {formatForInvoice} from 'utilities.js';

const Header = ({returnDetails}) => {
  const styles = useStyles();
  return (
    <Grid container spacing={1} style={{paddingBottom: 40}}>
      <Grid item container xs={12} spacing={1}>
        <Grid item xs={8}>
          <Typography className={styles.lineHeight} variant='h6' style={{color: '#0a3982'}}>EV Battery Returns</Typography>
          <Typography className={styles.lineHeight}>125 E Commercial St suite a</Typography>
          <Typography className={styles.lineHeight}>Anaheim, CA 92801</Typography>
          <Typography className={styles.lineHeight}>https://www.kbirecycling.com</Typography>
          <Typography className={styles.lineHeight}>(714) 738-8516</Typography>
        </Grid>
        <Grid item xs={4}>
          <img src={logo} alt='KBI Logo' style={{width: '100%'}} />
        </Grid>
        <Grid item xs={6}>
          <Typography className={styles.lineHeight} style={{fontWeight: 'bold', marginTop: 30}}>DEALERSHIP INFORMATION</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={styles.lineHeight} style={{fontWeight: 'bold', marginTop: 30}}>RETURN INFORMATION</Typography>
        </Grid>
        <Grid item xs={12} container spacing={0} style={{backgroundColor: 'rgba(211, 211, 211, .3)'}}>
          <Grid item xs={6}>
            <Typography className={styles.lineHeight}>{returnDetails.DealerName}</Typography>
            <Typography className={styles.lineHeight}>{returnDetails.LocationPickup.Address1}</Typography>
            <Typography className={styles.lineHeight}>{`${returnDetails.LocationPickup.City}, ${returnDetails.LocationPickup.State} ${returnDetails.LocationPickup.PostalCode}`}</Typography>
            <Typography className={styles.lineHeight}>{returnDetails.LocationPickup.Email}</Typography>
            <Typography className={styles.lineHeight}>{formatForInvoice(returnDetails.LocationPickup.Phone)}</Typography>
          </Grid>
          <Grid item xs={6} style={{paddingLeft: 4}}>
            <div><Typography className={styles.lineHeight} style={{fontWeight: 'bold', display: 'inline'}}>Battery VIN: </Typography><Typography className={styles.lineHeight} style={{display: 'inline'}}>{returnDetails.Battery.VIN}</Typography></div>
            <div><Typography className={styles.lineHeight} style={{fontWeight: 'bold', display: 'inline'}}>Serial Number: </Typography><Typography className={styles.lineHeight} style={{display: 'inline'}}>{returnDetails.Battery.SerialNumber}</Typography></div>
            <div><Typography className={styles.lineHeight} style={{fontWeight: 'bold', display: 'inline'}}>Module Serial Numbers: </Typography>
              {returnDetails.Battery.ModuleNumbers.map((module, i) => {
                if (returnDetails.Battery.ModuleNumbers.length === i + 1) {
                  return <Typography className={styles.lineHeight} key={module} style={{display: 'inline'}}>{module}</Typography>;
                } else {
                  return <Typography className={styles.lineHeight} key={module} style={{display: 'inline'}}>{`${module}, `}</Typography>;
                }
              })}
            </div>
            <div><Typography className={styles.lineHeight} style={{fontWeight: 'bold', display: 'inline'}}>Payment Status: </Typography><Typography className={styles.lineHeight} style={{display: 'inline'}}>{returnDetails.Status}</Typography></div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  lineHeight: {
    lineHeight: 1.2,
  },
}));
Header.propTypes = {
  returnDetails: PropTypes.object.isRequired,
};
export default Header;
