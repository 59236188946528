import React, {Fragment, useMemo, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {Dialog, DialogContent, DialogTitle, Collapse, Grid, Typography} from '@material-ui/core';
import {CheckCircleOutline} from '@material-ui/icons';
import {string, object} from 'yup';
import {Alert, DialogActions} from 'components/';
import {firestoreUtility} from 'utilities';
import {Firestore} from 'firebaseConfig';
import {setCurrentUser} from 'state/actions/auth';
import {TextField, FormikForm} from 'components/formik/';

const ModalEditDealerInfo = ({open, close}) => {
  const [stage, setStage] = useState('details');
  const dispatch = useDispatch();
  const {currentUser} = useSelector(state => state.auth);

  const actionsProps = useMemo(() => ({
    close: () => {
      setStage('details');
      close();
    },
    stage,
    submitStage: 'details',
  }), [close, stage]);
  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'sm',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [open]);
  const formProps = useMemo(() => ({
    initialStatus: {alert: ''},
    initialValues: {
      DealerNo: currentUser.DealerNo,
      DealerName: currentUser.DealerName,
    },
    onSubmit: (values, actions) => {
      (async () => {
        try {
          const editDoc = {
            collection: 'Users',
            writeType: 'update',
            docId: currentUser.UserId,
            docFields: {
              DealerName: values.DealerName,
              DealerNo: values.DealerNo,
            },
          };
          await firestoreUtility(editDoc);
          const userDoc = await Firestore.collection('Users').doc(currentUser.UserId).get();
          if (userDoc && userDoc.exists) {
            const user = {
              ...userDoc.data(),
              UserId: userDoc.id,
              TimeAuthorized: currentUser.TimeAuthorized,
              TimeExpires: currentUser.TimeExpires,
            };
            dispatch(setCurrentUser(user));
          }
          setStage('success');
        } catch (error) {
          actions.setStatus({alert: error.message});
          actions.setSubmitting(false);
        }
      })();
    },
    validationSchema: object().shape({
      DealerNo: string().label('Dealer No.').required().min(2),
      DealerName: string().label('Dealer Name').required().min(2),
    }),
  }), [currentUser, dispatch]);

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {({status, isValidating, isSubmitting}) => (
          <Fragment>
            <DialogTitle>
              <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
                Edit Dealership Information
              </Collapse>
            </DialogTitle>
            <DialogContent>
              <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField name='DealerNo' label='Dealer No.' fast required />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField name='DealerName' label='Dealer Name' fast required />
                  </Grid>
                </Grid>
              </Collapse>
              <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <CheckCircleOutline style={{fontSize: '10em'}} />
                  <div style={{width: '100%'}}>
                    <Typography variant="h6" style={{textAlign: 'center'}} gutterBottom>
              User profile has been successfully added.
                    </Typography>
                  </div>
                </div>
              </Collapse>
              <Alert message={status.alert} />
            </DialogContent>
            <DialogActions {...actionsProps} isValidating={isValidating} isSubmitting={isSubmitting} />
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

ModalEditDealerInfo.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool,
};
const isMemoEqual = (prevProps, nextProps) => {
  if (prevProps.open !== nextProps.open) return false;
  return true;
};
export default React.memo(ModalEditDealerInfo, isMemoEqual);
