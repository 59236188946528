import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {CircularProgress, Button} from '@material-ui/core';
import {Delete, AddCircle} from '@material-ui/icons';
import green from '@material-ui/core/colors/green';

class SubmitButton extends React.Component {
  determineIcon() {
    const {icon, classes} = this.props;
    if (icon === 'Delete') {
      return <Delete className={classes.extendedIcon} />;
    }
    if (icon === 'AddCircle') {
      return <AddCircle className={classes.extendedIcon} />;
    }
  }
  render() {
    const {onClick, loading, disabled, classes, text, color, variant, style, size, type = 'submit'} = this.props;
    const submitButton = {
      size: size || 'medium',
      color: color || 'default',
      variant: variant || 'text',
      disabled: loading || disabled,
      type: type,
      style: {...style},
      onClick,
    };
    return (
      <Button {...submitButton}>
        {this.determineIcon()}
        {text} {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </Button>
    );
  }
}

const styles = theme => ({
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
});
SubmitButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  text: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
  variant: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  type: PropTypes.string,
};
export default withStyles(styles)(SubmitButton);
