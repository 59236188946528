import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Collapse, Paper} from '@material-ui/core';
import {Tabs, Tab, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {ExistingUserForm, NewUserForm} from './Login/index';
import logo from '../media/logo_secondary.png';
import {useSelector} from 'react-redux';

const Login = (props) => {
  const redirectPath = useSelector(state => state.auth.lastPath);
  const [action, setAction] = useState('ExistingUser');
  const tabsProps = useMemo(() => ({
    centered: true,
    style: {width: '100%', marginTop: '20px'},
    textColor: 'primary',
    onChange: (e, newValue) => setAction(newValue),
    value: action,
  }), [action]);
  const styles = useStyles();

  return (
    <div className={styles.layout}>
      <Paper className={styles.paper} elevation={3}>
        <div style={{display: 'flex'}}>
          <img alt='EV Battery Returns Logo' src={logo} style={{height: '70px', marginBottom: '24px', marginTop: '8px'}} />
        </div>
        <Typography variant='h6' style={{lineHeight: '1.2'}}>A3 Battery Service Action</Typography>
        <Typography variant='h6' style={{lineHeight: '1.2'}}>Portal Sign In</Typography>
        <Tabs {...tabsProps}>
          <Tab label='Existing User' value='ExistingUser' />
          <Tab label='New User' value='NewUser' />
        </Tabs>
        <Collapse in={action ==='NewUser'} style={{width: '100%'}}>
          <NewUserForm />
        </Collapse>
        <Collapse in={action ==='ExistingUser'} style={{width: '100%'}}>
          <ExistingUserForm redirectPath={redirectPath} />
        </Collapse>
      </Paper>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  layout: {
    width: 'auto',
    display: 'block', // Fix IE11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(6))]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
}));
Login.propTypes = {
  history: PropTypes.object.isRequired,
};
export default Login;
