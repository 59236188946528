import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Divider, Grid, Paper, Typography} from '@material-ui/core';
import {Cancel, VerifiedUser, Print} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment';

const PrintCertificate = ({returnDetails, printCancel}) => {
  const {Battery, DateReceived, LocationPickup} = returnDetails;
  const styles = useStyles();
  const core = {
    buttonCancel: {
      className: 'HidePrint',
      size: 'small',
      style: {marginBottom: '24px', marginLeft: '8px'},
      variant: 'outlined',
      onClick: () => printCancel(),
    },
    buttonPrint: {
      className: 'HidePrint',
      size: 'small',
      style: {marginBottom: '24px', marginLeft: '0px'},
      variant: 'outlined',
      onClick: () => window.print(),
    },
    paper: {
      className: styles.paper,
      elevation: 0,
      style: {display: 'flex', flexWrap: 'wrap', marginTop: '0px', maxWidth: '850px', overflow: 'hidden'},
    },
    typeHeader: {
      style: {width: '100%', marginTop: '24px', textAlign: 'center', textTransform: 'uppercase'},
      variant: 'h4',
    },
  };

  return (
    <Fragment>
      <Button {...core.buttonPrint}>
        <Print className={styles.extendedIcon} />
        Start Print
      </Button>
      <Button {...core.buttonCancel}>
        <Cancel className={styles.extendedIcon} />
        Cancel Print
      </Button>
      <Grid spacing={5} container>
        <Grid item xs={12}>
          <Paper {...core.paper}>
            <Typography {...core.typeHeader}>Certificate of Acceptance</Typography>
            <VerifiedUser style={{fontSize: '12em', fill: '#002856', padding: '40px 0px 0px 0px', width: '100%'}} />
            <Divider style={{backgroundColor: '#002856', height: '3px', margin: '40px 0px', width: '100%'}} />
            <Grid spacing={0} container>
              <Grid item xs={4} style={{textAlign: 'center'}}>
                <Typography variant="h6">Battery VIN</Typography>
                <Typography variant="body2">{Battery.VIN}</Typography>
              </Grid>
              <Grid item xs={4} style={{textAlign: 'center'}}>
                <Typography variant="h6">Number of Modules</Typography>
                <Typography variant="body2">{Battery.DefectiveModules}</Typography>
              </Grid>
              <Grid item xs={4} style={{textAlign: 'center'}}>
                <Typography variant="h6">Received Date</Typography>
                <Typography variant="body2">{moment(DateReceived).format('MM/DD/YYYY') || 'MM/DD/YYYY'}</Typography>
              </Grid>
            </Grid>
            <Grid spacing={0} container style={{paddingTop: '40px'}}>
              <Grid item xs={4} style={{textAlign: 'center'}}>
                <Typography variant="h6">Dealership Location</Typography>
                <Typography variant="body2" style={{textTransform: 'capitalize'}}>
                  {returnDetails.DealerNo} - {returnDetails.DealerName.toLowerCase()}
                </Typography>
                <Typography variant="body2" style={{textTransform: 'capitalize'}}>
                  {LocationPickup.Address1.toLowerCase()}
                </Typography>
                {LocationPickup.Address2 && (
                  <Typography variant="subtitle1" style={{textTransform: 'capitalize'}}>
                    {LocationPickup.Address2.toLowerCase()}
                  </Typography>
                )}
                <Typography variant="body2" style={{textTransform: 'capitalize'}}>
                  {LocationPickup.City.toLowerCase()}, {LocationPickup.State} {LocationPickup.PostalCode} {LocationPickup.CountryCode}
                </Typography>
              </Grid>

              <Grid item xs={4} style={{textAlign: 'center'}}>
                <Typography variant="h6">Return Location</Typography>
                <Typography variant="body2">Kinsbursky Brothers Inc.</Typography>
                <Typography variant="body2">1125 Beacon St</Typography>
                <Typography variant="body2">Brea, CA 92821</Typography>
              </Grid>
            </Grid>
            <Divider style={{backgroundColor: '#002856', height: '3px', margin: '40px 0px', width: '100%'}} />
            <Typography variant="subtitle1" style={{textAlign: 'justify'}}>
            This document certifies that the above described battery was received and accepted by an approved downstream vendor. Furthermore, we certify that all activities performed in relationship to the accepted battery will be done in full compliance with our facility permits, and in accordance with all federal, state and local laws.
            </Typography>
            <Typography variant="subtitle1" style={{textAlign: 'justify', marginTop: '24px'}}>
              This document has been issued by KBI, a California corporation, located at 125 E. Commercial St. A, Anaheim CA 92801. For more
              information about this document, contact a KBI representative by calling 800-502-9258 or by emailing info@evbattmgmt.com.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  extendedIcon: {
    marginRight: '8px',
  },
  paper: {
    width: '100%',
    display: 'flex',
    marginTop: '24px',
    overflowX: 'hidden',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));
PrintCertificate.propTypes = {
  printCancel: PropTypes.func.isRequired,
  returnDetails: PropTypes.object.isRequired,
};
export default PrintCertificate;
