import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {ListItem, ListItemText, Divider, ListItemIcon} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {NavLink} from 'react-router-dom';
import includes from 'lodash.includes';

const MenuItem = ({Icon, link, padding, text}) => {
  const styles = makeStyles({
    listItemButton: {padding},
  })();
  const doesURLMatch = () => {
    if (link === window.location.pathname) return true;
    else return false;
  };
  const doesURLContain = () => {
    return includes(window.location.href, link);
  };

  return (
    <Fragment>
      <NavLink to={link} replace={doesURLMatch()}>
        <ListItem button selected={doesURLContain()} className={styles.listItemButton}>
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      </NavLink>
      <Divider />
    </Fragment>
  );
};

MenuItem.propTypes = {
  Icon: PropTypes.elementType.isRequired,
  link: PropTypes.string.isRequired,
  padding: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};
export default MenuItem;
