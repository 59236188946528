import React from 'react';
import PropTypes from 'prop-types';
import {Typography, ListItemIcon, ListItemText, MenuItem, MenuList} from '@material-ui/core';
import {CloudDownload} from '@material-ui/icons';

const DownloadableDocuments = ({returnDetails}) => {
  const getFileCount = () => {
    const files = returnDetails ? returnDetails.Files : null;
    if (!files) return 0;
    else return Object.keys(files).length;
  };
  const core = {
    menuSide: {
      style: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: 'ghostwhite',
        border: '1px solid darkgray',
        margin: '8px 0px',
      },
    },
  };

  if (!returnDetails.Files || getFileCount() === 0) return null;
  return (
    <MenuList {...core.menuSide}>
      <Typography variant="subtitle1" style={{width: '100%', textAlign: 'left', marginLeft: '8px'}}>
          Downloadable Documents
      </Typography>
      {returnDetails.Files.BOL && (
        <MenuItem onClick={() => window.open(returnDetails.Files.BOL, '_blank')}>
          <ListItemIcon>
            <CloudDownload />
          </ListItemIcon>
          <ListItemText primary="Bill of Lading" />
        </MenuItem>
      )}
      {returnDetails.Files.HazmatMarking && (
        <MenuItem onClick={() => window.open(returnDetails.Files.HazmatMarking, '_blank')}>
          <ListItemIcon>
            <CloudDownload />
          </ListItemIcon>
          <ListItemText primary="Hazmat Marking" />
        </MenuItem>
      )}
      {returnDetails.Files.ShippingLabel && (
        <MenuItem onClick={() => window.open(returnDetails.Files.ShippingLabel, '_blank')}>
          <ListItemIcon>
            <CloudDownload />
          </ListItemIcon>
          <ListItemText primary="Shipping Label" />
        </MenuItem>
      )}
    </MenuList>
  );
};

DownloadableDocuments.propTypes = {
  returnDetails: PropTypes.object.isRequired,
};
export default DownloadableDocuments;
