import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'formik';
import {Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
const useStyles = makeStyles({
  label: {
    fontSize: '0.8rem',
  },
});
const CheckboxGroup = ({boxes, children, label, name, smallFont, ...otherProps}) => {
  const classes = useStyles();
  const formControlProps = ({field, form}) => ({
    // console.log('CheckboxGroup { props, field, form }', { props, field, form });
    disabled: form.isSubmitting,
    error: Boolean(form.touched[name] && form.errors[name]),
    fullWidth: true,
    margin: 'dense',
    ...otherProps,
  });

  return (
    <Field name={name}>
      {({field, form}) => (
        <FormControl component="fieldset" id={name} {...formControlProps({field, form})}>
          <FormLabel component="legend">{label || name}</FormLabel>
          <FormGroup>
            {boxes.map(({label, value}) => {
              return <FormControlLabel key={value} label={label} classes={smallFont && {label: classes.label}} control={<Checkbox {...field} value={value} color='primary' checked={field.value.includes(value)} />} />;
            })}
          </FormGroup>
          {form.touched[name] && form.errors[name] && <FormHelperText error={true}>{form.errors[name]}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  );
};

CheckboxGroup.propTypes = {
  boxes: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  children: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  smallFont: PropTypes.bool,
};
export default CheckboxGroup;
