import React, {Fragment, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import {Button, Collapse, Grid, MenuItem, Typography} from '@material-ui/core';
import {CheckCircleOutline} from '@material-ui/icons';
import get from 'lodash.get';
import {object, string} from 'yup';
import {FormikForm, SelectField, TextField} from 'components/formik';
import {Alert} from 'components';
import {firestoreUtility} from 'utilities';

const ModalEmailDocuments = ({close, open, returnDetails}) => {
  const [stage, setStage] = useState('sendEmail');
  const {currentUser} = useSelector(state => state.auth);
  const handleClose = () => {
    setStage('sendEmail');
    close();
  };
  const getFiles = () => {
    return {
      BOL: get(returnDetails, 'Files.BOL', null),
      HazmatMarking: get(returnDetails, 'Files.HazmatMarking', null),
      ShippingLabel: get(returnDetails, 'Files.ShippingLabel', null),
    };
  };
  const dialogProps = {
    fullWidth: true,
    maxWidth: 'sm',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  };
  const formProps = {
    initialStatus: {alert: ''},
    initialValues: {
      CopySender: 'Yes',
      SendTo: get(returnDetails, 'LocationPickup.Email', ''),
      SendBOL: 'Include',
      SendHM: 'Include',
      SendSL: 'Include',
    },
    onSubmit: async (values, actions) => {
      try {
        const addDoc = {
          collection: 'Emails',
          writeType: 'add',
          docFields: {
            to: values.SendTo,
            cc: values.CopySender === 'Yes' ? currentUser.Email : '',
            template: {
              data: {
                BatteryDetailURL: window.location.href,
                VIN: get(returnDetails, 'Battery.VIN', null) ? returnDetails.Battery.VIN : '',
                BOL: values.SendBOL === 'Include' && Boolean(returnDetails.Files.BOL) ? returnDetails.Files.BOL : '',
                HazmatMarking: values.SendHM === 'Include' && Boolean(returnDetails.Files.HazmatMarking) ? returnDetails.Files.HazmatMarking : '',
                ShippingLabel: values.SendSL === 'Include' && Boolean(returnDetails.Files.ShippingLabel) ? returnDetails.Files.ShippingLabel : '',
              },
              name: 'SendFiles',
            },
          },
        };
        await firestoreUtility(addDoc);
        setStage('success');
      } catch (error) {
        actions.setStatus({alert: error.message});
        actions.setSubmitting(false);
      }
    },
    validationSchema: object().shape({
      CopySender: string().required().label('Copy Sender'),
      SendTo: string().required().label('Send To'),
      SendBOL: string().required().label('Send BOL'),
      SendHM: string().required().label('Send HM'),
      SendSL: string().required().label('Send SL'),
    }),
  };

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {({setStatus, status, isSubmitting, isValidating, setFieldValue}) => (
          <Fragment>
            <DialogTitle>
              <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
                Email Documents
              </Collapse>
            </DialogTitle>
            <DialogContent>
              <Collapse in={stage === 'sendEmail'} timeout={{enter: 500, exit: 500}}>
                <DialogContentText>Select documents to include and click send button.</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm>
                    <TextField name="SendTo" label="Send To" required />
                  </Grid>
                  <Grid item xs={12} sm>
                    <SelectField name="CopySender" label="Copy Sender" required>
                      {['Yes', 'No'].map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  {getFiles().BOL && (
                    <Grid item xs={12} sm>
                      <SelectField name="SendBOL" label="Send BOL" required>
                        {['Include', 'Exclude'].map(option => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </SelectField>
                    </Grid>
                  )}
                  {getFiles().HazmatMarking && (
                    <Grid item xs={12} sm>
                      <SelectField name="SendHM" label="Send HM" required>
                        {['Include', 'Exclude'].map(option => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </SelectField>
                    </Grid>
                  )}
                  {getFiles().ShippingLabel && (
                    <Grid item xs={12} sm>
                      <SelectField name="SendSL" label="Send SL" required>
                        {['Include', 'Exclude'].map(option => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </SelectField>
                    </Grid>
                  )}
                </Grid>
              </Collapse>
              <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <CheckCircleOutline style={{fontSize: '10em'}} />
                  <Typography variant="h6" style={{textAlign: 'center'}} gutterBottom>
                    Email has been successfully sent.
                  </Typography>
                </div>
              </Collapse>
              <Alert message={status.alert} />
            </DialogContent>
            <Collapse in={stage === 'sendEmail'} timeout={{enter: 500, exit: 500}}>
              <DialogActions>
                <Button onClick={handleClose} color="primary" disabled={isSubmitting || isValidating}>
                  Cancel
                </Button>
                <Button type="submit" color="primary" disabled={isSubmitting || isValidating}>
                  Send
                </Button>
              </DialogActions>
            </Collapse>
            <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
              <DialogActions>
                <Button onClick={() => handleClose()} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Collapse>
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

ModalEmailDocuments.propTypes = {
  close: PropTypes.func.isRequired,
  returnDetails: PropTypes.object.isRequired,
  open: PropTypes.bool,
};
const isMemoEqual = (prevProps, nextProps) => {
  if (prevProps.open !== nextProps.open) return false;
  return true;
};

export default React.memo(ModalEmailDocuments, isMemoEqual);
