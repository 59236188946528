import {useEffect, useRef} from 'react';
import {Firestore} from 'firebaseConfig';
import includes from 'lodash.includes';
import libphonenumber from 'google-libphonenumber';

// Firestore Utilites
const firestoreWrite = (utilitySettings) => {
  const errNotice = 'firestoreUtility error:';
  if (!utilitySettings) throw new Error(`${errNotice} settings object parameter {collection, writeType, docFields, docId} required`);
  const {collection, docFields, docId, writeType} = utilitySettings;

  if (!collection) throw new Error(`${errNotice} collection property [e.g. 'BatteryReturns'] is required`);
  if (!docFields) throw new Error(`${errNotice} docFields property is required`);
  if (!writeType || !includes(['add', 'set', 'update'], writeType)) {
    throw new Error(`${errNotice} writeType property ['add', 'set', 'update'] is required`);
  }

  const collectionRef = Firestore.collection(collection);
  if (writeType === 'add') return collectionRef.add(docFields);
  else if (writeType === 'set' && !docId) throw new Error(`${errNotice} docId property required for 'set' writeType`);
  else if (writeType === 'set' && docId) return collectionRef.doc(docId).set(docFields);
  else if (writeType === 'update' && !docId) throw new Error(`${errNotice} docId property required for 'update' writeType`);
  else if (writeType === 'update' && docId) return collectionRef.doc(docId).update(docFields);
};
export const firestoreUtility = async utilitySettings => {
  try {
    const response = await firestoreWrite(utilitySettings);
    return response || null;
  } catch (error) {
    throw new Error(error.message);
  }
};

// Phone Number Formatting Utilities
export const formatPhoneNumber = phoneNumberString => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};
export const getPhoneE164 = numberToFormat => {
  try {
    const PNF = libphonenumber.PhoneNumberFormat;
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    const number = phoneUtil.parseAndKeepRawInput(numberToFormat, 'US');
    return phoneUtil.format(number, PNF.E164);
  } catch (error) {
    return '';
  }
};

export const formatForInvoice = phoneNumberString => {
  const match = phoneNumberString.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
};

// Regex Utilities
export const postalRegex = /^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/im; // eslint-disable-line no-useless-escape
export const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im; // eslint-disable-line no-useless-escape

// Trace Component Updates (Why Did It Render)
export const useTraceUpdate = (props, name = 'Component') => {
  // HOW TO USE
  // const MyComponent = (props) => {
  //   useTraceUpdate(props);
  //   return <div>{props.children}</div>;
  // }
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (process.env.NODE_ENV === 'development' && Object.keys(changedProps).length > 0) {
      console.log(`${name} Changed props:`, changedProps);
    }
    prev.current = props;
  });
};
