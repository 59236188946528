import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'formik';
import {FormControl, FormHelperText, FormLabel} from '@material-ui/core';
import MuiRadioGroup from '@material-ui/core/RadioGroup';

const RadioGroup = ({children, label, name, ...otherProps}) => {
  const formControlProps = ({field, form}) => ({
    // console.log('RadioGroup { props, field, form }', { props, field, form });
    disabled: form.isSubmitting,
    error: Boolean(form.touched[name] && form.errors[name]),
    fullWidth: true,
    margin: 'dense',
    ...otherProps,
  });

  return (
    <Field name={name}>
      {({field, form}) => (
        <FormControl component="fieldset" id={name} {...formControlProps({field, form})}>
          <FormLabel component="legend">{label || name}</FormLabel>
          <MuiRadioGroup {...field}>{children}</MuiRadioGroup>
          {form.touched[name] && form.errors[name] && <FormHelperText error={true}>{form.errors[name]}</FormHelperText>}
        </FormControl>
      )}
    </Field>
  );
};

RadioGroup.propTypes = {
  children: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};
export default RadioGroup;
