export const states = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'GU',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
].map(item => ({
  value: item,
}));
export const provinces = ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'].map(item => ({
  value: item,
}));

export const dispositions = ['Recycle', 'Repair', 'Repurpose'];

export const batteryHazards = [
  'Battery is warm to the touch (i.e. above room temperature).',
  'Battery has signs of heat damage, such as burn marks or warping.',
  'Battery has exposed/unprotected wires capable of causing an electric shock.',
  'Battery has signs of physical damage, such as dented, breached, or swollen components.',
  'Battery has other indicators that it may not be safe to transport.',
];
export const currencyOption = {style: 'currency', currency: 'USD'};
export const batteryKitPrice = 125.50;
export const recyclingFee = 396.72;
export const programAdminFee = 125.00;
export const priceByState = {
  AK: {
    zone: 8,
    inboundRate: 0,
    outboundPriority: 0,
    outboundEconomy: 0,
  },
  AL: {
    zone: 5,
    inboundRate: 249.31,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  AR: {
    zone: 4,
    inboundRate: 249.31,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  AZ: {
    zone: 2,
    inboundRate: 214.02,
    outboundPriority: 55.00,
    outboundEconomy: 35.00,
  },
  CA: {
    zone: 1,
    inboundRate: 214.02,
    outboundPriority: 55.00,
    outboundEconomy: 35.00,
  },
  CO: {
    zone: 3,
    inboundRate: 214.02,
    outboundPriority: 55.00,
    outboundEconomy: 35.00,
  },
  CT: {
    zone: 7,
    inboundRate: 299.61,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  DC: {
    zone: 6,
    inboundRate: 289.15,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  DE: {
    zone: 6,
    inboundRate: 299.61,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  FL: {
    zone: 6,
    inboundRate: 299.61,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  GA: {
    zone: 6,
    inboundRate: 249.31,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  HI: {
    zone: 8,
    inboundRate: 0,
    outboundPriority: 0,
    outboundEconomy: 0,
  },
  IA: {
    zone: 4,
    inboundRate: 220.24,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  ID: {
    zone: 2,
    inboundRate: 214.02,
    outboundPriority: 55.00,
    outboundEconomy: 35.00,
  },
  IL: {
    zone: 4,
    inboundRate: 220.24,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  IN: {
    zone: 5,
    inboundRate: 220.24,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  KS: {
    zone: 3,
    inboundRate: 233.07,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  KY: {
    zone: 5,
    inboundRate: 278.31,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  LA: {
    zone: 4,
    inboundRate: 249.31,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  MA: {
    zone: 6,
    inboundRate: 299.61,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  MD: {
    zone: 6,
    inboundRate: 289.15,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  ME: {
    zone: 7,
    inboundRate: 299.61,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  MI: {
    zone: 5,
    inboundRate: 283.78,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  MN: {
    zone: 4,
    inboundRate: 299.61,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  MO: {
    zone: 4,
    inboundRate: 249.31,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  MS: {
    zone: 4,
    inboundRate: 249.31,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  MT: {
    zone: 3,
    inboundRate: 299.61,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  NC: {
    zone: 6,
    inboundRate: 278.31,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  ND: {
    zone: 3,
    inboundRate: 299.61,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  NE: {
    zone: 3,
    inboundRate: 233.07,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  NH: {
    zone: 7,
    inboundRate: 299.61,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  NJ: {
    zone: 6,
    inboundRate: 289.15,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  NM: {
    zone: 3,
    inboundRate: 214.02,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  NV: {
    zone: 1,
    inboundRate: 214.02,
    outboundPriority: 55.00,
    outboundEconomy: 35.00,
  },
  NY: {
    zone: 6,
    inboundRate: 289.15,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  OH: {
    zone: 5,
    inboundRate: 289.15,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  OK: {
    zone: 3,
    inboundRate: 233.07,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  OR: {
    zone: 1,
    inboundRate: 214.02,
    outboundPriority: 55.00,
    outboundEconomy: 35.00,
  },
  PA: {
    zone: 6,
    inboundRate: 289.15,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  PR: {
    zone: 8,
    inboundRate: 0,
    outboundPriority: 0,
    outboundEconomy: 0,
  },
  RI: {
    zone: 7,
    inboundRate: 299.61,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  SC: {
    zone: 6,
    inboundRate: 278.31,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  SD: {
    zone: 3,
    inboundRate: 299.61,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  TN: {
    zone: 5,
    inboundRate: 278.31,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  TX: {
    zone: 3,
    inboundRate: 214.02,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  UT: {
    zone: 2,
    inboundRate: 214.02,
    outboundPriority: 55.00,
    outboundEconomy: 35.00,
  },
  VA: {
    zone: 6,
    inboundRate: 278.31,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  VT: {
    zone: 7,
    inboundRate: 299.61,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  WA: {
    zone: 2,
    inboundRate: 214.02,
    outboundPriority: 55.00,
    outboundEconomy: 35.00,
  },
  WI: {
    zone: 4,
    inboundRate: 233.07,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  WV: {
    zone: 6,
    inboundRate: 278.31,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
  WY: {
    zone: 3,
    inboundRate: 299.61,
    outboundPriority: 105.53,
    outboundEconomy: 35.00,
  },
};
export const calculateTotal = (moduleCount, state, formik) => {
  const total = (moduleCount * (batteryKitPrice + priceByState[state]['outboundEconomy'])) + recyclingFee + priceByState[state].inboundRate + programAdminFee;
  if (formik.values.Total !== total.toFixed(2)) {
    formik.setFieldValue('Total', total.toFixed(2));
  }
  return total.toLocaleString('en-US', currencyOption);
};

export const adminEmail = 'ar@kbirecycling.com';
