import React from 'react'; // eslint-disable-line no-unused-vars
import {SET_BATTERY_RETURNS} from 'state/types';
import {Firestore} from 'firebaseConfig';


const getBatteryPickupCollection = ({currentUser, dispatch}) => {
  const batteryReturnsRef = Firestore.collection('BatteryReturns');
  const ref = {};
  const list = [];
  const options = {includeMetadataChanges: true};
  const query = () => {
    if (currentUser.Role === 'Dealership') return batteryReturnsRef.where('UserRef', '==', currentUser.UserId);
    else if (currentUser.Role === 'Admin') return batteryReturnsRef;
  };
  const handleQuerySnap = async snap => {
    const snapSize = snap.docChanges().length;
    if (snapSize > 0) {
      const findDocIndex = id => {
        const foundIndex = list.findIndex(item => {
          return item.BatteryReturnId === id;
        });
        return foundIndex;
      };
      for (let i = 0; i < snapSize; i++) {
        const {type, doc} = snap.docChanges()[i];
        const data = doc.data();
        const docData = {
          ...data,
          BatteryReturnId: doc.id,
        };
        if (type === 'added') {
          list.push(docData);
          ref[doc.id] = docData;
        }
        if (type === 'modified') {
          list.splice(findDocIndex(doc.id), 1, docData);
          ref[doc.id] = docData;
        }
        if (type === 'removed') {
          list.splice(findDocIndex(doc.id), 1);
          ref[doc.id] = docData;
        }
      }
    }
    dispatch({type: SET_BATTERY_RETURNS, payload: {list: [...list], ref: {...ref}}});
  };
  const handleQueryError = error => {
    console.error('Error: ', error);
  };
  return query().onSnapshot(options, handleQuerySnap, handleQueryError);
};

// View Listeners
// Note: Listeners should only be placed in `portalListener` when used in more than one view.
export const portalListener = ({currentUser, dispatch}) => () => {
  if (currentUser) {
    const batteryReturnCollectionListener = getBatteryPickupCollection({currentUser, dispatch});
    const unsubscribe = () => {
      if (batteryReturnCollectionListener) batteryReturnCollectionListener();
    };
    return () => unsubscribe();
  }
};
