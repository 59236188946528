import React from 'react';
import PropTypes from 'prop-types';
import MuiTextField from '@material-ui/core/TextField';
import {Field, FastField} from 'formik';
import get from 'lodash.get';

const TextField = ({fast, name, onBlur, onChange, ...otherProps}) => {
  const textFieldProps = ({form, field}) => {
    // console.log('TextField { props, field, form }', { field, form });
    return {
      disabled: form.isSubmitting,
      error: get(form.touched, name) && get(form.errors, name) ? true : false,
      fullWidth: true,
      helperText: get(form.touched, name) && get(form.errors, name),
      id: name,
      label: name,
      margin: 'dense',
      ...otherProps,
      onBlur: e => {
        field.onBlur(e);
        if (onBlur) onBlur({field, form});
      },
      onChange: e => {
        field.onChange(e);
        if (onChange) onChange({field, form});
      },
    };
  };

  if (fast) {
    return (
      <FastField name={name}>
        {({form, field}) => <MuiTextField {...field} {...textFieldProps({form, field})} />}
      </FastField>
    );
  }
  return (
    <Field name={name}>{({form, field}) => <MuiTextField {...field} {...textFieldProps({form, field})} />}</Field>
  );
};

TextField.defaultProps = {
  fast: false,
};
TextField.propTypes = {
  fast: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  TextFieldProps: PropTypes.object,
};
export default TextField;
