import React from 'react';
import PropTypes from 'prop-types';
import SubmitButton from './SubmitButton';
import {DialogActions, Button} from '@material-ui/core';

const cancelButton = (isSubmitting, onClick) => ({
  onClick: onClick,
  color: 'secondary',
  disabled: isSubmitting,
});

const submitButton = (loading, onSubmit, stage, submitStage) => ({
  onClick: onSubmit,
  loading,
  text: stage === submitStage ? 'Submit' : 'Next',
  color: 'primary',
  type: 'submit',
});

const Actions = ({close, isValidating, isSubmitting, stage, onSubmit, submitStage}) => {
  return (
    <DialogActions style={{justifyContent: 'flex-end'}}>
      {stage === 'success' ? (
        <Button onClick={close} color='primary'>Close</Button>
      ) : (
        <>
          <Button {...cancelButton(isSubmitting, close)}>Cancel</Button>
          <SubmitButton {...submitButton(isValidating || isSubmitting, onSubmit, stage, submitStage)} />
        </>
      )}
    </DialogActions>
  );
};

Actions.propTypes = {
  close: PropTypes.func.isRequired,
  isValidating: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  stage: PropTypes.string.isRequired,
  submitStage: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
};

export default React.memo(Actions);
