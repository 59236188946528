import React from 'react';
import {Typography, Card, Button, CardActions, CardContent, CardHeader, MenuItem} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {Alert} from 'components/';
import {string, object} from 'yup';
import {firestoreUtility, phoneRegex} from 'utilities';
import {TextField, SelectField, FormikForm} from 'components/formik/';

const Contact = () => {
  const core = {
    formGrid: {
      item: true,
      xs: 12,
      sm: 12,
      md: 6,
    },
    subheading: {
      style: {
        fontWeight: 'bold',
        marginTop: 12,
      },
      variant: 'subtitle1',
    },
    submitButton: {
      type: 'submit',
      color: 'primary',
      style: {margin: useTheme().spacing(1), marginTop: useTheme().spacing(3)},
      variant: 'contained',
    },
  };
  const formProps = {
    initialStatus: {alert: '', success: ''},
    initialValues: {
      Purpose: '',
      Name: '',
      Phone: '',
      Email: '',
      Comment: '',
    },
    validationSchema: object().shape({
      Purpose: string().label('Contact Purpose').required(),
      Name: string().required(),
      Phone: string().required().matches(phoneRegex, 'Phone is improperly formatted. (e.g. ###-###-####)'),
      Email: string().required().email(),
      Comment: string().required(),
    }),
    onSubmit: async (values, actions) => {
      actions.setStatus({alert: '', success: ''});
      try {
        const addDoc = {
          collection: 'Emails',
          writeType: 'add',
          docFields: {
            to: 'a3-support@evbatteryreturns.com',
            replyTo: values.Email,
            template: {
              data: {...values},
              name: 'ContactRequest',
            },
          },
        };
        await firestoreUtility(addDoc);
        actions.resetForm();
        actions.setStatus({success: 'Information successfully sent'});
      } catch (error) {
        actions.setStatus({alert: error.message});
        actions.setSubmitting(false);
      }
    },
  };
  return (
    <Grid container style={{flexGrow: 1}} spacing={2}>
      <Grid item xs={12} sm={6}>
        <Card style={{minHeight: '100%'}}>
          <CardHeader title='Contact Shipper' />
          <CardContent>
            <Typography variant='body1'>
              If you have any questions about any of the services offered by our company, please contact us or submit
              the contact form on this page and one of our helpful sales representatives will be in touch shortly.
            </Typography>
            <Typography {...core.subheading}>Principal Address</Typography>
            <Typography variant='body1'>
              125 E Commercial St A<br />
              Anaheim, CA 92801
            </Typography>
            <Typography {...core.subheading}>Contact</Typography>
            <Typography variant='body1'>
              Direct: 800-502-9258
              <br />
              Fax: 714-510-2962
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card style={{minHeight: '100%'}}>
          <FormikForm {...formProps}>
            {({status, isSubmitting, isValidating, setFieldValue, values}) => (
              <>
                <CardHeader title='Contact Form' />
                <CardContent>
                  <Grid container style={{flexGrow: 1}} spacing={2}>
                    <Grid {...core.formGrid}>
                      <SelectField name='Purpose' label='Contact Purpose' fast required >
                        {['Scheduling Battery Return ', 'Portal Technical Support', 'Other'].map(purpose => {
                          return (
                            <MenuItem key={purpose} value={purpose}>
                              {purpose}
                            </MenuItem>
                          );
                        })}
                      </SelectField>
                    </Grid>
                    <Grid {...core.formGrid}>
                      <TextField name='Name' fast required />
                    </Grid>
                    <Grid {...core.formGrid}>
                      <TextField name='Phone' fast required />
                    </Grid>
                    <Grid {...core.formGrid}>
                      <TextField name='Email' fast required />
                    </Grid>
                    <Grid {...core.formGrid} md={12}>
                      <TextField name='Comment' multiline fast required />
                    </Grid>
                    <Alert message={status.alert} />
                    <Alert message={status.success} type='success' color='#31a731' />
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button disabled={isSubmitting || isValidating} {...core.submitButton}>Submit</Button>
                </CardActions>
              </>
            )}
          </FormikForm>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Contact;
