import React, {Fragment} from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Button, Paper, Typography} from '@material-ui/core';
import {Pageview, GetApp} from '@material-ui/icons';
import {Table} from '@kbi/component-library';
import {exportToCSV} from '@kbi/utility-library';

const ViewBatteryReturnsTable = ({history, setModal}) => {
  const {firestore: {batteryReturns}, auth: {currentUser}} = useSelector(state => state);
  const getColumns = () => {
    if (currentUser.Role === 'Dealership') {
      return [
        {Header: 'Date Requested', accessor: 'Date.Requested', type: 'timestamp'},
        {Header: 'Status', accessor: 'Status'},
        {Header: 'VIN', accessor: 'Battery.VIN'},
        {Header: 'Hazmat Certified', accessor: 'Hazmat.Certified', type: 'boolean'},
      ];
    } else if (currentUser.Role === 'Admin') {
      return [
        {Header: 'Dealer No.', accessor: 'DealerNo'},
        {Header: 'Dealer Name', accessor: 'DealerName'},
        {Header: 'Date Requested', accessor: 'Date.Requested', type: 'timestamp'},
        {Header: 'Reminder', accessor: 'Date.Reminder', type: 'timestamp'},
        {Header: 'Status', accessor: 'Status', filterField: 'select'},
        {Header: 'VIN', accessor: 'Battery.VIN'},
        {Header: 'Hazmat Certified', accessor: 'Hazmat.Certified', type: 'boolean'},
        {Header: 'DDR Kit Qty', accessor: 'Battery.DefectiveModules', type: 'numeric'},
      ];
    }
  };

  if (batteryReturns.list.length === 0 || !currentUser) return null;
  const returnsTableProps = {
    actionsPerRow: [
      {icon: Pageview, tooltip: 'View Return Details', onClick: ({rowData}) => history.push(`/portal/battery-returns/${rowData.BatteryReturnId}`)}, // eslint-disable-line
    ],
    actionsBar: [{icon: GetApp, text: 'Export', onClick: ({tableData}) => {
      exportToCSV(tableData.data, getColumns(), 'battery-return-requests');
    }}],
    data: batteryReturns.list,
    columns: getColumns(),
    disableFilters: currentUser.Role !== 'Admin',
    sortInitial: [{id: 'Date.Requested'}],
    paginationInitialSize: currentUser.Role === 'Admin' ? 10 : 5,
    paginationSizes: [5, 10, 25, 50],
  };

  return (
    <Fragment>
      <Button color='primary' variant='outlined' onClick={() => setModal()}>
        Schedule New Return
      </Button>
      <Paper elevation={2} style={{marginTop: '24px'}}>
        <div style={{height: 64, display: 'flex', alignItems: 'center', paddingLeft: 16}}>
          <Typography variant='h6'>Battery Return Requests</Typography>
        </div>
        <Table {...returnsTableProps} />
      </Paper>
    </Fragment>
  );
};

ViewBatteryReturnsTable.propTypes = {
  history: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
};
export default withRouter(ViewBatteryReturnsTable);


// lookup: {'Request Received': 'Request Received', 'Module Kits Shipped': 'Module Kits Shipped', 'Shipment Scheduled': 'Shipment Scheduled', 'Received': 'Received', 'Pending Invoice': 'Pending Invoice', 'Invoiced': 'Invoiced', 'Paid': 'Paid'}
