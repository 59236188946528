import React, {Fragment, useCallback, useMemo, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {Button, Collapse, Grid, MenuItem, Paper, Radio, Typography, FormControlLabel} from '@material-ui/core';
import {List, ListItem, ListItemText, ListItemSecondaryAction} from '@material-ui/core';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import {CheckCircleOutline} from '@material-ui/icons';
import {Alert} from '@kbi/component-library';
import get from 'lodash.get';
import {array, number, object, string} from 'yup';
import moment from 'moment';
import {AutoField, CheckboxGroup, FormikForm, RadioGroup, SelectField, TextField, VinField} from 'components/formik';
import {states, batteryHazards, batteryKitPrice, recyclingFee, programAdminFee, priceByState, currencyOption, calculateTotal} from 'constLists';
import {firestoreUtility, postalRegex, phoneRegex} from 'utilities';
import {setCurrentUser} from 'state/actions/auth';

const ModalNewReturn = ({open, close}) => {
  const [stage, setStage] = useState('pickup');
  const {currentUser} = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    setStage('pickup');
    close();
  }, [close]);
  const handleRadioChange = useCallback((event, formik) => {
    if (event.target.value === 'newAddress') {
      formik.setFieldValue('Address1', '');
      formik.setFieldValue('Address2', '');
      formik.setFieldValue('City', '');
      formik.setFieldValue('State', '');
      formik.setFieldValue('PostalCode', '');
      formik.setTouched({});
    } else {
      const location = currentUser.Locations[event.target.value];
      formik.setFieldValue('Address1', location.Address1);
      formik.setFieldValue('Address2', location.Address2);
      formik.setFieldValue('City', location.City);
      formik.setFieldValue('State', location.State);
      formik.setFieldValue('PostalCode', location.PostalCode);
      formik.setTouched({});
    }
  }, [currentUser.Locations]);
  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'sm',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [open]);
  const formProps = useMemo(() => ({
    initialStatus: {alert: ''},
    initialValues: {
      DealerName: currentUser.Role === 'Dealership' ? get(currentUser, 'DealerName', '') : '',
      DealerNo: currentUser.Role === 'Dealership' ? get(currentUser, 'DealerNo', '') : '',
      Address: currentUser.Locations.length > 0 ? '0' : 'newAddress',
      Address1: currentUser.Locations.length > 0 ? currentUser.Locations[0].Address1 : '',
      Address2: currentUser.Locations.length > 0 ? currentUser.Locations[0].Address2 : '',
      City: currentUser.Locations.length > 0 ? currentUser.Locations[0].City : '',
      State: currentUser.Locations.length > 0 ? currentUser.Locations[0].State : '',
      PostalCode: currentUser.Locations.length > 0 ? currentUser.Locations[0].PostalCode : '',
      ContactName: get(currentUser, 'NameFull', ''),
      Phone: get(currentUser, 'Phone', ''),
      Fax: '',
      Email: get(currentUser, 'Email', ''),
      BatteryVIN: '',
      BatteryModules: '',
      BatteryHazards: [],
      HazmatCertified: '',
      CertifiedEmployee: '',
      ShippingType: 'outboundEconomy',
      AcceptPayment: '',
    },
    onSubmit: (values, actions) => {
      const moveToStage = (newStage) => {
        setStage(newStage);
        actions.setTouched({});
        actions.setSubmitting(false);
      };
      const saveNewAddress = async () => {
        const newLocations = [...currentUser.Locations, {
          Address1: values.Address1,
          Address2: values.Address2,
          City: values.City,
          State: values.State,
          PostalCode: values.PostalCode,
        }];
        const updateLocations = {
          collection: 'Users',
          writeType: 'update',
          docId: currentUser.UserId,
          docFields: {Locations: newLocations},
        };
        await firestoreUtility(updateLocations).catch(error => {
          // Non-vital error message; Only console.log in development environment.
          if (process.env.NODE_ENV === 'development') console.log('updateLocations Error: ', error.message);
        });
        dispatch(setCurrentUser({...currentUser, Locations: newLocations}));
      };
      const submitForm = async () => {
        try {
          const addDocument = {
            collection: 'BatteryReturns',
            writeType: 'add',
            docFields: {
              Accounting: (() => {
                const charges = {
                  RecyclingFee: recyclingFee,
                  ProgramAdminFee: programAdminFee,
                  ModuleKit: values.BatteryModules * batteryKitPrice,
                  InboundRate: priceByState[values.State].inboundRate,
                  OutboundRate: values.BatteryModules * priceByState[values.State][values.ShippingType],
                };
                if (values.HazmatCertified === 'Non-Hazmat') charges.ThirdParty = null;
                return charges;
              })(),
              Battery: {
                Hazards: values.BatteryHazards,
                DefectiveModules: values.BatteryModules,
                VIN: values.BatteryVIN.toUpperCase(),
              },
              Date: {
                Received: null,
                Reminder: moment().add(7, 'days').toDate(),
                Requested: new Date(),
              },
              DealerName: values.DealerName,
              DealerNo: values.DealerNo,
              Hazmat: {
                Certified: values.HazmatCertified === 'Hazmat' ? true : false,
                Employee: values.CertifiedEmployee,
                CertifiedBy: {
                  Email: currentUser.Email || null,
                  Name: currentUser.NameFull || null,
                  Phone: currentUser.Phone || null,
                  Role: currentUser.Role || null,
                  UserId: currentUser.UserId || null,
                },
              },
              LocationPickup: {
                Address1: values.Address1,
                Address2: values.Address2,
                City: values.City,
                State: values.State,
                PostalCode: values.PostalCode,
                Contact: values.ContactName,
                Phone: values.Phone,
                Fax: values.Fax,
                Email: values.Email,
              },
              Notes: [{
                Note: `Battery return requested on ${moment().format('MM/DD/YYYY')} by ${currentUser.NameFull} (${currentUser.Email}).`,
                CreatedOn: new Date(),
                CreatedBy: 'System',
              }],
              ShipmentDetails: null,
              Status: 'Request Received',
              UserRef: currentUser.UserId,
            },
          };
          const newDocument = await firestoreUtility(addDocument);
          const addEmail = {
            collection: 'Emails',
            writeType: 'add',
            docFields: {
              to: currentUser.Role === 'Admin' ? [values.Email, 'a3-support@evbatteryreturns.com'] : [currentUser.Email, values.Email, 'a3-support@evbatteryreturns.com'],
              template: {
                data: {
                  NotCertified: values.HazmatCertified === 'Hazmat' ? false : true,
                  BatteryVIN: values.BatteryVIN.toUpperCase(),
                  DocId: newDocument.id,
                  FirstName: currentUser.NameFirst,
                  ModuleCount: values.BatteryModules,
                  Recycling: (recyclingFee + programAdminFee).toLocaleString('en-US', currencyOption),
                  Kits: (values.BatteryModules * batteryKitPrice).toLocaleString('en-US', currencyOption),
                  Transportation: (priceByState[values.State].inboundRate + (values.BatteryModules * priceByState[values.State][values.ShippingType])).toLocaleString('en-US', currencyOption),
                  Total: (recyclingFee + programAdminFee + (values.BatteryModules * batteryKitPrice) + priceByState[values.State].inboundRate + (values.BatteryModules * priceByState[values.State][values.ShippingType])).toLocaleString('en-US', currencyOption), // eslint-disable-line max-len
                }, // {ConfirmationCode: newDocument.id, Name: currentUser.NameFirst},
                name: 'BatteryReturnRequest',
              },
            },
          };
          await firestoreUtility(addEmail).catch(error => {
            throw new Error(`There was an error. Please contact us and include this code ${newDocument.id}`);
          });
          if (values.Address === 'newAddress') saveNewAddress();
          setStage('success');
        } catch (error) {
          actions.setStatus({alert: error.message});
          actions.setSubmitting(false);
        }
      };
      if (stage === 'pickup') moveToStage('contact');
      if (stage === 'contact') moveToStage('battery');
      if (stage === 'battery') moveToStage('hazmat');
      if (stage === 'hazmat') moveToStage('estimate');
      if (stage === 'estimate') submitForm();
    },
    validationSchema: () => {
      const pickupValidation = object().shape({
        Address1: string().label('Address 1').when('Address', {
          is: 'newAddress',
          then: string().required(),
          otherwise: string().notRequired(),
        }),
        Address2: string().label('Address 2').notRequired(),
        City: string().label('City').when('Address', {
          is: 'newAddress',
          then: string().required(),
          otherwise: string().notRequired(),
        }),
        State: string().label('State').when('Address', {
          is: 'newAddress',
          then: string().required().nullable(),
          otherwise: string().notRequired(),
        }),
        PostalCode: string().label('Postal Code').when('Address', {
          is: 'newAddress',
          then: string().required().matches(postalRegex, 'Postal Code is improperly formatted. (e.g. #####)'),
          otherwise: string().notRequired(),
        }),
      });
      const contactValidation = object().shape({
        ContactName: string().label('Contac tName').required(),
        Phone: string().label('Contact Phone').required().matches(phoneRegex, 'Phone is not properly formatted (e.g. 555-555-5555)'), // eslint-disable-line
        Email: string().label('Contact Email').required().email(),
      });
      const batteryValidation = object().shape({
        BatteryVIN: string().label('VIN').required().min(17),
        BatteryModules: number().label('Number of Modules').required(),
        BatteryHazards: array().notRequired(),
      });
      const hazmatValidation = object().shape({
        HazmatCertified: string().label('Hazmat Training Certification').required(),
        CertifiedEmployee: string().when('HazmatCertified', {
          is: 'Hazmat',
          then: string().label('Certified Employee').required().min(3),
        }),
      });
      const estimateValidation = object().shape({
        AcceptPayment: string().label().required('You must agree to the above terms and conditions to proceed'),
      });
      if (stage === 'pickup') return pickupValidation;
      if (stage === 'contact') return contactValidation;
      if (stage === 'battery') return batteryValidation;
      if (stage === 'hazmat') return hazmatValidation;
      if (stage === 'estimate') return estimateValidation;
    },
  }), [currentUser, stage, dispatch]);
  const core = useMemo(() => ({
    buttons: {
      back: formik => ({
        onClick: () => {
          if (stage === 'contact') setStage('pickup');
          if (stage === 'battery') setStage('contact');
          if (stage === 'hazmat') setStage('battery');
          if (stage === 'estimate') setStage('hazmat');
        },
        color: 'primary',
        disableRipple: true,
        disabled: formik.isSubmitting || formik.isValidating,
      }),
      cancel: formik => ({
        color: 'secondary',
        disabled: formik.isSubmitting || formik.isValidating,
        onClick: () => handleClose(),
      }),
      submit: formik => ({
        type: 'submit',
        color: 'primary',
        disableRipple: true,
        disabled: formik.isSubmitting || formik.isValidating,
      }),
    },
    fields: {
      BatteryVin: {
        label: 'Vehicle Identification No. (VIN)',
        name: 'BatteryVIN',
        required: true,
      },
      HazmatCertified: {
        name: 'HazmatCertified',
        required: true,
        label: 'Hazmat Training Certification',
      },
      CertifiedEmployee: {
        InputLabelProps: {shrink: true},
        label: 'Certified Employee',
        name: 'CertifiedEmployee',
        placeholder: 'Enter name of certified employee',
        required: true,
      },
    },
  }), [handleClose, stage]);

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {formik => {
          const moduleCount = formik.values.BatteryModules ? formik.values.BatteryModules : 0;
          const state = formik.values.State ? formik.values.State : 'CA';
          const transportation = priceByState[state].inboundRate + (moduleCount * priceByState[state][formik.values.ShippingType]);
          const management = recyclingFee + programAdminFee;
          return (
            <Fragment>
              <DialogTitle>
                <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
                  New Return Request {currentUser.Role === 'Dealership' ? `- ${currentUser.DealerName} (${currentUser.DealerNo})` : ''}
                </Collapse>
              </DialogTitle>
              <DialogContent>
                <Collapse in={stage === 'pickup'} timeout={{enter: 500, exit: 500}}>
                  <Typography variant='h6' style={{lineHeight: 1.1, fontSize: '1.2rem'}}>Pickup Address</Typography>
                  <Typography variant='subtitle1' style={{marginBottom: '8px'}}>
                    Confirm address from where battery will be picked up.
                  </Typography>
                  <RadioGroup name='Address' onChange={e => handleRadioChange(e, formik)} >
                    {currentUser.Locations.map(({Address1, Address2, City, State, PostalCode}, index) => {
                      const fullAddress = `${Address1}, ${Address2 ? Address2 + ', ' : ''} ${City}, ${State} ${PostalCode}`;
                      return <FormControlLabel key={index} value={`${index}`} control={<Radio color="primary" />} label={fullAddress} />;
                    })}
                    <FormControlLabel value='newAddress' control={<Radio color="primary" />} label='New Address' />
                  </RadioGroup>
                  <Collapse in={currentUser.Role === 'Admin'} style={{padding: '4px 0px'}}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField name='DealerNo' label='Dealer No.' required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name='DealerName' label='Dealer Name' />
                      </Grid>
                    </Grid>
                  </Collapse>
                  <Collapse in={formik.values.Address === 'newAddress'} timeout={{enter: 500, exit: 500}} style={{padding: '4px 0px'}}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField name='Address1' label='Address 1' required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField name='Address2' label='Address 2' />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <TextField name='City' label='City' required />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <AutoField name='State' items={states} itemKey='value' required />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField name='PostalCode' label='Postal Code' required />
                      </Grid>
                    </Grid>
                  </Collapse>
                </Collapse>
                <Collapse in={stage === 'contact'} timeout={{enter: 500, exit: 500}}>
                  <Typography variant='h6' style={{lineHeight: 1.1, fontSize: '1.2rem'}}>Contact Information</Typography>
                  <Typography variant='subtitle1' style={{marginBottom: '8px'}}>
                    Confirm details of person responsible for this battery shipment.
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField name='ContactName' label='Name' required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField name='Email' label='Email' required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField name='Phone' label='Phone' required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField name='Fax' />
                    </Grid>
                  </Grid>
                </Collapse>
                <Collapse in={stage === 'battery'} timeout={{enter: 500, exit: 500}}>
                  <Typography variant='h6' style={{lineHeight: 1.1, fontSize: '1.2rem'}}>Battery Information</Typography>
                  <Typography variant='subtitle1' style={{marginBottom: '8px'}}>Enter details for battery to be returned.</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <VinField {...core.fields.BatteryVin} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectField name='BatteryModules' label='Number of Modules to Return'>
                        {[1, 2, 3, 4].map(item => (
                          <MenuItem key={item} value={item}>
                            {`${item} defective module${item === 1 ? '' : 's'}`}
                          </MenuItem>
                        ))}
                      </SelectField>
                    </Grid>
                    <Grid item xs={12}>
                      <Alert
                        severity='error'
                        style={{padding: '0px'}}
                        text='The damaged/corroded modules packaged in the DDR kits AND the crate containing the original battery housing with non-damaged/corroded modules will be picked up in a single shipment utilzing a single bill of lading.' // eslint-disable-line max-len
                        in={formik.values.BatteryModules === 1 || formik.values.BatteryModules === 2 || formik.values.BatteryModules === 3}
                        variant='outlined'
                      />
                      <Alert
                        severity='error'
                        style={{padding: '0px'}}
                        text='If a cell does not show signs of corrosion it does not require a return kit. It is rare to need four return kits; proceed only after confirming all four cells are corroded.' // eslint-disable-line max-len
                        in={formik.values.BatteryModules === 4}
                        variant='outlined'
                      />
                      <Alert
                        severity='error'
                        style={{padding: '0px'}}
                        text='In extreme cases, if all four modules are corroded and require four DDR packages, the original battery housing should be destroyed and is not required to be recycled through this program.' // eslint-disable-line max-len
                        in={formik.values.BatteryModules === 4}
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Paper elevation={0} style={{backgroundColor: 'ghostwhite', border: '1px solid gray', padding: '8px'}}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <CheckboxGroup name='BatteryHazards' label='Check whether any of the below potential hazards are present:' boxes={batteryHazards.map(item => {
                              return {value: item, label: item};
                            })} />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Collapse>
                <Collapse in={stage === 'hazmat'} timeout={{enter: 500, exit: 500}}>
                  <Typography variant='h6' style={{lineHeight: 1.1, fontSize: '1.2rem'}}>Hazmat Certification</Typography>
                  <Typography variant='subtitle1' style={{lineHeight: 1.3, marginBottom: '8px', textAlign: 'justify'}}>
                    IMPORTANT: Pursuant to strict Department of Transportation regulations, you must accurately declare if the employee packaging and
                    offering this battery for shipment is certified and approved to ship hazardous materials {`("Hazmat")`}.
                  </Typography>
                  <RadioGroup {...core.fields.HazmatCertified}>
                    <FormControlLabel
                      value='Hazmat'
                      label='I certify employee has and maintains the required hazmat training.'
                      control={<Radio color='primary' />}
                    />
                    <FormControlLabel
                      value='Non-Hazmat'
                      label='No hazmat trained employee. Use an authorized third-party instead.'
                      control={<Radio color='secondary' disableRipple />}
                    />
                  </RadioGroup>
                  <Collapse in={formik.values.HazmatCertified === 'Hazmat'}>
                    <TextField {...core.fields.CertifiedEmployee} />
                  </Collapse>
                </Collapse>
                <Collapse in={stage === 'estimate'} timeout={{enter: 500, exit: 500}}>
                  <Typography variant='h6' style={{lineHeight: 1.1, fontSize: '1.2rem'}}>Cost Estimate</Typography>
                  <Typography variant='subtitle1' style={{marginBottom: '8px'}}>Review and confirm cost estimate for battery return request.</Typography>
                  <List style={{width: '100%'}} dense disablePadding={true}>
                    <ListItem divider disableGutters>
                      <ListItemText primary="Battery Recycling & Management (x1)" secondary={management.toLocaleString('en-US', currencyOption)} />
                      <ListItemSecondaryAction>
                        <Typography variant='body2'>{management.toLocaleString('en-US', currencyOption)}</Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem divider disableGutters>
                      <ListItemText primary={`Damaged Module Kit${moduleCount > 1 ? 's' : ''} (x${moduleCount})`} secondary={batteryKitPrice.toLocaleString('en-US', currencyOption)} />
                      <ListItemSecondaryAction>
                        <Typography variant='body2'>{(moduleCount * batteryKitPrice).toLocaleString('en-US', currencyOption)}</Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {priceByState[state]['outboundEconomy'] === 0 ? <ListItem divider disableGutters><ListItemText primary="Transportation" />
                      <ListItemSecondaryAction>
                        <Typography variant='body2'>TBD</Typography>
                      </ListItemSecondaryAction></ListItem> : <ListItem divider disableGutters>
                      <ListItemText primary={`Transportation (x1)`} secondary={transportation.toLocaleString('en-US', currencyOption)} />
                      <ListItemSecondaryAction>
                        <Typography variant='body2'>{transportation.toLocaleString('en-US', currencyOption)}</Typography>
                      </ListItemSecondaryAction>
                    </ListItem>}
                    {formik.values.HazmatCertified === 'Non-Hazmat' && <ListItem divider disableGutters>
                      <ListItemText primary="Third Party Packaging" />
                      <ListItemSecondaryAction>
                        <Typography variant='body2'>TBD</Typography>
                      </ListItemSecondaryAction>
                    </ListItem>}
                    <ListItem disableGutters>
                      <ListItemText primary="Estimated Total" />
                      <ListItemSecondaryAction>
                        <Typography variant='h6'>{calculateTotal(moduleCount, state, formik)}</Typography>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                  <Paper elevation={0} style={{backgroundColor: 'ghostwhite', border: '1px solid gray', marginTop: '24px', padding: '8px 8px 0px 8px'}}>
                    <Typography>Terms and Conditions</Typography>
                    <Typography style={{fontSize: '0.8rem', textAlign: 'justify', paddingTop: '8px'}}>
                    I hereby approve and agree to pay the charges shown above as related to the return and recycling of the battery associated
                    with VIN {`${formik.values.BatteryVIN}.`}
                    </Typography>
                    <Typography style={{fontSize: '0.8rem', textAlign: 'justify', paddingTop: '8px'}}>
                    Further, I understand and agree that the Transportation cost is subject to change due to fees outside the control of this return service,
                    such as Missed Pickup and Rescheduling fees; and that any such additional fees will be added, without markup, to the estimated Transportation
                    cost shown above.
                    </Typography>
                    {formik.values.HazmatCertified === 'Non-Hazmat' && (
                      <Typography style={{fontSize: '0.8rem', textAlign: 'justify', paddingTop: '8px'}}>
                        Finally, I understand that there will be additional charges to schedule Third Party Packaging of the battery to comply
                        with Hazmat shipping requirements. A quote for such Third Party Packaging will be sent to you for review and approval before
                        being scheduled.
                      </Typography>
                    )}
                    <CheckboxGroup name='AcceptPayment' styke={{margin: 0}} label=' ' smallFont={true} boxes={[{label: 'I agree to the terms and conditions above', value: 'agree'}]} />
                  </Paper>
                </Collapse>
                <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <CheckCircleOutline style={{fontSize: '10em'}} />
                    <div style={{width: '100%'}}>
                      <Typography variant="h6" style={{textAlign: 'center'}} gutterBottom>
                        Thank you for submitting the battery return request. Your dealership will be contracted shortly to finalize the request.
                      </Typography>
                    </div>
                  </div>
                </Collapse>
                <Alert severity='error' text={formik.status.alert} in={Boolean(formik.status.alert)} />
              </DialogContent>
              <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
                <DialogActions>
                  <Button onClick={() => handleClose()} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Collapse>
              <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
                <DialogActions style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div>
                    <Button {...core.buttons.cancel(formik)}>
                      Cancel
                    </Button>
                  </div>
                  <div>
                    {stage !== 'pickup' && <Button {...core.buttons.back(formik)}>Back</Button>}
                    <Button {...core.buttons.submit(formik)}>
                      {stage !== 'hazmat' && stage !== 'estimate' && 'Next'}
                      {stage === 'hazmat' && 'Get Estimate'}
                      {stage === 'estimate' && 'Submit Request'}
                    </Button>
                  </div>
                </DialogActions>
              </Collapse>
            </Fragment>
          );
        }}
      </FormikForm>
    </Dialog>
  );
};

ModalNewReturn.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};
const isMemoEqual = (prevProps, nextProps) => {
  if (prevProps.open !== nextProps.open) return false;
  return true;
};
export default React.memo(withRouter(ModalNewReturn), isMemoEqual);


/*
<ListItemSecondaryAction style={{minWidth: '40%'}}>
<Hidden xsDown>
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <SelectField name='ShippingType' label='Type' style={{paddingRight: '16px'}} variant='outlined'>
                              {['outboundEconomy', 'outboundPriority'].map(item => (
                                <MenuItem key={item} value={item}>
                                  {item === 'outboundEconomy' && 'Economy'}
                                  {item === 'outboundPriority' && 'Priority'}
                                </MenuItem>
                              ))}
                            </SelectField>
                            <Typography variant='body2'>{transportation.toLocaleString('en-US', currencyOption)}</Typography>
                          </div>
                        </Hidden>
                        <Hidden smUp>
                          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                            <SelectField name='ShippingType' label='Type' variant='outlined'>
                              {['outboundEconomy', 'outboundPriority'].map(item => (
                                <MenuItem key={item} value={item}>
                                  {item === 'outboundEconomy' && 'Economy'}
                                  {item === 'outboundPriority' && 'Priority'}
                                </MenuItem>
                              ))}
                            </SelectField>
                            <Typography variant='body2'>{transportation.toLocaleString('en-US', currencyOption)}</Typography>
                          </div>
                        </Hidden>
                        */
