import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {List, ListItem, Typography, ListItemIcon, ListItemText} from '@material-ui/core';
import {Print, Payment, Receipt} from '@material-ui/icons';
import moment from 'moment';
import {withRouter} from 'react-router-dom';

const DetailsActions = ({returnDetails, setView, setModal, history}) => {
  const {currentUser} = useSelector(state => state.auth);
  const getFileCount = () => {
    const files = returnDetails ? returnDetails.Files : null;
    if (!files) return 0;
    else return Object.keys(files).length;
  };
  const core = {
    menuSide: {
      style: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: 'ghostwhite',
        border: '1px solid darkgray',
        margin: '8px 0px',
      },
      subheader: <Typography variant="subtitle1" style={{marginLeft: '8px', marginTop: '8px'}}>
      User Actions
      </Typography>,
    },
    listItemEmailDocuments: {
      primary: `Email Document${getFileCount() > 1 ? 's' : ''}`,
    },
    listItemReminder: {
      primary: returnDetails && returnDetails.Reminder ? 'Edit Reminder' : 'Set Reminder',
      secondary: returnDetails && returnDetails.Reminder ? moment(returnDetails.Reminder).format('MM/DD/YYYY') : null,
    },
  };

  return (
    <List {...core.menuSide}>
      {(returnDetails.Status === 'Pending Invoice' && currentUser.Role !== 'Admin') && <ListItem button className="PulseAlert" onClick={() => history.push(`/portal/battery-returns/${returnDetails.BatteryReturnId}/invoice`)}>
        <ListItemIcon>
          <Payment />
        </ListItemIcon>
        <ListItemText primary="Pay Return" />
      </ListItem>}
      {(['Paid', 'Invoiced'].indexOf(returnDetails.Status) > -1) && <ListItem button onClick={() => history.push(`/portal/battery-returns/${returnDetails.BatteryReturnId}/invoice`)}>
        <ListItemIcon>
          <Receipt />
        </ListItemIcon>
        <ListItemText primary="View Receipt" />
      </ListItem>}
      <ListItem button onClick={() => setView('printDetails')}>
        <ListItemIcon>
          <Print />
        </ListItemIcon>
        <ListItemText primary="Print Details" />
      </ListItem>
      {Boolean(returnDetails.Date.Received) && <ListItem button onClick={() => setView('printCertificate')}>
        <ListItemIcon>
          <Print />
        </ListItemIcon>
        <ListItemText primary="Print Certificate" />
      </ListItem>}
    </List>
  );
};

DetailsActions.propTypes = {
  setView: PropTypes.func,
  setModal: PropTypes.func,
  returnDetails: PropTypes.object,
  history: PropTypes.object.isRequired,
};
export default withRouter(DetailsActions);
