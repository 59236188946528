import React, {Fragment, useMemo, useState} from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import {Collapse, Typography} from '@material-ui/core';
import {object, string} from 'yup';
import {Alert} from 'components';
import {Auth, Functions} from 'firebaseConfig';
import {FormikForm, PasswordField, SubmitButton, TextField} from 'components/formik';

const ExistingUserForm = props => {
  const [stage, setStage] = useState('SignIn');
  const [userInfo, setUserInfo] = useState(null);
  const formProps = useMemo(() => ({
    initialStatus: {
      alert: '',
    },
    initialValues: {
      EmailSignIn: '',
      PasswordSignIn: '',
    },
    onSubmit: (values, actions) => {
      const {EmailSignIn, PasswordSignIn} = values;
      const handleSignIn = async () => {
        try {
          const user = await Auth.signInWithEmailAndPassword(EmailSignIn, PasswordSignIn);
          if (!user.user.emailVerified) {
            actions.setStatus({alert: `Your account has not been verified. An email with a link to verify your account was previously sent.`}); //eslint-disable-line
            setStage('Resend');
            setUserInfo(user.user);
            actions.setSubmitting(false);
            return;
          }
          if (Boolean(props.redirectPath)) {
            props.history.replace('/portal/' + props.redirectPath);
          } else {
            props.history.replace('/portal/battery-returns');
          }
        } catch (error) {
          actions.setStatus({alert: error.message});
          actions.setSubmitting(false);
        }
      };
      const handleForgotPassword = async () => {
        try {
          await Functions.httpsCallable('createResetPasswordLink')({email: EmailSignIn});
          actions.setStatus({alert: 'We have sent an email with a link to reset your password.'});
          actions.setSubmitting(false);
          setStage('SignIn');
        } catch (error) {
          actions.setStatus({alert: error.message});
          actions.setSubmitting(false);
        }
      };
      const handleVerificationEmail = async () => {
        try {
          await Functions.httpsCallable('sendVerificationEmail')({
            email: userInfo.email,
            firstName: userInfo.displayName,
          });
          actions.setStatus({alert: `A verification email has been resent. Please whitelist our domain to prevent emails from being sent to your spam.`}); //eslint-disable-line
          actions.setSubmitting(false);
          setStage('SignIn');
        } catch (error) {
          actions.setStatus({alert: error.message});
          actions.setSubmitting(false);
        }
      };
      if (stage === 'SignIn') handleSignIn();
      if (stage === 'ForgotPassword') handleForgotPassword();
      if (stage === 'Resend') handleVerificationEmail();
    },
    validationSchema: () => {
      const forgotPasswordValidation = object().shape({
        EmailSignIn: string().label('Email').required().email(),
      });
      const signInValidation = object().shape({
        EmailSignIn: string().label('Email').required().email(),
        PasswordSignIn: string().label('Password').required().min(5),
      });
      if (stage === 'ForgotPassword') return forgotPasswordValidation;
      else if (stage === 'SignIn') return signInValidation;
      else return object().notRequired();
    },
  }), [props.history, props.redirectPath, stage, userInfo]);
  const forgotPasswordProps = useMemo(() => ({
    gutterBottom: true,
    style: {
      cursor: 'pointer',
      float: 'right',
      marginTop: '4px',
    },
    variant: 'caption',
    onClick: () => {
      if (stage === 'SignIn') setStage('ForgotPassword');
      else if (stage === 'ForgotPassword') setStage('SignIn');
    },
  }), [stage]);
  const submitButton = useMemo(() => ({
    text: (() => {
      if (stage === 'SignIn') return 'Sign In';
      if (stage === 'ForgotPassword') return 'Reset Password';
      if (stage === 'Resend') return 'Resend Verification Email';
    })(),
    props: {
      color: 'primary',
      fullWidth: true,
      variant: 'contained',
      style: {marginTop: '24px', margin: '10px'},
    },
  }), [stage]);

  return (
    <FormikForm {...formProps}>
      {({status}) => {
        return (
          <Fragment>
            <Collapse in={stage === 'SignIn' || stage === 'ForgotPassword'}>
              <TextField name='EmailSignIn' label='Email' fast required />
            </Collapse>
            <Collapse in={stage === 'SignIn'}>
              <PasswordField name='PasswordSignIn' label='Password' fast required />
            </Collapse>
            <Alert message={status.alert} />
            <SubmitButton {...submitButton.props}>
              {submitButton.text}
            </SubmitButton>
            <Typography {...forgotPasswordProps}>
              {stage === 'SignIn' && 'Forgot Password?'}
              {stage === 'ForgotPassword' && 'Remembered Password?'}
            </Typography>
          </Fragment>
        );
      }}
    </FormikForm>
  );
};

ExistingUserForm.propTypes = {
  history: PropTypes.object.isRequired,
  redirectPath: PropTypes.string,
};
export default withRouter(ExistingUserForm);
