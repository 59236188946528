import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import {NotInterested} from '@material-ui/icons';

const ErrorPage = () => {
  return (
    <Grid container spacing={4} style={{textAlign: 'center', paddingTop: '24px'}}>
      <Grid item xs={12}>
        <NotInterested style={{fontSize: '10em', fill: 'slategray'}} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6'>Oops!</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='body1'>We encountered an error. Please go back and reload the page.</Typography>
      </Grid>
    </Grid>
  );
};

export default ErrorPage;
