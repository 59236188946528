import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

const FormikForm = ({ children, render, ...otherProps }) => (
  <Formik validateOnChange={false} {...otherProps}>
    {formikProps => (
      <Form style={{ width: '100%' }} noValidate method="post">
        {render && render({ ...formikProps })}
        {children && children({ ...formikProps })}
      </Form>
    )}
  </Formik>
);

FormikForm.propTypes = {
  children: PropTypes.func,
  render: PropTypes.func
};
export default FormikForm;
