import React, {Fragment, useState, useEffect, useCallback} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Collapse, Paper, Grid, Typography, TextField} from '@material-ui/core';
import {Add} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment';
import {AsyncLoader} from 'components';
import {AdminActions, DetailsHeader, DownloadableDocuments, PrintDetails, PrintCertificate, UserActions} from './BatteryReturnDetails/';
import {ModalUploadFile, ModalEditReturn, ModalSetReminder, ModalReceiveBattery, ModalAddShipment, ModalModuleKits, ModalFinalizePayment, ModalPaymentReceived} from './BatteryReturnDetails/modals/';
import {ModalAddNote, ModalEmailDocuments} from './BatteryReturnDetails/modals/';
import {formatPhoneNumber} from 'utilities';
import {Table} from '@kbi/component-library';

const BatteryReturnDetails = props => {
  const {firestore: {batteryReturns}, auth: {currentUser}} = useSelector(state => state);
  const [returnDetails, setReturnDetails] = useState(null);
  const [view, setView] = useState('main');
  const [modal, setModal] = useState('');
  const styles = useStyles();
  const closeModal = useCallback(() => {
    setModal('');
  }, []);
  const getReturnDetails = () => () => {
    if (batteryReturns) {
      const {id} = props.match.params;
      if (batteryReturns.ref[id]) setReturnDetails(batteryReturns.ref[id]);
      else props.history.replace('/portal/battery-returns');
    }
  };
  useEffect(getReturnDetails(), [batteryReturns]);
  if (!returnDetails) return <AsyncLoader />;
  const core = {
    paper: {
      className: styles.paper,
      elevation: 2,
      style: {display: 'flex', flexWrap: 'wrap'},
    },
    fieldDate: (name, value) => ({
      variant: 'outlined',
      readOnly: true,
      fullWidth: true,
      label: name,
      margin: 'dense',
      value: value ? moment(value.toDate()).format('MM/DD/YYYY') : 'Pending',
    }),
    fieldText: (name, value) => ({
      variant: 'outlined',
      readOnly: true,
      fullWidth: true,
      label: name,
      margin: 'dense',
      value: value ? value : '',
    }),
    fieldMultiText: (name, value) => ({
      variant: 'outlined',
      readOnly: true,
      fullWidth: true,
      label: name,
      margin: 'dense',
      value: value ? value.join(' ') : '',
      multiline: true,
    }),
    tableProps: {
      actionsBar: [
        {icon: Add, text: 'Add Note', onClick: () => setModal('modalAddNote')}, // eslint-disable-line
      ],
      columns: [
        {Header: 'Note', accessor: 'Note'},
        {Header: 'Author', accessor: 'CreatedBy'},
        {Header: 'Date', accessor: 'CreatedOn'},
      ],
      data: returnDetails.Notes.map(note => ({
        Note: note.Note,
        CreatedBy: note.CreatedBy,
        CreatedOn: moment(note.CreatedOn.toDate()).format('MM/DD/YYYY HH:mm:ss'),
      })),
      sortInitial: [{id: 'CreatedOn', desc: true}],
    },
  };

  return (
    <Fragment>
      <Collapse in={view === 'main'} timeout={{enter: 500, exit: 500}}>
        <Grid spacing={5} container>
          <Grid item xs={12} md={4} style={{padding: '0px 20px'}}>
            <Paper className={styles.paper} style={{flexDirection: 'column', alignItems: 'center'}}>
              <DetailsHeader returnDetails={returnDetails} />
              <DownloadableDocuments returnDetails={returnDetails} />
              <AdminActions returnDetails={returnDetails} setModal={setModal} />
              <UserActions returnDetails={returnDetails} setView={setView} setModal={setModal} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={8} style={{padding: '0px 20px'}}>
            <Paper {...core.paper}>
              <Typography variant='h6' gutterBottom style={{width: '100%'}}>
                Pickup Information
              </Typography>
              <Grid spacing={2} container>
                <Grid item xs={12} sm={4}>
                  <TextField {...core.fieldText('Dealer No.', returnDetails.DealerNo)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField {...core.fieldText('Dealer Name', returnDetails.DealerName)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField {...core.fieldText('Return Status', returnDetails.Status)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField {...core.fieldDate('Request Date', returnDetails.Date.Requested)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField {...core.fieldDate('Received Date', returnDetails.Date.Received)} />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField {...core.fieldText('Battery VIN', returnDetails.Battery.VIN)} />
                </Grid>
                <Grid item xs={12}>
                  <TextField {...core.fieldMultiText('Identified Hazards', returnDetails.Battery.Hazards)} />
                </Grid>
              </Grid>
            </Paper>
            <Paper {...core.paper}>
              <Typography variant='h6' gutterBottom style={{width: '100%'}}>
                Dealer Information
              </Typography>
              <Grid spacing={2} container>
                <Grid item xs={12} container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant='subtitle1'>
                      Pickup Details
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField {...core.fieldText('Contact', `${returnDetails.LocationPickup.Contact}`)} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField {...core.fieldText('Phone', formatPhoneNumber(returnDetails.LocationPickup.Phone))} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField {...core.fieldText('Email', returnDetails.LocationPickup.Email)} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField {...core.fieldText('Address 1', returnDetails.LocationPickup.Address1)} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField {...core.fieldText('Address 2', returnDetails.LocationPickup.Address2)} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField {...core.fieldText('City', returnDetails.LocationPickup.City)} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField {...core.fieldText('State', returnDetails.LocationPickup.State)} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField {...core.fieldText('Postal Code', returnDetails.LocationPickup.PostalCode)} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField {...core.fieldText('Hazmat Certified', returnDetails.Hazmat.Certified ? 'True' : 'False')} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField {...core.fieldText('Certified Employee', returnDetails.Hazmat.Employee || 'Not Applicable')} />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <Collapse in={currentUser.Role === 'Admin'}>
              <Paper elevation={2} style={{marginTop: '24px'}}>
                <div style={{height: 64, display: 'flex', alignItems: 'center', paddingLeft: 16}}>
                  <Typography variant='h6'>Admin Notes</Typography>
                </div>
                <Table {...core.tableProps} />
              </Paper>
            </Collapse>
          </Grid>
        </Grid>
      </Collapse>
      <Collapse in={view === 'printDetails'} timeout={{enter: 500, exit: 500}}>
        <PrintDetails printCancel={() => setView('main')} returnDetails={returnDetails} />
      </Collapse>
      <Collapse in={view === 'printCertificate'} timeout={{enter: 500, exit: 500}}>
        <PrintCertificate printCancel={() => setView('main')} returnDetails={returnDetails} />
      </Collapse>
      <ModalUploadFile close={closeModal} returnDetails={returnDetails} open={modal === 'modalUploadFile'} />
      <ModalEditReturn close={closeModal} returnDetails={returnDetails} open={modal === 'modalEditReturn'} />
      <ModalSetReminder close={closeModal} returnDetails={returnDetails} open={modal === 'modalSetReminder'} />
      <ModalReceiveBattery close={closeModal} returnDetails={returnDetails} open={modal === 'modalReceiveBattery'} />
      <ModalAddNote close={closeModal} returnDetails={returnDetails} open={modal === 'modalAddNote'} />
      <ModalEmailDocuments close={closeModal} returnDetails={returnDetails} open={modal === 'modalEmailDocuments'} />
      <ModalAddShipment close={closeModal} returnDetails={returnDetails} open={modal === 'modalAddShipment'} />
      <ModalModuleKits close={closeModal} returnDetails={returnDetails} currentUser={currentUser} open={modal === 'modalModuleKits'} />
      <ModalFinalizePayment close={closeModal} returnDetails={returnDetails} currentUser={currentUser} open={modal === 'modalFinalizePayment'} />
      <ModalPaymentReceived close={closeModal} returnDetails={returnDetails} open={modal === 'modalPaymentReceived'} />
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    display: 'flex',
    marginTop: '24px',
    overflowX: 'auto',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));
BatteryReturnDetails.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};
export default BatteryReturnDetails;
