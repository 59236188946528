import React, {Fragment, useMemo, useState} from 'react';
import {Collapse, Grid, Typography} from '@material-ui/core';
import {object, string} from 'yup';
import {FormikForm, PasswordField, SubmitButton, TextField} from 'components/formik';
import {Alert} from 'components';
import {Auth, Functions} from 'firebaseConfig';
import {phoneRegex} from 'utilities';

const NewUserForm = () => {
  const [stage, setStage] = useState('contact');
  const formProps = useMemo(() => ({
    initialStatus: {
      alert: '',
    },
    initialValues: {
      FirstName: '',
      LastName: '',
      Email: '',
      Phone: '',
      Password: '',
      ConfirmPassword: '',
    },
    onSubmit: (values, actions) => {
      if (stage === 'contact') {
        setStage('password');
        actions.setSubmitting(false);
        actions.setTouched({});
      }
      if (stage === 'password') {
        (async () => {
          try {
            await Functions.httpsCallable('registerNewUser')(values);
            await Auth.signInWithEmailAndPassword(values.Email, values.Password);
            await Functions.httpsCallable('sendVerificationEmail')({email: values.Email, firstName: values.FirstName});
            actions.setStatus({alert: ''});
            actions.setSubmitting(false);
            setStage('verifyEmail');
          } catch (error) {
            actions.setStatus({alert: error.message});
            actions.setSubmitting(false);
          }
        })();
      }
      if (stage === 'verifyEmail') {
        (async () => {
          try {
            const actionCodeSettings = {url: 'https://a3.evbatteryreturns.com/portal', handleCodeInApp: true};
            await Functions.httpsCallable('sendVerificationEmail')({email: values.Email, firstName: values.FirstName, actionCodeSettings});
            actions.setStatus({alert: `A verification email has been sent to ${values.Email}.`});
            actions.setSubmitting(false);
          } catch (error) {
            actions.setStatus({alert: error.message});
            actions.setSubmitting(false);
          }
        })();
      }
    },
    validationSchema: () => {
      const contactValidation = object().shape({
        FirstName: string().label('First Name').required().min(2),
        LastName: string().label('Last Name').required().min(2),
        Email: string().required().email(),
        Phone: string().required().matches(phoneRegex, 'Phone is improperly formatted. (e.g. ###-###-####)'),
      });
      const passwordValidation = object().shape({
        Password: string().required().min(5),
        ConfirmPassword: string().label('Confirm Password').required().min(5).test(
          'confirm-password',
          'Confirm Password field does not match',
          function(value) {
            const { Password, ConfirmPassword } = this.parent; // eslint-disable-line
            if (Password === ConfirmPassword) return true;
            else return false;
          },
        ),
      });
      if (stage === 'contact') return contactValidation;
      if (stage === 'password') return passwordValidation;
      if (stage === 'verifyEmail') return object().notRequired();
    },
  }), [stage]);
  const submitButton = useMemo(() => ({
    text: (() => {
      if (stage === 'contact') return 'Continue';
      if (stage === 'password') return 'Register User';
      if (stage === 'verifyEmail') return 'Resend Email Verification';
    })(),
    props: {
      color: 'primary',
      fullWidth: true,
      variant: 'contained',
      style: {marginTop: '24px'},
    },
  }), [stage]);

  return (
    <FormikForm {...formProps}>
      {formik => (
        <Fragment>
          <Collapse in={stage === 'contact'}>
            <Typography variant='button' style={{display: 'block', marginTop: '16px'}}>
                Enter Contact Information
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField name='FirstName' label='First Name' fast required />
              </Grid>
              <Grid item xs={12}>
                <TextField name='LastName' label='Last Name' fast required />
              </Grid>
              <Grid item xs={12}>
                <TextField name='Email' fast required />
              </Grid>
              <Grid item xs={12}>
                <TextField name='Phone' fast required />
              </Grid>
            </Grid>
          </Collapse>
          <Collapse in={stage === 'password'}>
            <Typography variant='button' style={{display: 'block', marginTop: '16px'}}>
                Enter & Confirm Password
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <PasswordField name='Password' fast required />
              </Grid>
              <Grid item xs={12}>
                <PasswordField name='ConfirmPassword' label='Confirm Password' fast required />
              </Grid>
            </Grid>
          </Collapse>
          <Collapse in={stage === 'verifyEmail'}>
            <Typography variant='h6' style={{display: 'block', fontSize: '1.1rem', marginTop: '24px'}}>
                Thank you for registering!
            </Typography>
            <Typography variant='body2' style={{lineHeight: 1.5, marginTop: '8px', textAlign: 'justify'}}>
              An email has been sent with a link to verify your account. Please whitelist our domain to prevent emails
              from being sent to your spam folder.
            </Typography>
          </Collapse>
          <Alert message={formik.status.alert} />
          <SubmitButton {...submitButton.props}>
            {submitButton.text}
          </SubmitButton>
        </Fragment>
      )}
    </FormikForm>
  );
};

export default NewUserForm;
