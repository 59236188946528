import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { Field, FastField } from 'formik';

const SelectField = ({ children, fast, name, onBlur, onChange, ...otherProps }) => {
  const textFieldProps = ({ field, form }) => {
    //console.log('SelectField { props, field, form }', { props, field, form });
    return {
      disabled: form.isSubmitting,
      error: form.touched[name] && form.errors[name] ? true : false,
      fullWidth: true,
      helperText: form.touched[name] && form.errors[name],
      id: name,
      label: name,
      margin: 'dense',
      select: true,
      ...otherProps,
      onBlur: e => {
        field.onBlur(e);
        if (onBlur) onBlur({ e, field, form });
      },
      onChange: e => {
        field.onChange(e);
        if (onChange)
          onChange({
            e,
            field: { ...field, value: e.target.value },
            form: { ...form, values: { ...form.values, [name]: e.target.value } }
          });
      }
    };
  };

  if (fast) {
    return (
      <FastField name={name}>
        {({ field, form }) => (
          <TextField {...field} {...textFieldProps({ field, form })}>
            {children}
          </TextField>
        )}
      </FastField>
    );
  }
  return (
    <Field name={name}>
      {({ field, form }) => (
        <TextField {...field} {...textFieldProps({ field, form })}>
          {children}
        </TextField>
      )}
    </Field>
  );
};

SelectField.defaultProps = {
  fast: false
};
SelectField.propTypes = {
  fast: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};
export default SelectField;
