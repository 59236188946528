import {SET_CURRENT_USER, RESET_CURRENT_USER, RESET_FIRESTORE, SET_LAST_PATH, RESET_LAST_PATH} from '../types';

export const setCurrentUser = payload => {
  return dispatch => {
    dispatch({type: SET_CURRENT_USER, payload});
  };
};

export const resetCurrentUser = () => {
  return dispatch => {
    dispatch({type: RESET_CURRENT_USER});
    dispatch({type: RESET_FIRESTORE});
  };
};

export const setLastPath = path => {
  return dispatch => {
    const splitPath = path.split('portal/');
    if (splitPath.length > 1) {
      dispatch({type: SET_LAST_PATH, payload: splitPath[1]});
    };
  };
};

export const resetLastPath = () => {
  return dispatch => {
    dispatch({type: RESET_LAST_PATH});
  };
};
