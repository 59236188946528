import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Collapse, Grid, MenuItem, Typography} from '@material-ui/core';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import {CheckCircleOutline} from '@material-ui/icons';
import get from 'lodash.get';
import {number, object, string, array} from 'yup';
import {AutoField, FormikForm, SelectField, TextField, VinField, CheckboxGroup} from 'components/formik/';
import {states, batteryHazards} from 'constLists/';
import {firestoreUtility, postalRegex, phoneRegex} from 'utilities/';
import {Alert} from 'components/';

const ModalEditReturn = ({open, close, returnDetails}) => {
  const [stage, setStage] = useState('pickup');

  const handleBack = () => {
    if (stage === 'battery') setStage('pickup');
  };
  const handleClose = () => {
    setStage('pickup');
    close();
  };

  const dialogProps = {
    fullWidth: true,
    maxWidth: 'sm',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  };
  const formProps = {
    initialStatus: {alert: ''},
    initialValues: {
      DealerNo: get(returnDetails, 'DealerNo', ''),
      DealerName: get(returnDetails, 'DealerName', ''),
      PickupContact: get(returnDetails, 'LocationPickup.Contact', ''),
      Phone: get(returnDetails, 'LocationPickup.Phone', ''),
      Email: get(returnDetails, 'LocationPickup.Email', ''),
      Address1: get(returnDetails, 'LocationPickup.Address1', ''),
      Address2: get(returnDetails, 'LocationPickup.Address2', ''),
      City: get(returnDetails, 'LocationPickup.City', ''),
      State: get(returnDetails, 'LocationPickup.State', ''),
      PostalCode: get(returnDetails, 'LocationPickup.PostalCode', ''),
      BatteryVIN: get(returnDetails, 'Battery.VIN', ''),
      BatteryModules: get(returnDetails, 'Battery.DefectiveModules', ''),
      BatteryHazards: get(returnDetails, 'Battery.Hazards', []),
    },
    onSubmit: async (values, actions) => {
      if (stage === 'pickup') {
        setStage('battery');
        actions.setTouched({});
        actions.setSubmitting(false);
      }
      if (stage === 'battery') {
        try {
          const updateDocument = {
            collection: 'BatteryReturns',
            writeType: 'update',
            docId: returnDetails.BatteryReturnId,
            docFields: {
              'Battery.Hazards': values.BatteryHazards,
              'Battery.DefectiveModules': values.BatteryModules,
              'Battery.VIN': values.BatteryVIN,
              'DealerName': values.DealerName,
              'DealerNo': values.DealerNo,
              'LocationPickup.Address1': values.Address1,
              'LocationPickup.Address2': values.Address2,
              'LocationPickup.City': values.City,
              'LocationPickup.State': values.State,
              'LocationPickup.PostalCode': values.PostalCode,
              'LocationPickup.Contact': values.PickupContact,
              'LocationPickup.Phone': values.Phone,
              'LocationPickup.Email': values.Email,
            },
          };
          await firestoreUtility(updateDocument);
          setStage('success');
        } catch (error) {
          actions.setStatus({alert: error.message});
          actions.setSubmitting(false);
        }
      }
    },
    validationSchema: () => {
      const pickupValidation = object().shape({
        DealerName: string().label('Dealer Name').required(),
        PickupContact: string().label('Pickup Contact').required(),
        Phone: string()
          .label('Phone')
          .required()
          .matches(phoneRegex, 'Phone is not properly formatted (e.g. 555-555-5555)'),
        Email: string().label('Email').required().email(),
        Address1: string().label('Address 1').required(),
        Address2: string().label('Address 2').notRequired(),
        City: string().label('City').required(),
        State: string().label('State').required(),
        PostalCode: string().label('Postal Code').required().matches(postalRegex, 'Postal Code is improperly formatted. (e.g. #####)'), // eslint-disable-line
      });
      const batteryValidation = object().shape({
        BatteryVIN: string().label('VIN').required().min(17),
        BatteryModules: number().label('Number of Modules').required(),
        BatteryHazards: array().of(string()).notRequired(),
      });
      if (stage === 'pickup') return pickupValidation;
      if (stage === 'battery') return batteryValidation;
    },
  };
  const core = {
    buttons: {
      back: formik => ({
        onClick: handleBack,
        color: 'primary',
        disableRipple: true,
        disabled: formik.isSubmitting || formik.isValidating,
      }),
      cancel: formik => ({
        color: 'secondary',
        disabled: formik.isSubmitting || formik.isValidating,
        onClick: handleClose,
      }),
      submit: formik => ({
        type: 'submit',
        color: 'primary',
        disableRipple: true,
        disabled: formik.isSubmitting || formik.isValidating,
      }),
    },
    fields: {
      BatteryDamaged: {
        label: 'Battery Condition',
        name: 'BatteryDamaged',
        required: true,
      },
      BatteryDamagedDesc: {
        label: 'Desribe the Damage',
        multiline: true,
        name: 'BatteryDamagedDesc',
        required: true,
      },
      BatteryVin: {
        label: 'Vehicle Identification No. (VIN)',
        name: 'BatteryVIN',
        required: true,
      },
      CertifiedEmployee: {
        InputLabelProps: {shrink: true},
        label: 'Certified Employee',
        name: 'CertifiedEmployee',
        placeholder: 'Enter name of certified employee',
        required: true,
      },
    },
    styles: {
      confirmation: {
        border: '1px solid lightgray',
        margin: '16px',
        padding: '8px',
        backgroundColor: '#fafafa',
      },
    },
  };

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {formik => {
          return (
            <Fragment>
              <DialogTitle>
                <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
                Edit Return Request
                </Collapse>
              </DialogTitle>
              <DialogContent>
                <Collapse in={stage === 'pickup'} timeout={{enter: 500, exit: 500}}>
                  <Typography variant='h6' style={{lineHeight: 1.1, fontSize: '1.2rem'}}>Pickup Information</Typography>
                  <Typography variant='subtitle1' style={{marginBottom: '8px'}}>Confirm address where battery will be picked up.</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField name='DealerNo' label='Dealer No.' required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField name='DealerName' label='Dealer Name' required />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField name='PickupContact' label='Pickup Contact' required />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField name='Phone' label='Pickup Phone' required />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <TextField name='Email' label='Pickup Email' required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField name='Address1' label='Pickup Address 1' required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField name='Address2' label='Pickup Address 2' />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField name='City' label='City' required />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <AutoField name='State' label='State' items={states} itemKey='value' required />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField name='PostalCode' label='Postal Code' required />
                    </Grid>
                  </Grid>
                </Collapse>
                <Collapse in={stage === 'battery'} timeout={{enter: 500, exit: 500}}>
                  <Typography variant='h6' style={{lineHeight: 1.1, fontSize: '1.2rem'}}>Battery Information</Typography>
                  <Typography variant='subtitle1' style={{marginBottom: '8px'}}>Enter details for battery to be returned.</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <VinField {...core.fields.BatteryVin} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectField name='BatteryModules' label='Number of Modules to Return'>
                        {[1, 2, 3, 4].map(item => (
                          <MenuItem key={item} value={item}>
                            {`${item} defective module${item === 1 ? '' : 's'}`}
                          </MenuItem>
                        ))}
                      </SelectField>
                    </Grid>
                    <Grid item xs={12}>
                      <CheckboxGroup name='BatteryHazards' label='Battery Shipping Hazards (if any)' boxes={batteryHazards.map(item => {
                        return {value: item, label: item};
                      })} />
                    </Grid>
                  </Grid>

                </Collapse>
                <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <CheckCircleOutline style={{fontSize: '10em'}} />

                    <Typography variant="h6" style={{textAlign: 'center'}} gutterBottom>
                      Battery return details have been updated.
                    </Typography>


                  </div>
                </Collapse>
                <Alert message={formik.status.alert} />
              </DialogContent>
              <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                  Close
                  </Button>
                </DialogActions>
              </Collapse>
              <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
                <DialogActions style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div>
                    <Button {...core.buttons.cancel(formik)}>
                    Cancel
                    </Button>
                  </div>
                  <div>
                    {stage !== 'pickup' && <Button {...core.buttons.back(formik)}>Back</Button>}
                    <Button {...core.buttons.submit(formik)}>{stage === 'battery' ? 'Submit' : 'Next'}</Button>
                  </div>
                </DialogActions>
              </Collapse>
            </Fragment>
          );
        }
        }
      </FormikForm>
    </Dialog>
  );
};

ModalEditReturn.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  returnDetails: PropTypes.object.isRequired,
};

const isMemoEqual = (prevProps, nextProps) => {
  if (prevProps.open !== nextProps.open) return false;
  return true;
};
export default React.memo(ModalEditReturn, isMemoEqual);
