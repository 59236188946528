import React, {useState, useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Button, Collapse, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Typography, List, ListItem, ListItemSecondaryAction, ListItemText} from '@material-ui/core';
import {CheckCircleOutline} from '@material-ui/icons';
import {FormikForm, CurrencyField} from 'components/formik';
import {object, number} from 'yup';
import {firestoreUtility} from 'utilities';
import moment from 'moment';
import {batteryKitPrice, recyclingFee, programAdminFee, priceByState, currencyOption} from 'constLists';
// const management = recyclingFee + programAdminFee;
const initialTPP = 0;
const convertToZero = value => (!value ? '0' : value);
const calculateTotal = (values, hazmat, formatted) => {
  const {Recycling, Management, Kits, Inbound, Outbound, TPP} = values;
  let total = parseFloat(convertToZero(Recycling)) + parseFloat(convertToZero(Management)) + parseFloat(convertToZero(Kits)) + parseFloat(convertToZero(Inbound)) + parseFloat(convertToZero(Outbound));// parseFloat(Transportation);
  if (!hazmat) {
    total = total + parseFloat(convertToZero(TPP));
  }
  if (formatted) {
    return total.toLocaleString('en-US', currencyOption);
  } else {
    return total.toFixed(2);
  }
};

const ModalFinalizePayment = ({open, close, returnDetails, currentUser}) => {
  const [stage, setStage] = useState('payment');
  const handleClose = useCallback(() => {
    setStage('payment');
    close();
  }, [close]);
  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'sm',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [open]);
  const {Battery: {DefectiveModules: moduleCount}, LocationPickup: {State: state}} = useMemo(() => returnDetails, [returnDetails]);
  const formProps = useMemo(() => ({
    initilStatus: {alert: ''},
    initialValues: {
      Recycling: recyclingFee.toFixed(2),
      Management: programAdminFee.toFixed(2),
      Kits: (moduleCount * batteryKitPrice).toFixed(2),
      Inbound: priceByState[state].inboundRate.toFixed(2),
      Outbound: (moduleCount * priceByState[state]['outboundEconomy']).toFixed(2),
      TPP: initialTPP.toFixed(2),
    },
    onSubmit: async (values, actions) => {
      try {
        const updateDoc = {
          collection: 'BatteryReturns',
          writeType: 'update',
          docId: returnDetails.BatteryReturnId,
          docFields: {
            'Date.Reminder': moment().add(3, 'days').toDate(),
            'Accounting': {
              RecyclingFee: values.Recycling,
              ManagementFee: values.Management,
              DamagedModuleKits: values.Kits,
              Inbound: values.Inbound,
              Outbound: values.Outbound,
              ThirdPartyPackaging: values.TPP,
              Total: calculateTotal(values, returnDetails.Hazmat.Certified, false),
            },
            'Status': 'Pending Invoice',
          },
        };
        await firestoreUtility(updateDoc);
        const addEmail = {
          collection: 'Emails',
          writeType: 'add',
          docFields: {
            to: currentUser.Role === 'Admin' ? returnDetails.LocationPickup.Email : [currentUser.Email, returnDetails.LocationPickup.Email],
            template: {
              data: {VIN: returnDetails.Battery.VIN, DocURL: returnDetails.BatteryReturnId},
              name: 'BatteryReturnPayment',
            },
          },
        };
        await firestoreUtility(addEmail);
        setStage('success');
      } catch (error) {
        actions.setStatus({alert: error.message});
        actions.setSubmitting(false);
      }
    },
    validationSchema: object().shape({
      Recycling: number().min(.01, 'Required'),
      Management: number().min(.01, 'Required'),
      Kits: number().min(0, 'Required'),
      Inbound: number().min(.01, 'Required'),
      Outbound: number().min(0, 'Required'),
      TPP: !returnDetails.Hazmat.Certified ? number().min(.01, 'Required') : number().notRequired(),
    }),
  }), [currentUser.Email, currentUser.Role, moduleCount, returnDetails.Battery.VIN, returnDetails.BatteryReturnId, returnDetails.Hazmat.Certified, returnDetails.LocationPickup.Email, state]);
  const core = useMemo(() => ({
    buttons: {
      cancel: formik => ({
        color: 'secondary',
        disabled: formik.isSubmitting || formik.isValidating,
        onClick: handleClose,
      }),
      submit: formik => ({
        type: 'submit',
        color: 'primary',
        disableRipple: true,
        disabled: formik.isSubmitting || formik.isValidating,
      }),
    },
  }), [handleClose]);
  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {formik => {
          return (
            <>
              <DialogTitle>
                <Collapse in={stage === 'payment'} timeout={{enter: 500, exit: 500}}>
                  Finalize Amount
                </Collapse>
              </DialogTitle>
              <DialogContent>
                <Collapse in={stage === 'payment'} timeout={{enter: 500, exit: 500}}>
                  <Typography>Add shipping and packing fees</Typography>
                  <Grid container spacing={2}>
                    <List style={{width: '100%'}} disablePadding={true}>
                      <ListItem divider disableGutters>
                        <ListItemText primary="Battery Recycling Fee (x1)" secondary={recyclingFee.toLocaleString('en-US', currencyOption)} />
                        <ListItemSecondaryAction>
                          <CurrencyField name='Recycling' />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem divider disableGutters>
                        <ListItemText primary="Management Fee (x1)" secondary={programAdminFee.toLocaleString('en-US', currencyOption)} />
                        <ListItemSecondaryAction>
                          <CurrencyField name='Management' />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem divider disableGutters>
                        <ListItemText primary={`Damaged Module Kit${moduleCount > 1 ? 's' : ''} (x${moduleCount})`} secondary={batteryKitPrice.toLocaleString('en-US', currencyOption)} />
                        <ListItemSecondaryAction>
                          <CurrencyField name='Kits' />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem divider disableGutters>
                        <ListItemText primary='Inbound (x1)' secondary={!priceByState[state].inboundRate ? 'TBD' : priceByState[state].inboundRate.toLocaleString('en-US', currencyOption)} />
                        <ListItemSecondaryAction >
                          <CurrencyField name='Inbound' />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem divider disableGutters>
                        <ListItemText primary={`Outbound (x${moduleCount})`} secondary={!priceByState[state]['outboundEconomy'] ? 'TBD' : (priceByState[state]['outboundEconomy']).toLocaleString('en-US', currencyOption)} />
                        <ListItemSecondaryAction >
                          <CurrencyField name='Outbound' />
                        </ListItemSecondaryAction>
                      </ListItem>
                      {!returnDetails.Hazmat.Certified && <ListItem divider disableGutters>
                        <ListItemText primary="Third Party Packaging (x1)" secondary='TBD' />
                        <ListItemSecondaryAction>
                          <CurrencyField name='TPP' label='Third Party Packaging' />
                        </ListItemSecondaryAction>
                      </ListItem>}
                      <ListItem disableGutters>
                        <ListItemText primary="Final Total" />
                        <ListItemSecondaryAction>
                          <Typography variant='h6'>{`${calculateTotal(formik.values, returnDetails.Hazmat.Certified, true)}`}</Typography>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </Grid>
                </Collapse>
                <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <CheckCircleOutline style={{fontSize: '10em'}} />
                    <Typography variant="h6" style={{textAlign: 'center'}} gutterBottom>
                      Shipping and packing fees were successfully added
                    </Typography>
                  </div>
                </Collapse>
              </DialogContent>
              <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                  Close
                  </Button>
                </DialogActions>
              </Collapse>
              <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
                <DialogActions style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Button {...core.buttons.cancel(formik)}>
                    Cancel
                  </Button>
                  <Button {...core.buttons.submit(formik)}>Submit</Button>
                </DialogActions>
              </Collapse>
            </>
          );
        }}
      </FormikForm>
    </Dialog>
  );
};

ModalFinalizePayment.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool,
  returnDetails: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
};
const isMemoEqual = (prevProps, nextProps) => {
  if (prevProps.open !== nextProps.open) return false;
  return true;
};
export default React.memo(ModalFinalizePayment, isMemoEqual);

/*
<ListItem divider disableGutters>
                        <ListItemText primary={`Transportation (x1)`} secondary={!transportation ? 'TBD' : transportation.toLocaleString('en-US', currencyOption)} />
                        <ListItemSecondaryAction >
                          <CurrencyField name='Transportation' />
                        </ListItemSecondaryAction>
                      </ListItem>
                      */
// const transportation = useMemo(() => priceByState[state].inboundRate + (moduleCount * priceByState[state]['outboundEconomy']), [moduleCount, state]);
