import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import {Button, Collapse, Grid, MenuItem, Typography} from '@material-ui/core';
import {CheckCircleOutline} from '@material-ui/icons';
import {Alert} from 'components/';
import Dropzone from 'react-dropzone';
import {object, string} from 'yup';
import {firestoreUtility} from 'utilities';
import {FormikForm, SelectField, TextField} from 'components/formik';
import {Storage} from 'firebaseConfig';

const ModalUploadFile = ({close, open, returnDetails}) => {
  const [stage, setStage] = useState('dropfile');
  const [file, setFile] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleClose = () => {
    setStage('dropfile');
    close();
  };
  const dialogProps = {
    fullWidth: true,
    maxWidth: 'sm',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  };
  const dropzoneProps = ({setFieldValue, setStatus}) => ({
    accept: ['application/pdf'],
    maxSize: 5242880,
    minSize: 1024,
    multiple: false,
    onDropAccepted: file => {
      setFile(file);
      setFieldValue('FileName', file[0].name);
      setStage('information');
    },
    onDropRejected: file => {
      const fileExtension = file[0].name.split('.').pop();
      if (file[0].size < 100) {
        setStatus({alert: 'The file you selected was too small.'});
      } else if (file[0].size > 5242880) {
        setStatus({alert: 'The file you selected was too large.'});
      } else if (fileExtension.toLowerCase() !== 'pdf') {
        setStatus({alert: 'The file you upload must be a pdf.'});
      }
    },
    onFileDialogCancel: () => {
      setFile([]);
      setFieldValue('FileName', '');
    },
  });
  const fileDropProps = {
    style: {
      alignItems: 'center',
      backgroundColor: 'aliceblue',
      border: '2px gray dotted',
      display: 'flex',
      height: '200px',
      justifyContent: 'center',
    },
  };
  const fileDropTextProps = {
    style: {display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center'},
  };
  const formProps = {
    initialStatus: {alert: ''},
    initialValues: {
      FileName: '',
      FileType: '',
    },
    onSubmit: async (values, actions) => {
      setStage('upload');
      const getUploadProps = () => {
        let uploadDoc = '';
        let uploadKey = '';
        if (values.FileType === 'Bill of Lading') {
          uploadDoc = Storage.ref()
            .child(`Return_Files/${returnDetails.BatteryReturnId}-BOL`)
            .put(file[0]);
          uploadKey = 'BOL';
        }
        if (values.FileType === 'Hazmat Marking') {
          uploadDoc = Storage.ref()
            .child(`Return_Files/${returnDetails.BatteryReturnId}-HM`)
            .put(file[0]);
          uploadKey = 'HazmatMarking';
        }
        if (values.FileType === 'Shipping Label') {
          uploadDoc = Storage.ref()
            .child(`Return_Files/${returnDetails.BatteryReturnId}-SL`)
            .put(file[0]);
          uploadKey = 'ShippingLabel';
        }
        return {uploadDoc, uploadKey};
      };
      const uploadProgress = snapshot => {
        setUploadProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      };
      const uploadSuccess = async (uploadDoc, uploadKey) => {
        try {
          const downloadURL = await uploadDoc.snapshot.ref.getDownloadURL();
          const updateDocument = {
            collection: 'BatteryReturns',
            writeType: 'update',
            docId: returnDetails.BatteryReturnId,
            docFields: {
              Files: {...returnDetails.Files, [uploadKey]: downloadURL},
            },
          };
          await firestoreUtility(updateDocument);
          setStage('success');
        } catch (error) {
          actions.setStatus({alert: error.message});
          actions.setSubmitting(false);
        }
      };
      const uploadError = error => actions.setStatus({alert: error.message});
      const {uploadDoc, uploadKey} = getUploadProps();
      if (uploadDoc && uploadKey) {
        uploadDoc.on('state_changed', uploadProgress, uploadError, () => uploadSuccess(uploadDoc, uploadKey));
      }
    },
    validationSchema: object().shape({
      FileType: string().label('File Type').required(),
    }),
  };

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {({setStatus, status, isSubmitting, isValidating, setFieldValue}) => (
          <Fragment>
            <DialogTitle>
              <Collapse in={stage !== 'success' && stage !== 'upload'} timeout={{enter: 500, exit: 500}}>
                Upload File
              </Collapse>
            </DialogTitle>
            <DialogContent>
              <Collapse in={stage === 'dropfile'} timeout={{enter: 500, exit: 500}}>
                <Dropzone {...dropzoneProps({setFieldValue, setStatus})}>
                  {({getRootProps, getInputProps}) => (
                    <div {...getRootProps()} {...fileDropProps}>
                      <input {...getInputProps()} />
                      <div {...fileDropTextProps}>
                        <Typography variant="subtitle1">Drop File Here or Click Inside Box to Select</Typography>
                        <Typography variant="body2">(File Must Be a PDF Under 5mb)</Typography>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </Collapse>
              <Collapse in={stage === 'information'} timeout={{enter: 500, exit: 500}}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <TextField name="FileName" label="File Name" disabled />
                  </Grid>
                  <Grid item xs={12} sm>
                    <SelectField name="FileType" label="File Type" select>
                      {['Bill of Lading', 'Hazmat Marking', 'Shipping Label'].map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </SelectField>
                  </Grid>
                </Grid>
              </Collapse>
              <Collapse in={stage === 'upload'} timeout={{enter: 500, exit: 500}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '24px'}}>
                  <Typography variant="h3" gutterBottom>
                    Upload Progress
                  </Typography>
                  <Typography variant="h1">{uploadProgress.toFixed(0)}%</Typography>
                </div>
              </Collapse>
              <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
                <DialogContent>
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <CheckCircleOutline style={{fontSize: '10em'}} />
                    <Typography variant="h6" style={{textAlign: 'center'}} gutterBottom>
                      File has been successfully uploaded.
                    </Typography>
                  </div>
                </DialogContent>
              </Collapse>
              <Alert message={status.alert} />
            </DialogContent>
            <Collapse in={stage === 'dropfile'} timeout={{enter: 500, exit: 500}}>
              <DialogActions>
                <Button onClick={() => handleClose()} color="primary" disabled={isSubmitting || isValidating}>
                  Cancel
                </Button>
              </DialogActions>
            </Collapse>
            <Collapse in={stage === 'information'} timeout={{enter: 500, exit: 500}}>
              <DialogActions>
                <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                  <Button onClick={() => handleClose()} color="secondary" disabled={isSubmitting || isValidating}>
                    Cancel
                  </Button>
                  <Button type="submit" color="primary" disabled={isSubmitting || isValidating}>
                    Upload
                  </Button>
                </div>
              </DialogActions>
            </Collapse>
            <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Collapse>
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

ModalUploadFile.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  returnDetails: PropTypes.object.isRequired,
};
const isMemoEqual = (prevProps, nextProps) => {
  if (prevProps.open !== nextProps.open) return false;
  return true;
};
export default React.memo(ModalUploadFile, isMemoEqual);
