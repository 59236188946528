import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {currencyOption, batteryKitPrice} from 'constLists.js';
const PriceBreakdown = ({returnDetails, currentUser}) => {
  const styles = useStyles();
  const {DamagedModuleKits, Inbound, Outbound, RecyclingFee, ManagementFee, ThirdPartyPackaging, Total} = returnDetails.Accounting;
  const RecyclingAndManagement = parseFloat(RecyclingFee) + parseFloat(ManagementFee);
  const moduleCount = returnDetails.Battery.DefectiveModules;
  const transportation = (parseFloat(Inbound) + parseFloat(Outbound)).toLocaleString('en-US', currencyOption);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography className={styles.lineHeight} style={{fontWeight: 'bold'}}>COST BREAKDOWN</Typography>
      </Grid>
      <Grid item xs={12} container spacing={0} style={{backgroundColor: 'rgba(211, 211, 211, .3)'}}>
        {currentUser.Role === 'Admin' ?
          <>
            <Grid item xs={6}>
              <Typography>Recycling Fee (x1)</Typography>
            </Grid>
            <Grid item xs={6} style={{textAlign: 'end'}}>
              <Typography>{`$${RecyclingFee}`}</Typography>
            </Grid>
            <Grid item xs={12} style={{marginBottom: 24}}>
              <Typography>${RecyclingFee}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>Management Fee (x1)</Typography>
            </Grid>
            <Grid item xs={6} style={{textAlign: 'end'}}>
              <Typography>{`$${ManagementFee}`}</Typography>
            </Grid>
            <Grid item xs={12} style={{marginBottom: 24}}>
              <Typography>${ManagementFee}</Typography>
            </Grid>
          </> :
          <>
            <Grid item xs={6}>
              <Typography>Battery Recycling & Management (x1)</Typography>
            </Grid>
            <Grid item xs={6} style={{textAlign: 'end'}}>
              <Typography>{`$${RecyclingAndManagement}`}</Typography>
            </Grid>
            <Grid item xs={12} style={{marginBottom: 24}}>
              <Typography>${RecyclingAndManagement}</Typography>
            </Grid>
          </>
        }
        <Grid item xs={6}>
          <Typography>{`Damaged Module Kit${moduleCount > 1 ? 's' : ''} (x${moduleCount})`}</Typography>
        </Grid>
        <Grid item xs={6} style={{textAlign: 'end'}}>
          <Typography>{`$${DamagedModuleKits}`}</Typography>
        </Grid>
        <Grid item xs={12} style={{marginBottom: 24}}>
          <Typography>{batteryKitPrice.toLocaleString('en-US', currencyOption)}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>Transportation (x1)</Typography>
        </Grid>
        <Grid item xs={6} style={{textAlign: 'end'}}>
          <Typography>{`${transportation}`}</Typography>
        </Grid>
        <Grid item xs={12} style={{marginBottom: 24}}>
          <Typography>{transportation}</Typography>
        </Grid>
        {!returnDetails.Hazmat.Certified &&
        <>
          <Grid item xs={6}>
            <Typography>Third Party Packaging (x1)</Typography>
          </Grid>
          <Grid item xs={6} style={{textAlign: 'end'}}>
            <Typography>{`$${ThirdPartyPackaging}`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>${ThirdPartyPackaging}</Typography>
          </Grid>
        </>
        }
      </Grid>
      <Grid item xs={6}>
        <Typography className={styles.lineHeight} style={{fontWeight: 'bold'}}>Total</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography className={styles.lineHeight} style={{fontWeight: 'bold', textAlign: 'end'}}>{parseFloat(Total).toLocaleString('en-US', currencyOption)}</Typography>
      </Grid>
    </Grid>
  );
};
const useStyles = makeStyles(theme => ({
  noPadding: {
    paddingLeft: 0,
  },
  lineHeight: {
    lineHeight: 1.2,
  },
}));
PriceBreakdown.propTypes = {
  returnDetails: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
};
export default PriceBreakdown;
