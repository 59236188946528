import React from 'react';
import PropTypes from 'prop-types';
import {AppBar, Hidden, Toolbar, IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Menu} from '@material-ui/icons';
import logo from '../../media/logo.png';

const MainAppBar = ({disableGutters, toggleDrawer}) => {
  const styles = useStyles();

  return (
    <AppBar position="absolute" className={styles.appBar}>
      <Toolbar>
        <Hidden mdUp>
          <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
            <div style={{display: 'flex'}}>
              <img alt="EV Battery Returns Logo" src={logo} style={{height: '75px', padding: '10px 0px 10px 0px'}} />
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <IconButton aria-label="menu" style={{color: 'white'}} onClick={toggleDrawer}>
                <Menu fontSize="large" />
              </IconButton>
            </div>
          </div>
        </Hidden>
        <Hidden smDown>
          <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
            <div style={{display: 'flex', width: '200px'}}>
              <img alt="EV Battery Returns Logo" src={logo} style={{height: '75px', padding: '10px 0px 10px 0px'}} />
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              {/* Future Items can go here*/}
            </div>
          </div>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));
MainAppBar.propTypes = {
  disableGutters: PropTypes.bool,
  toggleDrawer: PropTypes.func,
};
export default React.memo(MainAppBar);
