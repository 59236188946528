import React, {useState, useMemo, useCallback} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Button, Collapse, Grid, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Typography} from '@material-ui/core';
import {CheckCircleOutline} from '@material-ui/icons';
import {FormikForm, TextField, SelectField, DateField} from 'components/formik';
import {object, string, date} from 'yup';
import moment from 'moment';
import {firestoreUtility} from 'utilities';


const ModalAddShipment = ({open, close, returnDetails}) => {
  const [stage, setStage] = useState('shipment');
  const {currentUser} = useSelector(state => state.auth);
  const handleClose = useCallback(() => {
    setStage('shipment');
    close();
  }, [close]);
  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'xs',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [open]);
  const formProps = useMemo(() => ({
    initilStatus: {alert: ''},
    initialValues: {
      Carrier: 'FedEx',
      PickupDate: moment().format('YYYY-MM-DD'),
      ShippingPaper: '',
      ShippingPO: '',
    },
    onSubmit: async (values, actions) => {
      try {
        const updateDoc = {
          collection: 'BatteryReturns',
          writeType: 'update',
          docId: returnDetails.BatteryReturnId,
          docFields: {
            'Date.Reminder': moment().add(10, 'days').toDate(),
            'ShipmentDetails.Carrier': values.Carrier,
            'ShipmentDetails.PickupDate': new Date(moment(values.PickupDate)),
            'ShipmentDetails.ShippingPaper': values.ShippingPaper,
            'ShipmentDetails.ShippingPO': values.ShippingPO,
            'Status': 'Shipment Scheduled',
          },
        };
        await firestoreUtility(updateDoc);
        const addEmail = {
          collection: 'Emails',
          writeType: 'add',
          docFields: {
            to: currentUser.Role === 'Admin' ? returnDetails.LocationPickup.Email : [currentUser.Email, returnDetails.LocationPickup.Email],
            template: {
              data: {VIN: returnDetails.Battery.VIN, carrier: values.Carrier, date: values.PickupDate, shippingPaperNo: values.ShippingPaper},
              name: 'PickupScheduled',
            },
          },
        };
        await firestoreUtility(addEmail);
        setStage('success');
      } catch (error) {
        actions.setStatus({alert: error.message});
        actions.setSubmitting(false);
      }
    },
    validationSchema: object().shape({
      Carrier: string().required(),
      PickupDate: date().required(),
      ShippingPaper: string().label('Shipping Paper No.').required(),
      ShippingPO: string().label('Shipping PO No.'),
    }),
  }), [currentUser.Email, currentUser.Role, returnDetails.Battery.VIN, returnDetails.BatteryReturnId, returnDetails.LocationPickup.Email]);
  const core = useMemo(() => ({
    buttons: {
      cancel: formik => ({
        color: 'secondary',
        disabled: formik.isSubmitting || formik.isValidating,
        onClick: handleClose,
      }),
      submit: formik => ({
        type: 'submit',
        color: 'primary',
        disableRipple: true,
        disabled: formik.isSubmitting || formik.isValidating,
      }),
    },
  }), [handleClose]);
  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {formik => {
          return (
            <>
              <DialogTitle>
                <Collapse in={stage === 'shipment'} timeout={{enter: 500, exit: 500}}>
                  Shipment Details
                </Collapse>
              </DialogTitle>
              <DialogContent>
                <Collapse in={stage === 'shipment'} timeout={{enter: 500, exit: 500}}>
                  <Typography>Add details about battery shipment.</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <SelectField name='Carrier'>
                        <MenuItem value='FedEx'>FedEx</MenuItem>
                        <MenuItem value='Veolia'>Veolia</MenuItem>
                      </SelectField>
                    </Grid>
                    <Grid item xs={12}>
                      <DateField name='PickupDate' label='Pickup Date' />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField name='ShippingPaper' label='Shipping Paper No.' required />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField name='ShippingPO' label='Shipping PO No.' />
                    </Grid>
                  </Grid>
                </Collapse>
                <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <CheckCircleOutline style={{fontSize: '10em'}} />
                    <Typography variant="h6" style={{textAlign: 'center'}} gutterBottom>
                      Shipment details were successfully added
                    </Typography>
                  </div>
                </Collapse>
              </DialogContent>
              <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                  Close
                  </Button>
                </DialogActions>
              </Collapse>
              <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
                <DialogActions style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Button {...core.buttons.cancel(formik)}>
                    Cancel
                  </Button>
                  <Button {...core.buttons.submit(formik)}>Submit</Button>
                </DialogActions>
              </Collapse>
            </>
          );
        }}
      </FormikForm>
    </Dialog>
  );
};

ModalAddShipment.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool,
  returnDetails: PropTypes.object.isRequired,
};
const isMemoEqual = (prevProps, nextProps) => {
  if (prevProps.open !== nextProps.open) return false;
  return true;
};
export default React.memo(ModalAddShipment, isMemoEqual);
