import {SET_CURRENT_USER, RESET_CURRENT_USER, SET_LAST_PATH, RESET_LAST_PATH} from '../types';

const INITIAL_STATE = {
  currentUser: null,
  lastPath: '',
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action;
  switch (type) {
  case SET_CURRENT_USER:
    return {...state, currentUser: payload};
  case RESET_CURRENT_USER:
    return {...state, currentUser: null};
  case SET_LAST_PATH:
    return {...state, lastPath: payload};
  case RESET_LAST_PATH:
    return {...state, lastPath: ''};
  default:
    return state;
  }
};
