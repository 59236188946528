import React, {Fragment, useMemo, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {Dialog, DialogContent, DialogTitle, Collapse, Grid, Typography} from '@material-ui/core';
import {CheckCircleOutline} from '@material-ui/icons';
import {string, object} from 'yup';
import {Alert, DialogActions} from 'components/';
import {firestoreUtility, postalRegex} from 'utilities';
import {Firestore} from 'firebaseConfig';
import {setCurrentUser} from 'state/actions/auth';
import {TextField, FormikForm, AutoField} from 'components/formik/';
import {states} from 'constLists.js';

const ModalEditDealerLocation = ({close, modal}) => {
  const [stage, setStage] = useState('details');
  const dispatch = useDispatch();
  const {currentUser} = useSelector(state => state.auth);

  const actionsProps = useMemo(() => ({
    close: () => {
      setStage('details');
      close();
    },
    stage,
    submitStage: 'details',
  }), [close, stage]);
  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'sm',
    open: modal.type === 'addDealerLocation' || modal.type === 'editDealerLocation',
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [modal.type]);
  const formProps = useMemo(() => ({
    initialStatus: {alert: ''},
    initialValues: {
      Address1: modal.type === 'editDealerLocation' ? modal.location.Address1 : '',
      Address2: modal.type === 'editDealerLocation' ? modal.location.Address2 : '',
      City: modal.type === 'editDealerLocation' ? modal.location.City : '',
      State: modal.type === 'editDealerLocation' ? modal.location.State : '',
      PostalCode: modal.type === 'editDealerLocation' ? modal.location.PostalCode : '',
    },
    onSubmit: (values, actions) => {
      (async () => {
        const userLocations = currentUser.Locations;
        const formLocation = {
          Address1: values.Address1,
          Address2: values.Address2,
          City: values.City,
          State: values.State,
          PostalCode: values.PostalCode,
        };
        try {
          if (modal.type === 'addDealerLocation') userLocations.push(formLocation);
          if (modal.type === 'editDealerLocation') userLocations.splice(modal.location.tableData.id, 1, formLocation);
          const editDocument = {
            collection: 'Users',
            writeType: 'update',
            docId: currentUser.UserId,
            docFields: {Locations: userLocations},
          };
          await firestoreUtility(editDocument);
          const userDoc = await Firestore.collection('Users').doc(currentUser.UserId).get();
          if (userDoc && userDoc.exists) {
            const user = {
              ...userDoc.data(),
              UserId: userDoc.id,
              TimeAuthorized: currentUser.TimeAuthorized,
              TimeExpires: currentUser.TimeExpires,
            };
            dispatch(setCurrentUser(user));
          }
          setStage('success');
        } catch (error) {
          actions.setStatus({alert: error.message});
          actions.setSubmitting(false);
        }
      })();
    },
    validationSchema: object().shape({
      Address1: string().label('Address 1').required(),
      Address2: string().label('Address 2').notRequired(),
      City: string().required(),
      State: string().required(),
      PostalCode: string().label('Postal Code').required().matches(postalRegex, 'Postal Code is improperly formatted. (e.g. #####)'),
    }),
  }), [currentUser, dispatch, modal]);

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {({status, isValidating, isSubmitting}) => (
          <Fragment>
            <DialogTitle>
              <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
                {modal.type === 'addDealerLocation' ? 'Add' : 'Edit'} Dealership Location
              </Collapse>
            </DialogTitle>
            <DialogContent>
              <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField name='Address1' label='Address 1' fast required />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name='Address2' label='Address 2' fast />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField name='City' fast required />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <AutoField name='State' items={states} itemKey='value' required />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField name='PostalCode' label='Postal Code' fast required />
                  </Grid>
                </Grid>
              </Collapse>
              <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <CheckCircleOutline style={{fontSize: '10em'}} />
                  <div style={{width: '100%'}}>
                    <Typography variant="h6" style={{textAlign: 'center'}} gutterBottom>
                    Dealer location has been successfully updated.
                    </Typography>
                  </div>
                </div>
              </Collapse>
              <Alert message={status.alert} />
            </DialogContent>
            <DialogActions {...actionsProps} isValidating={isValidating} isSubmitting={isSubmitting} />
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

const isMemoEqual = (prevProps, nextProps) => {
  const prevModal = prevProps.modal;
  const nextModal = nextProps.modal;
  if (!prevModal.type && (nextModal.type === 'addDealerLocation' || nextModal.type === 'editDealerLocation')) return false;
  else if ((prevModal.type === 'addDealerLocation' || prevModal.type === 'editDealerLocation') && !nextModal.type) return false;
  return true;
};
ModalEditDealerLocation.propTypes = {
  close: PropTypes.func.isRequired,
  modal: PropTypes.object.isRequired,
};
export default React.memo(ModalEditDealerLocation, isMemoEqual);
