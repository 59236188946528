import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {ListItem, ListItemText, Divider, ListItemIcon} from '@material-ui/core';
import {useDispatch} from 'react-redux';
import {resetLastPath} from 'state/actions/auth';
import {PowerSettingsNew} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import {Redirect} from 'react-router-dom';
import {Auth} from 'firebaseConfig';

const LogoutItem = ({padding}) => {
  const [redirect, setRedirect] = useState(null);
  const dispatch = useDispatch();
  const styles = makeStyles({
    listItemButton: {padding},
  })();
  const handleLogout = () => {
    setRedirect('/signin');
    dispatch(resetLastPath());
    Auth.signOut();
  };

  if (redirect) return <Redirect to={redirect} />;
  return (
    <Fragment>
      <ListItem button className={styles.listItemButton} onClick={() => handleLogout()}>
        <ListItemIcon>
          <PowerSettingsNew />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItem>
      <Divider />
    </Fragment>
  );
};

LogoutItem.propTypes = {
  padding: PropTypes.number.isRequired,
};
export default React.memo(LogoutItem);
