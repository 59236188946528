import React from 'react';
import PropTypes from 'prop-types';
import {Paper, Typography} from '@material-ui/core';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Error, CheckCircleOutline} from '@material-ui/icons';

const Alert = props => {
  const {message, type, color} = props;
  const theme = useTheme();
  const styles = makeStyles({
    alertPaper: {
      ...theme.mixins.gutters(),
      alignItems: 'center',
      border: `1px solid ${color || theme.palette.secondary.main}`,
      display: 'flex',
      width: '100%',
      marginTop: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    alertIcon: {
      color: color || theme.palette.secondary.main,
      fontSize: '32px',
      marginRight: theme.spacing(2),
    },
  })();

  if (!message || message === '') return null;
  return (
    <Paper elevation={0} className={styles.alertPaper}>
      <GetAlertIcon type={type} styles={styles} />
      <Typography variant="body2">{message}</Typography>
    </Paper>
  );
};

const GetAlertIcon = ({styles, type}) => {
  if (type === 'error') return <Error className={styles.alertIcon} />;
  if (type === 'success') return <CheckCircleOutline className={styles.alertIcon} />;
  else return <Error className={styles.alertIcon} />;
};

Alert.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
  color: PropTypes.string,
};
export default React.memo(Alert);
