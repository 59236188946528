import React, {useCallback, useMemo, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {Button, Dialog, DialogContent, DialogTitle, Collapse, DialogActions, Typography} from '@material-ui/core';
import {DeleteOutline} from '@material-ui/icons';
import {Alert, SubmitButton} from 'components/';
import {firestoreUtility} from 'utilities';
import {Firestore} from 'firebaseConfig';
import {setCurrentUser} from 'state/actions/auth';

const ModalDeleteDealerLocation = ({close, modal}) => {
  const [stage, setStage] = useState('delete');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {currentUser} = useSelector(state => state.auth);

  const handleClose = useCallback(() => {
    setStage('delete');
    close();
  }, [close]);
  const handleDelete = useCallback(async () => {
    setError('');
    setLoading(true);
    try {
      const userLocations = currentUser.Locations;
      userLocations.splice(modal.location.tableData.id, 1);
      const editDocument = {
        collection: 'Users',
        writeType: 'update',
        docId: currentUser.UserId,
        docFields: {Locations: userLocations},
      };
      await firestoreUtility(editDocument);
      const userDoc = await Firestore.collection('Users').doc(currentUser.UserId).get();
      if (userDoc && userDoc.exists) {
        const user = {
          ...userDoc.data(),
          UserId: userDoc.id,
          TimeAuthorized: currentUser.TimeAuthorized,
          TimeExpires: currentUser.TimeExpires,
        };
        dispatch(setCurrentUser(user));
      }
      setStage('success');
      setError('');
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }, [currentUser, dispatch, modal]);
  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'sm',
    open: modal.type === 'deleteDealerLocation',
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [modal.type]);

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>
        <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
          Delete Dealer Location
        </Collapse>
      </DialogTitle>
      <DialogContent>
        <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
          <Typography style={{textAlign: 'center'}}>Are you sure you wish to delete this location?</Typography>
          {modal.location && (
            <Typography style={{textAlign: 'center'}} variant='subtitle2'>
              {`${modal.location.Address1}, ${modal.location.City}, ${modal.location.State} ${modal.location.PostalCode}`}
            </Typography>
          )}
        </Collapse>
        <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <DeleteOutline style={{fontSize: '10em'}} />
            <div style={{width: '100%'}}>
              <Typography variant="h6" style={{textAlign: 'center'}} gutterBottom>
                    Dealer location has been successfully removed.
              </Typography>
            </div>
          </div>
        </Collapse>
        <Alert message={error} />
      </DialogContent>
      <Collapse in={stage === 'delete'} timeout={{enter: 500, exit: 500}}>
        <DialogActions>
          <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
            <Button onClick={handleClose} color="secondary" disabled={loading}>
            Cancel
            </Button>
            <SubmitButton loading={loading} disabled={loading} onClick={handleDelete} color="primary" text={'Delete'}>
            </SubmitButton>
          </div>
        </DialogActions>
      </Collapse>
      <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Collapse>
    </Dialog>
  );
};

const isMemoEqual = (prevProps, nextProps) => {
  if (!prevProps.modal.type && nextProps.modal.type === 'deleteDealerLocation') return false;
  else if (prevProps.modal.type === 'deleteDealerLocation' && !nextProps.modal.type) return false;
  return true;
};
ModalDeleteDealerLocation.propTypes = {
  close: PropTypes.func.isRequired,
  modal: PropTypes.object.isRequired,
};
export default React.memo(ModalDeleteDealerLocation, isMemoEqual);
