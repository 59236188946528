import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/storage';


const productionConfig = {
  apiKey: 'AIzaSyCxXdOmwx87KK9B-8F4zyyNOQhfnobANkA',
  authDomain: 'a3-recall.firebaseapp.com',
  databaseURL: 'https://a3-recall.firebaseio.com',
  projectId: 'a3-recall',
  storageBucket: 'a3-recall.appspot.com',
  messagingSenderId: '470701869752',
  appId: '1:470701869752:web:b2cbe8e609101cabbc024d',
  measurementId: 'G-8XS71BZYM9',
};
firebase.firestore.setLogLevel('error');
const Fire = firebase.initializeApp(productionConfig);

export const FieldValue = firebase.firestore.FieldValue;
export const Firestore = Fire.firestore();
export const Auth = Fire.auth();
export const Functions = Fire.functions();
export const Storage = Fire.storage();
