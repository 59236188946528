import React, {useEffect, useState} from 'react';
import {Route, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {setCurrentUser, resetCurrentUser, setLastPath} from 'state/actions/auth';
import {Auth, Firestore} from 'firebaseConfig';
import Loader from './Loader';
import {withRouter} from 'react-router';

const PrivateRoute = props => {
  const [auth, setAuth] = useState({checked: false, user: null});
  const dispatch = useDispatch();
  useEffect(() => dispatch(setLastPath(window.location.href)), [dispatch]);
  const setCheckedUserToNull = () => {
    setAuth({checked: true, user: null});
    dispatch(resetCurrentUser());
  };
  const authListener = () => {
    const unsubscribe = Auth.onIdTokenChanged(async idToken => {
      let userDoc = null; let tokenResult = null;
      if (idToken) {
        tokenResult = await Auth.currentUser.getIdTokenResult();
        userDoc = await Firestore.collection('Users').doc(tokenResult.claims.user_id).get();
      }
      if (userDoc && userDoc.exists) {
        const user = {
          ...userDoc.data(),
          UserId: userDoc.id,
          TimeAuthorized: tokenResult.claims.auth_time,
          TimeExpires: tokenResult.claims.exp,
        };
        setAuth({checked: true, user});
        dispatch(setCurrentUser(user));
      }
      if (!idToken || !userDoc || !userDoc.exists || !Auth.currentUser.emailVerified) setCheckedUserToNull();
    });
    return () => {
      unsubscribe();
      setCheckedUserToNull();
    };
  };
  useEffect(authListener, []);
  if (auth.checked && auth.user) {
    const {Component, path} = props;
    return <Route path={path} render={props => <Component {...props} currentUser={auth.user} />} />;
  } else if (auth.checked && !auth.user) {
    return <Redirect to={'/signin'} />;
  } else {
    return <Loader />;
  }
};

PrivateRoute.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.element, PropTypes.object, PropTypes.func]),
  path: PropTypes.string,
};
export default withRouter(PrivateRoute);
