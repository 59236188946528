import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {List, ListItem, Typography, ListItemIcon, ListItemText, CircularProgress} from '@material-ui/core';
import {Edit, BatteryFull, CloudUpload, Send, Alarm, Subject, BrokenImage, AttachMoney, Receipt, Done} from '@material-ui/icons';
import moment from 'moment';
import {withRouter} from 'react-router-dom';
import {firestoreUtility} from 'utilities';

const AdminActions = ({setModal, returnDetails, history}) => {
  const {currentUser} = useSelector(state => state.auth);
  const [sendingEmail, setSending] = useState(false);
  const getFileCount = () => {
    const files = returnDetails ? returnDetails.Files : null;
    if (!files) return 0;
    else return Object.keys(files).length;
  };
  const resendInvoice = async () => {
    setSending(true);
    const updateDoc = {
      collection: 'BatteryReturns',
      writeType: 'update',
      docId: returnDetails.BatteryReturnId,
      docFields: {
        'Date.Reminder': moment().add(3, 'days').toDate(),
      },
    };
    await firestoreUtility(updateDoc);
    const email = {
      collection: 'Emails',
      writeType: 'add',
      docFields: {
        to: currentUser.Role === 'Admin' ? returnDetails.LocationPickup.Email : [currentUser.Email, returnDetails.LocationPickup.Email],
        template: {
          data: {VIN: returnDetails.Battery.VIN, DocURL: returnDetails.BatteryReturnId},
          name: 'BatteryReturnPayment',
        },
      },
    };
    await firestoreUtility(email);
    setSending(false);
  };
  const core = {
    menuSide: {
      style: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: 'ghostwhite',
        border: '1px solid darkgray',
        margin: '8px 0px',
      },
      subheader: <Typography variant="subtitle1" style={{marginLeft: '8px', marginTop: '8px'}}>
      Admin Actions
      </Typography>,
    },
    listItemEmailDocuments: {
      primary: `Email Document${getFileCount() > 1 ? 's' : ''}`,
    },
    listItemReminder: {
      primary: returnDetails && returnDetails.Date.Reminder ? 'Edit Reminder' : 'Set Reminder',
      secondary: returnDetails && returnDetails.Date.Reminder ? moment(returnDetails.Date.Reminder.toDate()).format('MM/DD/YYYY') : null,
    },
  };

  if (currentUser.Role !== 'Admin') return null;
  return (
    <List {...core.menuSide}>
      <ListItem button onClick={() => setModal('modalEditReturn')}>
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        <ListItemText primary="Edit Details" />
      </ListItem>
      {returnDetails.Status === 'Request Received' && <ListItem button className="PulseAlert" onClick={() => setModal('modalModuleKits')}>
        <ListItemIcon>
          <BrokenImage />
        </ListItemIcon>
        <ListItemText primary="Send Outbound Kit" />
      </ListItem>}
      {returnDetails.Status === 'Module Kits Shipped' && <ListItem button className="PulseAlert" onClick={() => setModal('modalAddShipment')}>
        <ListItemIcon>
          <Subject />
        </ListItemIcon>
        <ListItemText primary="Add Return Shipment" />
      </ListItem>}
      {returnDetails.Status === 'Shipment Scheduled' && <ListItem button className="PulseAlert" onClick={() => setModal('modalReceiveBattery')}>
        <ListItemIcon>
          <BatteryFull />
        </ListItemIcon>
        <ListItemText primary="Receive Battery" />
      </ListItem>}
      {returnDetails.Status === 'Received' && <ListItem button className="PulseAlert" onClick={() => setModal('modalFinalizePayment')}>
        <ListItemIcon>
          <AttachMoney />
        </ListItemIcon>
        <ListItemText primary="Finalize Amount" />
      </ListItem>}
      {(['Paid', 'Invoiced'].indexOf(returnDetails.Status) > -1) && <ListItem button onClick={() => history.push(`/portal/battery-returns/${returnDetails.BatteryReturnId}/invoice`)}>
        <ListItemIcon>
          <Receipt />
        </ListItemIcon>
        <ListItemText primary="View Receipt" />
      </ListItem>}
      {returnDetails.Status === 'Invoiced' && <ListItem button onClick={() => setModal('modalPaymentReceived')}>
        <ListItemIcon>
          <Done />
        </ListItemIcon>
        <ListItemText primary="Payment Complete" />
      </ListItem>}
      <ListItem button onClick={() => setModal('modalUploadFile')}>
        <ListItemIcon>
          <CloudUpload />
        </ListItemIcon>
        <ListItemText primary="Upload Document" />
      </ListItem>
      {returnDetails.Files !== null && getFileCount() > 0 && (
        <ListItem button onClick={() => setModal('modalEmailDocuments')}>
          <ListItemIcon>
            <Send />
          </ListItemIcon>
          <ListItemText {...core.listItemEmailDocuments} />
        </ListItem>
      )}
      {returnDetails.Status === 'Pending Invoice' && (
        <ListItem button onClick={resendInvoice}>
          <ListItemIcon>
            {sendingEmail ? <CircularProgress size={32} /> : <Send />}
          </ListItemIcon>
          <ListItemText primary='Resend Invoice Email' />
        </ListItem>
      )}
      <ListItem button onClick={() => setModal('modalSetReminder')}>
        <ListItemIcon>
          <Alarm />
        </ListItemIcon>
        <ListItemText {...core.listItemReminder} />
      </ListItem>
    </List>
  );
};

AdminActions.propTypes = {
  setModal: PropTypes.func,
  returnDetails: PropTypes.object,
  history: PropTypes.object.isRequired,
};
export default withRouter(AdminActions);
