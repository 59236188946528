import {SET_BATTERY_RETURNS, RESET_FIRESTORE} from '../types';

const INITIAL_STATE = {
  batteryReturnsLoading: false,
  batteryReturns: null,
};

export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action;
  switch (type) {
  case SET_BATTERY_RETURNS:
    return {...state, batteryReturns: payload};
  case RESET_FIRESTORE:
    return {...INITIAL_STATE};
  default:
    return state;
  }
};
