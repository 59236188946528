import React, {Fragment, useState} from 'react';
import {useSelector} from 'react-redux';
import {ModalNewReturn, ViewBatteryReturnsTable, ViewNoBatteryReturns, ModalFinishAccount} from './BatteryReturn/index';
import {AsyncLoader} from 'components';

const BatteryReturns = () => {
  const [modal, setModal] = useState(false);
  const {batteryReturns} = useSelector(state => state.firestore);

  if (!batteryReturns) return <AsyncLoader />;
  return (
    <Fragment>
      <ViewBatteryReturnsTable setModal={() => setModal('newReturn')} />
      <ViewNoBatteryReturns setModal={modalType => setModal(modalType)} />
      <ModalNewReturn open={modal === 'newReturn'} close={() => setModal('')} />
      <ModalFinishAccount open={modal === 'finishSetup'} close={() => setModal('')} />
    </Fragment>
  );
};

export default BatteryReturns;
