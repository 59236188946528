import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, TextField } from '@material-ui/core';
import { Field, FastField } from 'formik';

const NumberField = ({ decimal, fast, name, onBlur, onChange, ...otherProps }) => {
  const setNumberValue = ({ field, form }) => {
    const value = parseFloat(field.value).toFixed(decimal);
    if (!isNaN(value)) form.setFieldValue(field.name, value);
    else form.setFieldValue(field.name, '');
  };
  const textFieldProps = ({ field, form }) => {
    //console.log('NumberField { props, field, form }', { props, field, form });
    return {
      disabled: form.isSubmitting,
      error: form.touched[name] && form.errors[name] ? true : false,
      fullWidth: true,
      helperText: form.touched[name] && form.errors[name],
      id: name,
      InputProps: { endAdornment: <InputAdornment position="end">No.</InputAdornment> },
      label: name,
      margin: 'dense',
      ...otherProps,
      onBlur: e => {
        field.onBlur(e);
        setNumberValue({ field, form });
        if (onBlur) onBlur({ field, form });
      },
      onChange: e => {
        field.onChange(e);
        if (onChange)
          onChange({
            field: { ...field, value: e.target.value },
            form: { ...form, values: { ...form.values, [name]: e.target.value } }
          });
      },
      onKeyDown: e => {
        if (e.keyCode >= 48 && e.keyCode <= 57) e.persist();
        else if (e.keyCode >= 96 && e.keyCode <= 105) e.persist();
        else if (e.keyCode === 8) e.persist();
        else if (e.keyCode === 9) e.persist();
        else if (e.keyCode === 13) e.persist();
        else if (e.keyCode === 46) e.persist();
        else if (e.keyCode === 189) e.persist();
        else if (e.keyCode === 190) {
          if (field.value.split('.').length > 1) e.preventDefault();
          else e.persist();
        } else e.preventDefault();
      }
    };
  };

  if (fast) {
    return (
      <FastField name={name}>
        {({ field, form }) => <TextField {...field} {...textFieldProps({ field, form })} />}
      </FastField>
    );
  }
  return (
    <Field name={name}>{({ field, form }) => <TextField {...field} {...textFieldProps({ field, form })} />}</Field>
  );
};

NumberField.defaultProps = {
  fast: false,
  decimal: 0
};
NumberField.propTypes = {
  fast: PropTypes.bool,
  decimal: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};
export default NumberField;
