import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogContent, DialogTitle, DialogActions, Collapse, Typography, Button} from '@material-ui/core';
import {CheckCircleOutline} from '@material-ui/icons';
import {Alert} from 'components/';
import {firestoreUtility} from 'utilities';
import {currencyOption} from 'constLists';
const numberToWord = ['', 'one', 'two', 'three', 'four'];

const ModalPaymentReceived = ({close, open, returnDetails}) => {
  const [stage, setStage] = useState('basic');
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const handleClose = () => {
    setStage('basic');
    setError('');
    setSubmitting('');
    close();
  };
  const dialogProps = {
    fullWidth: true,
    maxWidth: 'sm',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  };
  const handleSubmit = async (values, actions) => {
    setSubmitting(true);
    try {
      const updateDocument = {
        collection: 'BatteryReturns',
        writeType: 'update',
        docId: returnDetails.BatteryReturnId,
        docFields: {Status: 'Paid'},
      };
      await firestoreUtility(updateDocument);
      setSubmitting(false);
      setStage('success');
    } catch (error) {
      setError(error.message);
      setSubmitting(false);
    }
  };

  return (
    <Dialog {...dialogProps}>
      <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
        <DialogTitle>
          Battery Return Process Complete
        </DialogTitle>
      </Collapse>
      <DialogContent>
        <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
          <Typography>
            {`${returnDetails.DealerName} has paid the full amount of ${parseFloat(returnDetails.Accounting.Total).toLocaleString('en-US', currencyOption)} for the recycling of ${numberToWord[returnDetails.Battery.DefectiveModules]} battery modules.`}
          </Typography>
          <Typography>{`Battery VIN: ${returnDetails.Battery.VIN}`}</Typography>
          <Typography>{`Battery Serial Number: ${returnDetails.Battery.SerialNumber}`}</Typography>
          <Alert message={error} />
        </Collapse>
        <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
          <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <CheckCircleOutline style={{fontSize: '10em'}} />
            <Typography variant="h6" style={{textAlign: 'center'}} gutterBottom>
              {`Battery return has been succesfully marked as "Paid"`}
            </Typography>
          </div>
        </Collapse>
      </DialogContent>
      <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={submitting}>Close</Button>
        </DialogActions>
      </Collapse>
      <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
        <DialogActions style={{display: 'flex', justifyContent: 'space-between'}}>
          <Button color='secondary' onClick={handleClose} disabled={submitting}>Cancel</Button>
          <Button color='primary' onClick={handleSubmit} disabled={submitting}>Mark Paid</Button>
        </DialogActions>
      </Collapse>
    </Dialog>
  );
};

ModalPaymentReceived.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool,
  returnDetails: PropTypes.object.isRequired,
};
const isMemoEqual = (prevProps, nextProps) => {
  if (prevProps.open !== nextProps.open) return false;
  return true;
};
export default React.memo(ModalPaymentReceived, isMemoEqual);
