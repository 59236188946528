import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Typography} from '@material-ui/core';
import {Warning, LocalShipping} from '@material-ui/icons';


const DetailsHeader = ({returnDetails}) => {
  const {currentUser} = useSelector(state => state.auth);
  const adminUser = () => {
    if (currentUser.Role === 'Admin') return true;
    else return false;
  };
  const core = {
    icon: {
      style: {
        fontSize: '10em',
        fill: adminUser() && returnDetails.Battery.Hazards.length > 0 ? 'red' : 'slategray',
        width: '100%',
      },
    },
    typographyMain: {
      gutterBottom: adminUser() && returnDetails.Battery.Hazards.length > 0 ? false : true,
      style: {
        color: adminUser() && returnDetails.Battery.Hazards.length > 0 ? 'red' : 'black',
        textAlign: 'center',
        width: '100%',
      },
      variant: 'h6',
    },
  };

  if (adminUser() && returnDetails.Battery.Hazards.length > 0) {
    return (
      <Fragment>
        <Warning {...core.icon} />
        <Typography {...core.typographyMain}>Damaged Battery Return Details</Typography>
      </Fragment>
    );
  }
  return (
    <Fragment>
      <LocalShipping {...core.icon} />
      <Typography {...core.typographyMain}>Battery Return Details</Typography>
    </Fragment>
  );
};

DetailsHeader.propTypes = {
  returnDetails: PropTypes.object,
};
export default DetailsHeader;
