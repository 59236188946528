import './polyfills';
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import {Portal, Login, ErrorPage} from './views';
import {PrivateRoute} from './components';
import {Auth, Functions} from './firebaseConfig.js';
// Import Redux functionality
import {Provider} from 'react-redux';
import {store} from './state/store';

// Import Design Elements
import CssBaseline from '@material-ui/core/CssBaseline';
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import './index.css';
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0a3982',
      light: '#4b63b2',
      dark: '#001555',
      contrastText: '#fff',
    },
    secondary: {
      main: '#c62828',
      light: '#ff5f52',
      dark: '#8e0000',
      contrastText: '#fff',
    },
  },
  shape: {
    borderRadius: 4,
  },
});

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <CssBaseline />
            <Switch>
              <PrivateRoute path="/portal" Component={Portal} />
              <Route exact path="/signin" component={Login} />
              <Route exact path="/error" component={ErrorPage} />
              <Redirect exact to="/portal" />
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    );
  }
  async componentDidCatch(error, info) {
    if (process.env.NODE_ENV === 'production') {
      const {claims} = await Auth.currentUser.getIdTokenResult();
      await Functions.httpsCallable('emailCatchError')({
        errorMessage: error.message,
        errorName: error.name,
        errorStack: info.componentStack,
        user: claims.email || 'No User Data found.',
      });
      window.location.replace('http://a3.evbatteryreturns.com/error');
    } else {
      console.log('componentDidCatch', {error, info});
    }
  }
}

ReactDOM.render(<App />, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change unregister() to register() below. Note this comes with some
// pitfalls. Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
