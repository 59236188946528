// Auth Redux Types
export const SET_CURRENT_USER = 'reduxTypes/SET_CURRENT_USER';
export const RESET_CURRENT_USER = 'reduxTypes/RESET_CURRENT_USER';
export const SET_LAST_PATH = 'reduxTypes/SET_LAST_PATH';
export const RESET_LAST_PATH = 'reduxTypes/RESET_LAST_PATH';

// Firestore Redux Types
export const SET_BATTERY_RETURNS = 'reduxTypes/SET_BATTERY_RETURNS';
export const RESET_FIRESTORE = 'reduxTypes/RESET_FIRESTORE';
export const SET_BATTERY_RETURNS_LOADING = 'reduxTypes/SET_BATTERY_RETURNS_LOADING';
