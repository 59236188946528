import React, {useMemo, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Paper, Typography} from '@material-ui/core';
import {Pageview, GetApp} from '@material-ui/icons';
import {AsyncLoader} from 'components';
import {Table} from '@kbi/component-library';
import {exportToCSV} from '@kbi/utility-library';
import {Firestore} from 'firebaseConfig';

const AdminReport = () => {
  const [allUsers, setUsers] = useState({data: undefined, loaded: false});
  const {firestore: {batteryReturns}, auth: {currentUser}} = useSelector(state => state);
  useEffect(() => {
    const users = [];
    Firestore.collection('Users').get().then(snapshot => {
      snapshot.forEach(doc => {
        users.push({userId: doc.id, ...doc.data()});
      });
      setUsers({data: users, loaded: true});
    });
  }, []);

  const returnsTableData = useMemo(() => {
    if (batteryReturns) {
      return batteryReturns.list.filter(batReturn => {
        const options = ['Received', 'Pending Invoice', 'Invoiced', 'Paid'];
        return options.includes(batReturn.Status);
      }).map(batReturn => {
        return {...batReturn, Battery: {...batReturn.Battery, ModuleNumbers: batReturn.Battery.ModuleNumbers.join(', ')}};
      });
    }
  }, [batteryReturns]);

  const returnColumns = [
    {Header: 'Dealer No.', accessor: 'DealerNo'},
    {Header: 'Date Requested', accessor: 'Date.Requested', type: 'timestamp', defaultSort: 'desc'},
    {Header: 'Module Count', accessor: 'Battery.DefectiveModules'},
    {Header: 'Status', accessor: 'Status', lookup: {'Received': 'Received', 'Pending Invoice': 'Pending Invoice', 'Invoiced': 'Invoiced', 'Paid': 'Paid'}},
    {Header: 'VIN', accessor: 'Battery.VIN'},
    {Header: 'Shipping No.', accessor: 'ShipmentDetails.ShippingPaper'},
    {Header: 'Hazmat Certified', accessor: 'Hazmat.Certified', type: 'boolean'},
    {Header: 'Serial Number', accessor: 'Battery.SerialNumber'},
  ];

  const usersColumns = [
    {Header: 'Name', accessor: 'NameFull'},
    {Header: 'Dealer No.', accessor: 'DealerNo'},
    {Header: 'Dealer Name', accessor: 'DealerName'},
    {Header: 'Email', accessor: 'Email'},
    {Header: 'Phone', accessor: 'Phone'},
    {Header: 'Role', accessor: 'Role', filterField: 'select'},
    {Header: 'Verified', accessor: 'Verified', type: 'boolean'},
  ];

  if (!batteryReturns || !currentUser || !allUsers.loaded) return <AsyncLoader />;
  const returnsTableProps = {
    actionsPerRow: [
      {icon: Pageview, tooltip: 'View Return Details', onClick: ({rowData}) => history.push(`/portal/battery-returns/${rowData.BatteryReturnId}`)}, // eslint-disable-line
    ],
    actionsBar: [{icon: GetApp, text: 'Export', onClick: ({tableData}) => {
      exportToCSV(tableData.data, [...returnColumns, {Header: 'Module Numbers', accessor: 'Battery.ModuleNumbers'}], 'received-battery-returns');
    }}],
    data: returnsTableData,
    columns: returnColumns,
  };
  const usersTableProps = {
    data: allUsers.data,
    columns: usersColumns,
  };
  return (
    <>
      <Paper elevation={2} style={{marginTop: '24px'}}>
        <div style={{height: 64, display: 'flex', alignItems: 'center', paddingLeft: 16}}>
          <Typography variant='h6'>Received Battery Returns</Typography>
        </div>
        <Table {...returnsTableProps} />
      </Paper>
      <Paper elevation={2} style={{marginTop: '24px'}}>
        <div style={{height: 64, display: 'flex', alignItems: 'center', paddingLeft: 16}}>
          <Typography variant='h6'>Portal Users</Typography>
        </div>
        <Table {...usersTableProps} />
      </Paper>
    </>
  );
};

export default withRouter(AdminReport);
