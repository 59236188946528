import React, {Fragment, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import {Button, Collapse, Grid, Typography} from '@material-ui/core';
import {CheckCircleOutline} from '@material-ui/icons';
import {Alert} from 'components/';
import moment from 'moment';
import {FieldArray} from 'formik';
import {date, object, string, array} from 'yup';
import {DateField, FormikForm, TextField} from 'components/formik/';
import {firestoreUtility} from 'utilities/';
const createInitialValue = (numberOfKits) => {
  const value = [];
  for (let i = 0; i < numberOfKits; i++) {
    value.push('');
  }
  return value;
};

const ModalAddReceipt = ({close, open, returnDetails}) => {
  const [stage, setStage] = useState('dateReceived');
  const {currentUser} = useSelector(state => state.auth);
  const handleClose = () => {
    setStage('dateReceived');
    close();
  };
  const dialogProps = {
    fullWidth: true,
    maxWidth: 'sm',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  };
  const formProps = {
    initialStatus: {alert: ''},
    initialValues: {
      DateReceived: moment().format('YYYY-MM-DD'),
      BatterySerialNumber: '',
      ModuleSerialNumbers: createInitialValue(returnDetails.Battery.DefectiveModules),
    },
    onSubmit: async (values, actions) => {
      try {
        const updateDoc = {
          collection: 'BatteryReturns',
          writeType: 'update',
          docId: returnDetails.BatteryReturnId,
          docFields: {
            'Date.Received': moment(values.DateReceived).toDate(),
            'Date.Reminder': moment().add(1, 'days').toDate(),
            'Battery.SerialNumber': values.BatterySerialNumber,
            'Battery.ModuleNumbers': values.ModuleSerialNumbers,
            'Status': 'Received',
          },
        };
        await firestoreUtility(updateDoc);
        const addDoc = {
          collection: 'Emails',
          writeType: 'add',
          docFields: {
            to: currentUser.Role === 'Admin' ? returnDetails.LocationPickup.Email : [currentUser.Email, returnDetails.LocationPickup.Email],
            template: {
              data: {Certificate: `http://a3.evbatteryreturns.com/portal/battery-returns/${returnDetails.BatteryReturnId}`, VIN: returnDetails.Battery.VIN},
              name: 'BatteryReceived',
            },
          },
        };
        await firestoreUtility(addDoc);
        setStage('success');
      } catch (error) {
        actions.setStatus({alert: error.message});
        actions.setSubmitting(false);
      }
    },
    validationSchema: object().shape({
      DateReceived: date()
        .label('Date Received')
        .required()
        .max(new Date(), 'Date Requested cannot be set in the future'),
      BatterySerialNumber: string()
        .label('Serial Number')
        .required()
        .min(3),
      ModuleSerialNumbers: array().of(string().min(2).required('This field is required')),
    }),
  };

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {formik => (
          <Fragment>
            <DialogTitle>
              <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
                Shipment Received
              </Collapse>
            </DialogTitle>
            <DialogContent id="dialog-content">
              <Collapse in={stage === 'dateReceived'} timeout={{enter: 500, exit: 500}}>
                <DialogContentText>Enter date battery received.</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <DateField name="DateReceived" label="Date Received" required />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name="BatterySerialNumber" label="Battery Serial Number" required />
                  </Grid>
                  <FieldArray name="ModuleSerialNumbers">
                    {arrayHelpers => (
                      formik.values.ModuleSerialNumbers.map((module, i) => {
                        return (
                          <Grid item xs={12} key={i}>
                            <TextField name={`ModuleSerialNumbers[${i}]`} label={`Module Serial ${i + 1}`} required />
                          </Grid>
                        );
                      })
                    )}
                  </FieldArray>
                  <Grid item xs={12}>
                    <Alert message={formik.status.alert} />
                  </Grid>
                </Grid>
              </Collapse>
              <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <CheckCircleOutline style={{fontSize: '10em'}} />
                  <Typography variant="h6" style={{textAlign: 'center'}} gutterBottom>
                    Battery was successfully received into portal.
                  </Typography>
                </div>
              </Collapse>
            </DialogContent>
            <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Collapse>
            <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
              <DialogActions>
                <Button onClick={handleClose} color="secondary" disabled={formik.isSubmitting || formik.isValidating}>
                  Cancel
                </Button>
                <Button type="submit" color="primary" disabled={formik.isSubmitting || formik.isValidating}>
                  Submit
                </Button>
              </DialogActions>
            </Collapse>
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

ModalAddReceipt.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool,
  returnDetails: PropTypes.object.isRequired,
};
const isMemoEqual = (prevProps, nextProps) => {
  if (prevProps.open !== nextProps.open) return false;
  return true;
};
export default React.memo(ModalAddReceipt, isMemoEqual);

/*
  const dropzoneProps = ({setFieldError, setFieldValue}) => ({
    accept: 'image/*',
    maxSize: 5242880,
    minSize: 1024,
    multiple: false,
    onDropAccepted: async acceptedFiles => {
      try {
        const image = await Jimp.read(URL.createObjectURL(acceptedFiles[0]));
        const getImageSRC = async () => {
          let src = null;
          if (image.bitmap.width < 600) src = image.getBase64Async(Jimp.MIME_JPEG);
          else src = await image.resize(600, Jimp.AUTO).getBase64Async(Jimp.MIME_JPEG);
          return src;
        };
        const imageSRC = await getImageSRC();
        setQrImage(imageSRC);
        try {
          const result = await codeReader.decodeFromImage(undefined, imageSRC);
          setFieldValue('SerialNumber', result.text);
          setFieldError('SerialNumber', null);
        } catch (err) {
          setFieldValue('SerialNumber', '');
          setFieldError('SerialNumber', 'QR Code Not Found or Invalid. Try Again.');
        }
      } catch (error) {
        setFieldValue('SerialNumber', '');
        setFieldError('SerialNumber', error.message);
      }
    },
    onDropRejected: rejectedFiles => {
      const {size, type} = rejectedFiles[0];
      setFieldValue('SerialNumber', '');
      let alert = 'An error occured. Try again.';
      if (size < 1024) alert = 'The file you selected was too small.';
      else if (size > 5242880) alert = 'The file you selected was too large.';
      else if (!includes(type, 'image')) alert = 'The file you selected is not an image.';
      setFieldError('SerialNumber', alert);
    },
  });

  const fileDropProps = {
    style: {
      alignItems: 'center',
      backgroundColor: 'aliceblue',
      border: '2px gray dotted',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
    },
  };

  const serialImageProps = {
    src: qrImage,
    style: {
      border: '1px solid gray',
      boxShadow: '4px 4px 5px gray',
      maxHeight: '150px',
      maxWidth: document.getElementById('dialog-content') ?
        document.getElementById('dialog-content').offsetWidth * 0.8 :
        null,
    },
  };

<Grid item xs={12}>
  <Dropzone {...dropzoneProps({setFieldError: formik.setFieldError, setFieldValue: formik.setFieldValue})}>
    {({getRootProps, getInputProps}) => (
      <div {...getRootProps()} {...fileDropProps}>
        <input {...getInputProps()} />
        <div style={{display: 'flex', padding: '16px'}}>
          <PhotoCamera />
          <Typography style={{paddingLeft: '16px'}}>Serial Number</Typography>
        </div>
      </div>
    )}
  </Dropzone>
</Grid>
{qrImage && (
                    <Grid item xs={12} style={{textAlign: 'center'}}>
                      <img alt="QR Preview" {...serialImageProps} />
                    </Grid>
                  )}
*/
