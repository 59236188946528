import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Dialog, DialogContent, DialogTitle, Collapse, Typography} from '@material-ui/core';
import {CheckCircleOutline} from '@material-ui/icons';
import {string, object} from 'yup';
import {Alert, DialogActions} from 'components/';
import {firestoreUtility} from 'utilities';
import {TextField, FormikForm} from 'components/formik/';
import {FieldValue} from 'firebaseConfig';

const ModalAddNote = ({close, open, returnDetails}) => {
  const [stage, setStage] = useState('note');
  const {currentUser} = useSelector(state => state.auth);
  const handleClose = () => {
    setStage('note');
    close();
  };
  const dialogProps = {
    fullWidth: true,
    maxWidth: 'sm',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  };
  const formProps = {
    initialStatus: {alert: ''},
    initialValues: {
      Note: '',
    },
    onSubmit: async (values, actions) => {
      try {
        const updateDocument = {
          collection: 'BatteryReturns',
          writeType: 'update',
          docId: returnDetails.BatteryReturnId,
          docFields: {Notes: FieldValue.arrayUnion({CreatedBy: currentUser.NameFull, CreatedOn: new Date(), Note: values.Note})},
        };
        await firestoreUtility(updateDocument);
        setStage('success');
      } catch (error) {
        actions.setStatus({alert: error.message});
        actions.setSubmitting(false);
      }
    },
    validationSchema: object().shape({
      Note: string().required(),
    }),
  };
  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {({status, isValidating, isSubmitting}) => (
          <>
            <DialogTitle>
              <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
                Add Note
              </Collapse>
            </DialogTitle>
            <DialogContent>
              <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
                <TextField name='Note' />
              </Collapse>
              <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <CheckCircleOutline style={{fontSize: '10em'}} />
                  <div style={{width: '100%'}}>
                    <Typography variant="h6" style={{textAlign: 'center'}} gutterBottom>
                      Note has been successfully added.
                    </Typography>
                  </div>
                </div>
              </Collapse>
              <Alert message={status.alert} />
            </DialogContent>
            <DialogActions close={handleClose} isValidating={isValidating} isSubmitting={isSubmitting} stage={stage} submitStage={'note'} />
          </>
        )}
      </FormikForm>
    </Dialog>
  );
};

ModalAddNote.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool,
  returnDetails: PropTypes.object.isRequired,
};
const isMemoEqual = (prevProps, nextProps) => {
  if (prevProps.open !== nextProps.open) return false;
  return true;
};
export default React.memo(ModalAddNote, isMemoEqual);
