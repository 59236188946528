import React from 'react';
import PropTypes from 'prop-types';
import {InputAdornment, TextField} from '@material-ui/core';
import {Field, FastField} from 'formik';

const CurrencyField = ({decimal, fast, name, onBlur, onChange, ...otherProps}) => {
  const setCurrencyValue = ({field, form}) => {
    const value = parseFloat(field.value).toFixed(decimal);
    if (!isNaN(value)) form.setFieldValue(name, value);
    else form.setFieldValue(name, '');
  };
  const textFieldProps = ({field, form}) => {
    // console.log('CurrencyField { props, field, form }', { otherProps, field, form });
    return {
      disabled: form.isSubmitting,
      error: form.touched[name] && form.errors[name] ? true : false,
      fullWidth: true,
      helperText: form.touched[name] && form.errors[name],
      id: name,
      InputProps: {startAdornment: <InputAdornment position="start">$</InputAdornment>},
      label: name,
      margin: 'dense',
      ...otherProps,
      onBlur: e => {
        field.onBlur(e);
        if (e.target.value === '') form.setFieldValue(name, '0.00');
        else setCurrencyValue({field, form});
        if (onBlur) onBlur({field, form});
      },
      onChange: e => {
        field.onChange(e);
        if (onChange) onChange({field, form});
      },
      onKeyDown: e => {
        const allowedKeyCodes = [8, 9, 13, 37, 38, 39, 40, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 189];
        const periodKeyCodes = [110, 190];
        if (allowedKeyCodes.indexOf(e.keyCode) >= 0) e.persist();
        else if (periodKeyCodes.indexOf(e.keyCode) >= 0 && field.value.split('.').length <= 1) e.persist();
        else e.preventDefault();
      },
    };
  };

  if (fast) {
    return <FastField name={name}>{({field, form}) => <TextField {...field} {...textFieldProps} />}</FastField>;
  }
  return (
    <Field name={name}>{({field, form}) => <TextField {...field} {...textFieldProps({field, form})} />}</Field>
  );
};

CurrencyField.defaultProps = {
  fast: false,
  decimal: 2,
};
CurrencyField.propTypes = {
  decimal: PropTypes.number,
  fast: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};
export default CurrencyField;
