import React, {useEffect, useState, useCallback} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {AsyncLoader} from 'components';
import {Button, Paper} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Print, Receipt, CreditCard} from '@material-ui/icons';
import {PriceBreakdown, ModalPaperInvoice, ModalCreditCard, Header} from './Invoice/';
import NotPaid from 'media/paid-stamp-transparent-10.png';

const Invoice = ({match, history}) => {
  const {firestore: {batteryReturns}, auth: {currentUser}} = useSelector(state => state);
  const styles = useStyles();
  const [returnDetails, setReturnDetails] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const handleCCOpen = () => setModalType('creditCard');
  const handleInvoiceOpen = () => setModalType('paperInvoice');
  const handlePrint = () => window.print();
  const [modalType, setModalType] = useState('');
  const handleClose = () => setModalType('');
  const getReturnDetails = useCallback(() => () => {
    if (batteryReturns) {
      const {id} = match.params;
      if (batteryReturns.ref[id]) setReturnDetails(batteryReturns.ref[id]);
      else history.replace('/portal/battery-returns');
    }
  }, [batteryReturns, match, history]);
  useEffect(getReturnDetails(), [batteryReturns]);
  useEffect(() => {
    if (!loaded) {
      const sqPaymentScript = document.createElement('script');
      sqPaymentScript.src = 'https://js.squareup.com/v2/paymentform';
      sqPaymentScript.type = 'text/javascript';
      sqPaymentScript.async = false;
      document.getElementsByTagName('head')[0].appendChild(sqPaymentScript);
      setLoaded(true);
    }
  }, [loaded]);
  const core = {
    buttonInvoice: {
      className: 'HidePrint',
      size: 'small',
      style: {marginBottom: '24px', marginLeft: '0px'},
      variant: 'outlined',
      onClick: handleInvoiceOpen,
    },
    buttonCreditCard: {
      className: 'HidePrint',
      size: 'small',
      style: {marginBottom: '24px', marginLeft: '8px'},
      variant: 'outlined',
      onClick: handleCCOpen,
    },
    buttonPrint: {
      className: 'HidePrint',
      size: 'small',
      style: {marginBottom: '24px', marginLeft: '8px'},
      variant: 'outlined',
      onClick: handlePrint,
    },
    paper: {
      className: styles.paper,
      elevation: 3,
    },
  };
  if (!returnDetails || !currentUser) return <AsyncLoader />;
  if (['Pending Invoice', 'Paid', 'Invoiced'].indexOf(returnDetails.Status) === -1) history.push(`/portal/battery-returns/${returnDetails.BatteryReturnId}`);
  return (
    <>
      {(['Paid', 'Invoiced'].indexOf(returnDetails.Status) === -1) &&
    <>
      <Button {...core.buttonInvoice}><Receipt className={styles.extendedIcon} />Pay with Invoice</Button>
      <Button {...core.buttonCreditCard}><CreditCard className={styles.extendedIcon} />Pay with Credit Card</Button>
      <Button {...core.buttonPrint}><Print className={styles.extendedIcon} /> Print Receipt</Button>
    </>
      }
      <Paper {...core.paper} id='InvoicePrint'>
        <div style={{margin: 0, width: '100%'}} className='RemoveMinHeight'>
          <Header returnDetails={returnDetails} />
          {(returnDetails.Status !== 'Paid') && <img src={NotPaid} alt='Not Paid' style={{position: 'absolute', width: 245, height: 238, opacity: '50%', objectFit: 'contain', marginLeft: '-120px', left: '50%', marginTop: 60}} />}
          <PriceBreakdown returnDetails={returnDetails} currentUser={currentUser} />
          <ModalCreditCard close={handleClose} returnDetails={returnDetails} open={modalType === 'creditCard'} />
          <ModalPaperInvoice close={handleClose} returnDetails={returnDetails} open={modalType === 'paperInvoice'} currentUser={currentUser} />
        </div>
      </Paper>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  extendedIcon: {
    marginRight: '8px',
  },
  paper: {
    width: '100%',
    display: 'flex',
    overflowX: 'hidden',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    flexWrap: 'wrap', marginTop: '0px', maxWidth: '612px', position: 'relative', minHeight: 792,
  },
}));

Invoice.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(Invoice);
