import React from 'react';
import './AsyncLoader.css';

const AsyncLoader = props => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '48px' }}>
      <div className='lds-dual-ring' />
    </div>
  );
};

export default AsyncLoader;
