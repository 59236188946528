import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {ModalEditUserInfo, ModalEditDealerInfo, ModalEditDealerLocation, ModalDeleteDealerLocation} from './UserProfile/';
import {ViewAccountSetup, ViewMainProfile} from './UserProfile/';
import {ModalFinishAccount} from './BatteryReturn/';
import {AsyncLoader} from 'components/';
import {withRouter} from 'react-router';

const UserProfile = props => {
  const {currentUser} = useSelector(state => state.auth);
  const [modal, setModal] = useState({type: null});

  if (!currentUser) return <AsyncLoader />;
  return (
    <Fragment>
      <ViewAccountSetup setModal={setModal} currentUser={currentUser} />
      <ViewMainProfile setModal={setModal} currentUser={currentUser} />
      <ModalEditUserInfo close={() => setModal({type: null})} open={modal.type === 'editUserInfo'} />
      <ModalEditDealerInfo close={() => setModal({type: null})} open={modal.type === 'editDealerInfo'} />
      <ModalEditDealerLocation close={() => setModal({type: null})} modal={modal} />
      <ModalDeleteDealerLocation close={() => setModal({type: null})} modal={modal} />
      <ModalFinishAccount close={() => setModal({type: null})} open={modal.type === 'finishSetup'} />
    </Fragment>
  );
};

UserProfile.propTypes = {
  history: PropTypes.object.isRequired,
};
export default withRouter(UserProfile);
