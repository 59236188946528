import React, {useState, useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Button, Collapse, Grid, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@material-ui/core';
import {CheckCircleOutline} from '@material-ui/icons';
import {FormikForm, TextField} from 'components/formik';
import {FieldArray} from 'formik';
import {object, string, array} from 'yup';
import {firestoreUtility} from 'utilities';
import moment from 'moment'

const createInitialValue = (numberOfKits) => {
  const value = [];
  for (let i = 0; i < numberOfKits; i++) {
    value.push('');
  }
  return value;
};

const ModalModuleKits = ({open, close, currentUser, returnDetails}) => {
  const [stage, setStage] = useState('shipment');
  const handleClose = useCallback(() => {
    setStage('shipment');
    close();
  }, [close]);
  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'xs',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [open]);
  const formProps = useMemo(() => ({
    initilStatus: {alert: ''},
    initialValues: {
      Modules: createInitialValue(returnDetails.Battery.DefectiveModules),
    },
    onSubmit: async (values, actions) => {
      try {
        const updateDoc = {
          collection: 'BatteryReturns',
          writeType: 'update',
          docId: returnDetails.BatteryReturnId,
          docFields: {
            'Date.Reminder': moment().add(7, 'days').toDate(),
            ShipmentDetails: {
              ModuleKitShippingNumbers: values.Modules,
            },
            Status: 'Module Kits Shipped',
          },
        };
        await firestoreUtility(updateDoc);
        const addEmail = {
          collection: 'Emails',
          writeType: 'add',
          docFields: {
            to: currentUser.Role === 'Admin' ? returnDetails.LocationPickup.Email : [currentUser.Email, returnDetails.LocationPickup.Email],
            template: {
              data: {ShippingNumbers: values.Modules},
              name: 'ModuleKitsShipped',
            },
          },
        };
        await firestoreUtility(addEmail);
        setStage('success');
      } catch (error) {
        actions.setStatus({alert: error.message});
        actions.setSubmitting(false);
      }
    },
    validationSchema: object().shape({
      Modules: array().of(string().required('This field is required')),
    }),
  }), [currentUser.Email, currentUser.Role, returnDetails.Battery.DefectiveModules, returnDetails.BatteryReturnId, returnDetails.LocationPickup.Email]);
  const core = useMemo(() => ({
    buttons: {
      cancel: formik => ({
        color: 'secondary',
        disabled: formik.isSubmitting || formik.isValidating,
        onClick: handleClose,
      }),
      submit: formik => ({
        type: 'submit',
        color: 'primary',
        disableRipple: true,
        disabled: formik.isSubmitting || formik.isValidating,
      }),
    },
  }), [handleClose]);
  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {formik => {
          return (
            <>
              <DialogTitle>
                <Collapse in={stage === 'shipment'} timeout={{enter: 500, exit: 500}}>
                  Damage Module Battery Kit Shipping
                </Collapse>
              </DialogTitle>
              <DialogContent>
                <Collapse in={stage === 'shipment'} timeout={{enter: 500, exit: 500}}>
                  <Typography>Add the FedEx tracking number for each damaged module kit.</Typography>
                  <FieldArray name="Modules" render={arrayHelpers => (
                    <Grid container spacing={2}>
                      {formik.values.Modules.map((module, i) => {
                        return (
                          <Grid item xs={12} key={i}>
                            <TextField name={`Modules[${i}]`} label={`Module Kit ${i + 1}`} />
                          </Grid>
                        );
                      })}
                    </Grid>
                  )} />
                </Collapse>
                <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <CheckCircleOutline style={{fontSize: '10em'}} />
                    <Typography variant="h6" style={{textAlign: 'center'}} gutterBottom>
                      Damaged module battery kit shipping numbers were successfully added
                    </Typography>
                  </div>
                </Collapse>
              </DialogContent>
              <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                  Close
                  </Button>
                </DialogActions>
              </Collapse>
              <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
                <DialogActions style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Button {...core.buttons.cancel(formik)}>
                    Cancel
                  </Button>
                  <Button {...core.buttons.submit(formik)}>Submit</Button>
                </DialogActions>
              </Collapse>
            </>
          );
        }}
      </FormikForm>
    </Dialog>
  );
};

ModalModuleKits.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool,
  returnDetails: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
};
const isMemoEqual = (prevProps, nextProps) => {
  if (prevProps.open !== nextProps.open) return false;
  return true;
};
export default React.memo(ModalModuleKits, isMemoEqual);

// error={((formik.touched && formik.touched.Modules[i]) || formik.submitCount) && !formik.values.Modules[i]} helperText='This is a required field.'
