import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import {List} from '@material-ui/core';
import {AccountCircle, Assessment, Call, LocalShipping} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import {LogoutItem, MenuItem} from './MenuList/index';
import includes from 'lodash.includes';

const MenuList = props => {
  const {currentUser} = useSelector(state => state.auth);
  const menuItems = [
    {Icon: LocalShipping, padding: 15, text: 'Battery Returns', link: '/portal/battery-returns', visibleTo: ['Dealership', 'Admin']},
    {Icon: AccountCircle, padding: 15, text: 'User Profile', link: '/portal/user-profile', visibleTo: ['Dealership']},
    {Icon: Call, padding: 15, text: 'Contact Us', link: '/portal/contact-shipper', visibleTo: ['Dealership']},
    {Icon: Assessment, padding: 15, text: 'Reports', link: '/portal/admin-reports', visibleTo: ['Admin']},
    // {Icon: Settings, padding: 15, text: 'Admin Settings', link: '/portal/admin-settings', visibleTo: ['Admin']},
  ];
  const styles = useStyles();

  if (!currentUser) return null;
  return (
    <Fragment>
      <div className={styles.toolbar} />
      <List component="nav">
        {menuItems.map((item, index) => {
          if (includes(item.visibleTo, currentUser.Role)) return <MenuItem key={index} {...item} />;
          else return null;
        })}
        <LogoutItem padding={15} />
      </List>
    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.between('sm', 'md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
    },
  },
  listItemButton: {
    padding: 15,
  },
}));
export default MenuList;
