import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Drawer, Hidden} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Switch, Route, Redirect} from 'react-router-dom';
import classNames from 'classnames';
import {MainAppBar, MenuList} from './Portal/index';
import {BatteryReturns, BatteryReturnDetails, Contact, UserProfile, Invoice, AdminReports} from './index';
import {portalListener} from 'state/actions/firestore';

const Portal = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const {currentUser} = useSelector(state => state.auth);
  const styles = useStyles();
  const dispatch = useDispatch();

  useEffect(portalListener({currentUser, dispatch}), [currentUser]);
  const core = {
    drawerMobile: {
      variant: 'temporary',
      anchor: 'left',
      open: drawerOpen,
      onClose: () => setDrawerOpen(!drawerOpen),
      classes: {paper: styles.drawerPaper},
      ModalProps: {keepMounted: true},
    },
    drawerSmall: {
      variant: 'permanent',
      classes: {paper: classNames(styles.drawerPaper, !drawerOpen && styles.drawerPaperClose)},
      open: true,
    },
    drawerLarge: {
      variant: 'permanent',
      classes: {paper: styles.drawerPaper},
    },
    mainAppBar: {
      disableGutters: true,
      toggleDrawer: () => setDrawerOpen(!drawerOpen),
    },
  };

  return (
    <div className={styles.root}>
      <div id="MainMenu">
        <Hidden smUp>
          <MainAppBar {...core.mainAppBar} />
          <Drawer {...core.drawerMobile} style={{height: '100%'}}>
            <MenuList />
          </Drawer>
        </Hidden>
        <Hidden xsDown lgUp>
          <MainAppBar {...core.mainAppBar} />
          <Drawer {...core.drawerSmall} style={{height: '100%'}}>
            <MenuList />
          </Drawer>
        </Hidden>
        <Hidden mdDown>
          <MainAppBar />
          <Drawer {...core.drawerLarge} style={{height: '100%'}}>
            <MenuList />
          </Drawer>
        </Hidden>
      </div>
      <div className={styles.content} id="MainBody">
        <div className={styles.toolbar} id="Toolbar" />
        <Switch>
          <Route exact path="/portal/admin-settings" component={BatteryReturns} />
          <Route exact path="/portal/battery-returns/:id" component={BatteryReturnDetails} />
          <Route exact path="/portal/battery-returns" component={BatteryReturns} />
          <Route exact path="/portal/contact-shipper" component={Contact} />
          <Route exact path="/portal/admin-reports" component={AdminReports} />
          <Route exact path="/portal/user-profile" component={UserProfile} />
          <Route exact path="/portal/error" component={BatteryReturns} />
          <Route exact path='/portal/battery-returns/:id/invoice' component={Invoice} />
          <Redirect push to="/portal/battery-returns" />
        </Switch>
      </div>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    backgroundColor: '#f4f4f4',
    padding: theme.spacing(3),
    minWidth: 0,
  },
  drawerPaper: {
    [theme.breakpoints.down('xs')]: {
      width: '75vw',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: 240,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    [theme.breakpoints.up('lg')]: {
      position: 'relative',
      width: 240,
    },
    minHeight: '100vh',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  root: {
    flexGrow: 1,
    minHeight: '100vh',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.between('sm', 'md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
    },
  },
}));
export default Portal;
