import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Button, Grid, InputAdornment, Paper, TextField, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Cancel, LocalShipping, Print} from '@material-ui/icons';
import moment from 'moment';
import {formatPhoneNumber} from 'utilities';

const PrintDetails = ({returnDetails, printCancel}) => {
  console.log({returnDetails});
  const {currentUser} = useSelector(state => state.auth);
  const styles = useStyles();

  const core = {
    buttonCancel: {
      className: 'HidePrint',
      size: 'small',
      style: {marginBottom: '24px', marginLeft: '8px'},
      variant: 'outlined',
      onClick: () => printCancel(),
    },
    buttonPrint: {
      className: 'HidePrint',
      size: 'small',
      style: {marginBottom: '24px', marginLeft: '0px'},
      variant: 'outlined',
      onClick: () => window.print(),
    },
    fieldBoolean: (name, value) => {
      let bool = null;
      if (typeof value === 'boolean') {
        bool = value ? 'True' : 'False';
      } else {
        bool = 'Undefined';
      }
      return {
        variant: 'outlined',
        fullWidth: true,
        InputProps: {readOnly: true},
        label: name,
        margin: 'dense',
        value: bool,
      };
    },
    fieldCurrency: (name, value) => ({
      variant: 'outlined',
      fullWidth: true,
      label: name,
      margin: 'dense',
      type: 'number',
      value: typeof value === 'number' ? value.toFixed(2) : value,
      InputProps: {
        readOnly: true,
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      },
    }),
    fieldDate: (name, value) => ({
      variant: 'outlined',
      fullWidth: true,
      InputProps: {readOnly: true},
      label: name,
      margin: 'dense',
      value: value ? moment(value).format('MM/DD/YYYY') : 'Pending',
    }),
    fieldText: (name, value) => ({
      variant: 'outlined',
      fullWidth: true,
      InputProps: {readOnly: true},
      label: name,
      margin: 'dense',
      value: value ? value : '',
    }),
    paper: {
      className: styles.paper,
      elevation: 3,
      style: {flexWrap: 'wrap', marginTop: '0px', maxWidth: '850px'},
    },
  };
  const {Address1, City, State, PostalCode} = returnDetails.LocationPickup;

  return (
    <Fragment>
      <Button {...core.buttonPrint}>
        <Print className={styles.extendedIcon} />
          Start Print
      </Button>
      <Button {...core.buttonCancel}>
        <Cancel className={styles.extendedIcon} />
          Cancel Print
      </Button>
      <Grid spacing={5} container style={{minHeight: 1100, margin: 0, width: '100%'}} className='RemoveMinHeight'>
        <Paper {...core.paper} id='RemoveElevation'>
          <Grid item xs={12}>

            <LocalShipping style={{fontSize: '4em', fill: 'slategray', width: '100%'}} />
            <Typography variant="h5" gutterBottom style={{width: '100%', textAlign: 'center'}}>
              Battery Pickup Details
            </Typography>

            <Typography variant="h6" gutterBottom style={{width: '100%'}}>
              Pickup Information
            </Typography>
            <Grid spacing={2} container>
              <Grid item xs={3}>
                <TextField {...core.fieldText('Dealer No.', returnDetails.DealerNo)} />
              </Grid>
              <Grid item xs={3}>
                <TextField {...core.fieldText('Dealer Name', returnDetails.DealerName)} />
              </Grid>
              <Grid item xs={3}>
                <TextField {...core.fieldText('Return Status', returnDetails.Status)} />
              </Grid>
              <Grid item xs={3}>
                <TextField {...core.fieldDate('Request Date', returnDetails.Date.Requested)} />
              </Grid>
              <Grid item xs={3}>
                <TextField {...core.fieldDate('Received Date', returnDetails.Date.Received)} />
              </Grid>
              <Grid item xs={3}>
                <TextField {...core.fieldText('Defective Modules', returnDetails.Battery.DefectiveModules)} />
              </Grid>
              <Grid item xs={6}>
                <TextField {...core.fieldText('Battery VIN', returnDetails.Battery.VIN)} />
              </Grid>
            </Grid>
            {currentUser.Role === 'Admin' && (
              <Grid spacing={2} container>
                <Grid item xs>
                  <TextField {...core.fieldCurrency('Recycling Fee', returnDetails.Accounting.RecyclingFee)} />
                </Grid>
                <Grid item xs>
                  <TextField {...core.fieldCurrency('Tracking Admin Fee', returnDetails.Accounting.ProgramAdminFee)} />
                </Grid>
                <Grid item xs>
                  <TextField {...core.fieldCurrency('Outbound Shipping', returnDetails.Accounting.OutboundRate)} />
                </Grid>
                <Grid item xs>
                  <TextField {...core.fieldCurrency('Inbound Shipping', returnDetails.Accounting.InboundRate)} />
                </Grid>
                <Grid item xs>
                  <TextField {...core.fieldCurrency('Module Kits', returnDetails.Accounting.ModuleKit)} />
                </Grid>
              </Grid>
            )}

            <Typography variant="h6" gutterBottom style={{width: '100%'}}>
              Dealer Information
            </Typography>
            <Grid spacing={2} container>
              <Grid item sm={4}>
                <TextField {...core.fieldText('Contact', `${returnDetails.LocationPickup.Contact}`)} />
              </Grid>
              <Grid item sm={4}>
                <TextField {...core.fieldText('Phone', formatPhoneNumber(returnDetails.LocationPickup.Phone))} />
              </Grid>
              <Grid item sm={4}>
                <TextField {...core.fieldText('Email', returnDetails.LocationPickup.Email)} />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  {...core.fieldText('Pickup Site', `${Address1}, ${City}, ${State} ${PostalCode}`)}
                />
              </Grid>
              <Grid item sm={3}>
                <TextField {...core.fieldText('Hazmat Certified', returnDetails.Hazmat.Certified ? 'True' : 'False')} />
              </Grid>
              <Grid item sm={3}>
                <TextField {...core.fieldText('Certified Employee', returnDetails.Hazmat.Employee || 'Not Applicable')} />
              </Grid>
            </Grid>
            {returnDetails.ShipmentDetails && (
              <>
                <Typography variant="h6" gutterBottom style={{width: '100%'}}>
                Shipper Information
                </Typography>
                <Grid spacing={2} container>
                  <Grid item xs={4}>
                    <TextField {...core.fieldText('Carrier', returnDetails.ShipmentDetails.Carrier)} />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField {...core.fieldDate('Pickup Date', returnDetails.ShipmentDetails.PickupDate?.toDate())} />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField {...core.fieldText('Shipping Paper No.', returnDetails.ShipmentDetails.ShippingPaper)} />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Paper>
      </Grid>

    </Fragment>
  );
};

const useStyles = makeStyles(theme => ({
  extendedIcon: {
    marginRight: '8px',
  },
  paper: {
    width: '100%',
    display: 'flex',
    marginTop: '24px',
    overflowX: 'hidden',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));
PrintDetails.propTypes = {
  returnDetails: PropTypes.object.isRequired,
  printCancel: PropTypes.func.isRequired,
};
export default PrintDetails;
