import React, {Fragment, useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {Collapse, Grid, Typography, Dialog, DialogContent, DialogTitle, DialogActions, Button} from '@material-ui/core';
import {CheckCircleOutline} from '@material-ui/icons';
import {firestoreUtility, postalRegex} from 'utilities';
import {Alert} from 'components';
import {AutoField, FormikForm, TextField} from 'components/formik';
import {setCurrentUser} from 'state/actions/auth';
import {object, string} from 'yup';
import {states} from 'constLists.js';
import {Firestore, FieldValue} from 'firebaseConfig';

const ModalFinishAccount = ({open, close}) => {
  const [stage, setStage] = useState('dealership');
  const {currentUser} = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    setStage('dealership');
    close();
  }, [close]);
  const handleBack = useCallback(() => {
    if (stage === 'address') setStage('dealership');
  }, [stage]);
  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'sm',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [open]);
  const formProps = useMemo(() => ({
    initialStatus: {
      alert: '',
    },
    initialValues: {
      DealerNo: '',
      DealerName: '',
      Address1: '',
      Address2: '',
      City: '',
      State: '',
      PostalCode: '',
    },
    onSubmit: (values, actions) => {
      if (stage === 'dealership') {
        setStage('address');
        actions.setSubmitting(false);
        actions.setTouched({});
      }
      if (stage === 'address') {
        (async () => {
          try {
            const updateDoc = {
              collection: 'Users',
              writeType: 'update',
              docId: currentUser.UserId,
              docFields: {
                DealerName: values.DealerName,
                DealerNo: values.DealerNo,
                Locations: FieldValue.arrayUnion({
                  Address1: values.Address1,
                  Address2: values.Address2,
                  City: values.City,
                  State: values.State,
                  PostalCode: values.PostalCode,
                }),
                Verified: true,
              },
            };
            await firestoreUtility(updateDoc);
            const userDoc = await Firestore.collection('Users').doc(currentUser.UserId).get();
            const user = {
              ...userDoc.data(),
              UserId: userDoc.id,
              TimeAuthorized: currentUser.TimeAuthorized,
              TimeExpires: currentUser.TimeExpires,
            };
            dispatch(setCurrentUser(user));
            setStage('success');
            actions.setSubmitting(false);
          } catch (error) {
            actions.setStatus({alert: error.message});
            actions.setSubmitting(false);
          }
        })();
      }
    },
    validationSchema: () => {
      const dealershipValidation = object().shape({
        DealerNo: string().label('Dealer No.').required('Required').min(2),
        DealerName: string().label('Dealer Name').required().min(2),
      });
      const addressValidation = object().shape({
        Address1: string().label('Address 1').required(),
        Address2: string().label('Address 2').notRequired(),
        City: string().label('City').required(),
        State: string().label('State').required(),
        PostalCode: string().label('Postal Code').required().matches(postalRegex, 'Postal Code is improperly formatted. (e.g. #####)'),
      });
      if (stage === 'dealership') return dealershipValidation;
      if (stage === 'address') return addressValidation;
    },
  }), [currentUser, dispatch, stage]);
  const core = useMemo(() => ({
    buttons: {
      back: formik => ({
        onClick: handleBack,
        color: 'primary',
        disableRipple: true,
        disabled: formik.isSubmitting || formik.isValidating,
      }),
      cancel: formik => ({
        color: 'secondary',
        disabled: formik.isSubmitting || formik.isValidating,
        onClick: handleClose,
      }),
      submit: formik => ({
        type: 'submit',
        color: 'primary',
        disableRipple: true,
        disabled: formik.isSubmitting || formik.isValidating,
      }),
    },
  }), [handleBack, handleClose]);

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {formik => (
          <Fragment>
            <DialogTitle>
              <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
                Finish Account Setup
              </Collapse>
            </DialogTitle>
            <DialogContent id="dialog-content">
              <Collapse in={stage === 'dealership'}>
                <Typography variant='body1'>
                  Enter Dealership Information
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField name='DealerNo' label='Dealer No.' fast required />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name='DealerName' label='Dealer Name' fast required />
                  </Grid>
                </Grid>
              </Collapse>
              <Collapse in={stage === 'address'}>
                <Typography variant='body1'>
                  Enter the primary pickup address for battery returns
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <TextField name='Address1' label='Address 1' fast required />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField name='Address2' label='Address 2' fast />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField name='City' label='City' fast required />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <AutoField name='State' label='State' items={states} itemKey='value' required />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField name='PostalCode' label='Postal Code' fast required />
                  </Grid>
                </Grid>
              </Collapse>
              <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <CheckCircleOutline style={{fontSize: '10em'}} />
                  <div style={{width: '100%'}}>
                    <Typography variant="h6" style={{textAlign: 'center'}} gutterBottom>
                      Account setup complete
                    </Typography>
                  </div>s
                </div>
              </Collapse>
              <Alert message={formik.status.alert} />
            </DialogContent>
            <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Collapse>
            <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
              <DialogActions style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                  <Button {...core.buttons.cancel(formik)}>
                    Cancel
                  </Button>
                </div>
                <div>
                  {stage !== 'dealership' && <Button {...core.buttons.back(formik)}>Back</Button>}
                  <Button {...core.buttons.submit(formik)}>{stage === 'address' ? 'Submit' : 'Next'}</Button>
                </div>
              </DialogActions>
            </Collapse>
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

ModalFinishAccount.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
export default ModalFinishAccount;
