import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { Field, FastField } from 'formik';

const PasswordField = ({ fast, name, onBlur, onChange, ...otherProps }) => {
  const textFieldProps = ({ form, field }) => {
    //console.log('PasswordField { props, field, form }', { props, field, form });
    return {
      disabled: form.isSubmitting,
      error: form.touched[name] && form.errors[name] ? true : false,
      fullWidth: true,
      helperText: form.touched[name] && form.errors[name],
      id: name,
      label: name,
      margin: 'dense',
      type: 'password',
      ...otherProps,
      onBlur: e => {
        field.onBlur(e);
        if (onBlur) onBlur({ field, form });
      },
      onChange: e => {
        field.onChange(e);
        if (onChange) onChange({ field, form });
      }
    };
  };

  if (fast) {
    return (
      <FastField name={name}>
        {({ form, field }) => <TextField {...field} {...textFieldProps({ form, field })} />}
      </FastField>
    );
  }
  return (
    <Field name={name}>{({ form, field }) => <TextField {...field} {...textFieldProps({ form, field })} />}</Field>
  );
};

PasswordField.defaultProps = {
  fast: false
};
PasswordField.propTypes = {
  fast: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};
export default PasswordField;
