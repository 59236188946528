import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Paper, Grid, Typography, MenuList, MenuItem, ListItemIcon, ListItemText, TextField, Button} from '@material-ui/core';
import {Edit, Delete, AccountCircle} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import {formatPhoneNumber} from 'utilities';
import cloneDeep from 'lodash.clonedeep';
import {Table} from '@kbi/component-library'

const ViewMainProfile = ({currentUser, setModal}) => {
  const styles = useStyles();

  const core = useMemo(() => ({
    paper: {
      className: styles.paper,
      elevation: 2,
    },
    icon: {
      style: {
        fontSize: '10em',
        fill: 'slategray',
        width: '100%',
      },
    },
    typographyMain: {
      gutterBottom: false,
      style: {
        color: 'black',
        textAlign: 'center',
        width: '100%',
      },
      variant: 'h6',
    },
    typographySecondary: {
      gutterBottom: false,
      style: {
        color: 'black',
        textAlign: 'center',
        width: '100%',
      },
      variant: 'subtitle1',
    },
    menuSide: {
      style: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: 'ghostwhite',
        border: '1px solid darkgray',
        margin: '8px 0px',
      },
    },
  }), [styles]);
  const fieldText = useCallback((name, value) => ({
    variant: 'outlined',
    readOnly: true,
    fullWidth: true,
    label: name,
    margin: 'dense',
    value: value ? value : '',
  }), []);
  const locationsTableProps = useMemo(() => ({
    actionsPerRow: [
      {icon: Edit, tooltip: 'Edit Location', onClick: ({event, rowData, rowIndex}) => setModal({type: 'editDealerLocation', location: rowData})}, // eslint-disable-line
      {icon: Delete, tooltip: 'Delete Location', onClick: ({event, rowData, rowIndex}) => setModal({type: 'deleteDealerLocation', location: rowData})}, // eslint-disable-line
    ],
    columns: [
      {Header: 'Address1', accessor: 'Address1'},
      {Header: 'Address2', accessor: 'Address2'},
      {Header: 'City', accessor: 'City'},
      {Header: 'State', accessor: 'State'},
      {Header: 'Postal Code', accessor: 'PostalCode'},
    ],
    data: currentUser ? cloneDeep(currentUser.Locations) : [],
    isLoading: !currentUser,
  }), [currentUser, setModal]);

  if (!currentUser.DealerNo) return null;
  return (
    <Grid spacing={2} container>
      <Grid item xs={12} md={4}>
        <Paper className={styles.paper} style={{flexDirection: 'column', alignItems: 'center'}}>
          <AccountCircle {...core.icon} />
          <Typography {...core.typographyMain}>User Profile</Typography>
          <MenuList {...core.menuSide}>
            <MenuItem onClick={() => setModal({type: 'editUserInfo'})}>
              <ListItemIcon style={{minWidth: '40px'}}>
                <Edit />
              </ListItemIcon>
              <ListItemText primary="Edit User Information" />
            </MenuItem>
            <MenuItem onClick={() => setModal({type: 'editDealerInfo'})}>
              <ListItemIcon style={{minWidth: '40px'}}>
                <Edit />
              </ListItemIcon>
              <ListItemText primary="Edit Dealer Information" />
            </MenuItem>
          </MenuList>
        </Paper>
      </Grid>
      <Grid item xs={12} md={8}>
        <Paper {...core.paper} style={{display: 'flex', flexWrap: 'wrap'}}>
          <Typography variant="h6" gutterBottom style={{width: '100%'}}>
          User Information
          </Typography>
          <Grid spacing={2} container>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={12} sm={4}>
                <TextField {...fieldText('Display Name', currentUser.NameFull)} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField {...fieldText('Email', currentUser.Email)} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField {...fieldText('Phone', formatPhoneNumber(currentUser.Phone))} />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper {...core.paper} style={{display: 'flex', flexWrap: 'wrap'}}>
          <Typography variant="h6" gutterBottom style={{width: '100%'}}>
          Dealer Information
          </Typography>
          <Grid spacing={2} container>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={12} sm={6}>
                <TextField {...fieldText('DealerNo.', currentUser.DealerNo)} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField {...fieldText('Dealer Name', currentUser.DealerName)} />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Paper elevation={2} style={{marginTop: '24px'}}>
          <div style={{height: 64, display: 'flex', alignItems: 'center', paddingLeft: 16}}>
            <Typography variant='h6'>Dealer Locations</Typography>
          </div>
          <Table {...locationsTableProps} />
          <Button onClick={() => setModal({type: 'addDealerLocation', location: null})} color='primary' style={{margin: '8px'}}>
            Add Location
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: '24px',
    overflowX: 'auto',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));
ViewMainProfile.propTypes = {
  currentUser: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
};
const isMemoEqual = (prevProps, nextProps) => {
  if (prevProps.currentUser !== nextProps.currentUser) return false;
  return true;
};
export default React.memo(ViewMainProfile, isMemoEqual);
