import React from 'react';
import PropTypes from 'prop-types';
import {Paper, Typography, Button} from '@material-ui/core';
import {AccountCircle} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';

const ViewAccountSetup = ({currentUser, setModal}) => {
  const styles = useStyles();

  if (currentUser.DealerNo) return null;
  return (
    <Paper className={styles.paper}>
      <Typography variant="h4" gutterBottom>
      User Profile
      </Typography>
      <AccountCircle style={{fontSize: '10em', fill: 'slategray', width: '100%'}} />
      <Typography variant="h6" gutterBottom style={{width: '100%', textAlign: 'center'}}>
        {`Let's Finish Setting Up Your Account.`}
      </Typography>
      <Button variant="outlined" color="primary" onClick={() => setModal({type: 'finishSetup'})}>
        Complete Setup
      </Button>
    </Paper>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: '24px',
    overflowX: 'auto',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));
ViewAccountSetup.propTypes = {
  currentUser: PropTypes.object.isRequired,
  setModal: PropTypes.func.isRequired,
};
const isMemoEqual = (prevProps, nextProps) => {
  if (prevProps.currentUser.DealerNo !== nextProps.currentUser.DealerNo) return false;
  return true;
};
export default React.memo(ViewAccountSetup, isMemoEqual);
