import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import {Button, Collapse, Typography} from '@material-ui/core';
import {CheckCircleOutline} from '@material-ui/icons';
import moment from 'moment';
import {date, object} from 'yup';
import {Alert} from 'components/';
import {firestoreUtility} from 'utilities';
import {DateField, FormikForm} from 'components/formik/';

const ModalSetReminder = ({close, open, returnDetails}) => {
  const [stage, setStage] = useState('setDate');

  const handleClose = () => {
    setStage('setDate');
    close();
  };
  const handleDelete = async ({setStatus, status}) => {
    if (status.confirmDelete === false) setStatus({...status, confirmDelete: true});
    if (status.confirmDelete === true) {
      try {
        const updateDocument = {
          collection: 'BatteryReturns',
          writeType: 'update',
          docId: returnDetails.BatteryReturnId,
          docFields: {'Date.Reminder': null},
        };
        await firestoreUtility(updateDocument);
        setStage('success');
      } catch (error) {
        setStatus({alert: error.message});
      }
    }
  };

  const deleteButtonProps = ({setStatus, status, isSubmitting, isValidating}) => ({
    color: 'secondary',
    disabled: isSubmitting || isValidating,
    onClick: () => handleDelete({setStatus, status}),
  });
  const dialogProps = {
    fullWidth: true,
    maxWidth: 'sm',
    open,
    scroll: 'body',
    transitionDuration: {exit: 0},
  };
  const formProps = {
    initialStatus: {alert: '', confirmDelete: false},
    initialValues: {
      Reminder: returnDetails.Date.Reminder ?
        moment(returnDetails.Date.Reminder).format('YYYY-MM-DD') :
        moment()
          .add(3, 'days')
          .format('YYYY-MM-DD'),
    },
    onSubmit: async (values, actions) => {
      try {
        const updateDocument = {
          collection: 'BatteryReturns',
          writeType: 'update',
          docId: returnDetails.BatteryReturnId,
          docFields: {'Date.Reminder': moment(values.Reminder).toDate()},
        };
        await firestoreUtility(updateDocument);
        setStage('success');
      } catch (error) {
        actions.setStatus({alert: error.message});
        actions.setSubmitting(false);
      }
    },
    validationSchema: object().shape({
      Reminder: date().required(),
    }),
  };
  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {({setStatus, status, isSubmitting, isValidating, setFieldValue, values}) => (
          <Fragment>
            <DialogTitle>
              <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
                Set Reminder
              </Collapse>
            </DialogTitle>
            <DialogContent>
              <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
                <DateField name="Reminder" />
              </Collapse>
              <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <CheckCircleOutline style={{fontSize: '10em'}} />
                  <Typography variant="h6" style={{textAlign: 'center'}} gutterBottom>
                    Reminder has been successfully updated.
                  </Typography>
                </div>
              </Collapse>
              <Alert message={status.alert} />
            </DialogContent>
            <Collapse in={stage !== 'success'} timeout={{enter: 500, exit: 500}}>
              <DialogActions>
                <div style={{justifyContent: 'space-between', width: '100%'}}>
                  {returnDetails.Date.Reminder && (
                    <Button {...deleteButtonProps({setStatus, status, isSubmitting, isValidating})}>
                      {!status.confirmDelete ? 'Delete' : 'Confirm'}
                    </Button>
                  )}
                  <div style={{float: 'right'}}>
                    <Button onClick={handleClose} color="secondary" disabled={isSubmitting || isValidating}>
                      Cancel
                    </Button>
                    <Button type="submit" color="primary" disabled={isSubmitting || isValidating}>
                      Set Reminder
                    </Button>
                  </div>
                </div>
              </DialogActions>
            </Collapse>
            <Collapse in={stage === 'success'} timeout={{enter: 500, exit: 500}}>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Collapse>
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

ModalSetReminder.propTypes = {
  close: PropTypes.func.isRequired,
  open: PropTypes.bool,
  returnDetails: PropTypes.object.isRequired,
};
const isMemoEqual = (prevProps, nextProps) => {
  if (prevProps.open !== nextProps.open) return false;
  return true;
};
export default React.memo(ModalSetReminder, isMemoEqual);
