import {createStore, combineReducers, applyMiddleware} from 'redux';
import ReduxThunk from 'redux-thunk';
import {auth, firestore} from './reducers';

// Initialize Redux Store
export const store = createStore(
  combineReducers({
    auth,
    firestore,
  }),
  applyMiddleware(ReduxThunk)
);
